import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Switch from "antd/lib/switch";
import { useEffect, useState } from "react";
import { EntityProperty } from "../../../../../api/NetworkingCustomers";
import { formatValidInputClass, validInputClass } from "../../../../../helpers/FormatHelper";
import { getResource } from "../../../../../helpers/ResourcesHelper";
import Button from "../../../../inputs/Button";
import Dropdown from "../../../../inputs/Dropdown";
import Input from "../../../../inputs/Input";

export interface TradeInformation
{
  EntityPropertyList  : EntityProperty[],
  OnlineAccess        : boolean;
  TradeLimit          : number;
  TradeLimitWoE       : string;
  DailyTradeLimit     : number;
  DailyTradeLimitWoE  : string;
}

const tradeLimitList = [
  { label: 'Warning', value: 'Warning' },
  { label: 'Enforce', value: "Enforce" }
];

const REGEX= {
  'number[1-100]' : '^[1-9][0-9]?$|^100$'
};

const TradeInformationStep = (props: any) => {

  const [isShareHolder, setIsShareHolder] = useState<boolean>(false);
  const [shareHolderPercentage, setShareHolderPercentage] = useState<number>(0);
  const [requireOnlineAccess, setRequireOnlineAccess] = useState<boolean>(false);
  const [tradeLimit, setTradeLimit] = useState<number>(0.00);
  const [dailyTradeLimit, setDailyTradeLimit] = useState<number>(0.00);
  const [tradeLimitSelected, setTradeLimitSelected] = useState<string>('');
  const [dailyTradeLimitSelected, setDailyTradeLimitSelected] = useState<string>('');
  const [tokenIsShareHolder, setTokenIsShareHolder] = useState<string>('');
  const [tokenShareHolderPercentage, setTokenShareHolderPercentage] = useState<string>('');
  const [validations, setValidations] = useState({
    shareHolderPercentage: true,
    tradeLimitSelected: true,
    dailyTradeLimitSelected: true,
  });

  useEffect(() => {
    if((props.do == 'edit' && props.contact !== null) || (props.do == 'add' && props.contact !== null))
    {
      setRequireOnlineAccess(props.contact.OnlineAccess);
      setTradeLimit(props.contact.TradeLimit);
      setTradeLimitSelected(props.contact.TradeLimitWarningOrEnforce);
      setDailyTradeLimit(props.contact.DailyTradeLimit);
      setDailyTradeLimitSelected(props.contact.DailyTradeLimitWarningOrEnforce);
      if (props.validForm) {
        setValidations({
          ...validations,
          tradeLimitSelected: validInputClass(props.contact.TradeLimitWarningOrEnforce),
          dailyTradeLimitSelected: validInputClass(props.contact.DailyTradeLimitWarningOrEnforce),
        });
      }
      
      if(props.contact.EntityProperties !== undefined)
      {
        if(props.contact.EntityProperties !== '')
        {
          let auxOBJ = JSON.parse(props.contact.EntityProperties);
          let epList :any[] = auxOBJ.EntityProperty;
          
          /*///////////////////////////////////////////// any
            {
              DataType: "Numeric"
              Description: "Share Holder Percent"
              UpdateToken: "637589550672933333"
              Value: "66.00000000"
            }
          */

          epList.forEach((entityProperty: any) => {
            if(entityProperty.Description === 'Share Holder')
            {
              switch (entityProperty.Value) 
              {
                case 'Yes':
                {
                  setIsShareHolder(true);
                  setTokenIsShareHolder(entityProperty.UpdateToken);
                  break;
                }
                default:
                {
                  setIsShareHolder(false);
                  setTokenIsShareHolder(entityProperty.UpdateToken);
                  break;
                }
              }
            }

            if(entityProperty.Description === 'Share Holder Percent')
            {
              let auxValuePercentage:number = entityProperty.Value.toString() === '' ? 0 : parseInt(entityProperty.Value.toString());

              if(auxValuePercentage > 0)
              {
                setIsShareHolder(true);
              }
              if (props.validForm) {
                setValidations({
                  ...validations,
                  shareHolderPercentage: validInputClass(entityProperty.Value.toString()),
                });
              }
              setShareHolderPercentage(auxValuePercentage);
              setTokenShareHolderPercentage(entityProperty.UpdateToken);
            }
          });
        }
      }
    }
  }, [props.contact]);

  const switchActions = (value: any, type: string) => {
    let objSetters: { [key: string]: any } = {
      ['isshareholder']       : () => {
        setIsShareHolder(value);
        if(value === false)
        {
          setShareHolderPercentage(0);
        }
      },
      ['requireonlineaccess'] : () => {setRequireOnlineAccess(value);},
    }
    return(objSetters[type]());
  }

  const inputsActions = (value: any, type: string) => {
    value = value.replace(/[,]+/g, "");
    let objSetters: { [key: string]: any } = {
      ['shareholderpercentage']: () => {
        setValidations({ ...validations, shareHolderPercentage: true });
        setShareHolderPercentage(value);
      },
      ['tradelimit']: () => { setTradeLimit(value); },
      ['dailytradelimit']: () => { setDailyTradeLimit(value); },
    }
    return(objSetters[type]())
  }

  const dropdownsActions = (item: any, type: string) =>
  {
    let objSetters: { [key: string]: any } = {
      ['tradelimit']: () => {
        setValidations({ ...validations, tradeLimitSelected: true });
        setTradeLimitSelected(item.value);
      },
      ['dailytradelimit']: () => {
        setValidations({ ...validations, dailyTradeLimitSelected: true });
        setDailyTradeLimitSelected(item.value);
      },
    }
    return(objSetters[type]())
  }

  const validateFields = () => {
    let shareHolderPercentageValid    : boolean = true;
    let tradeLimitSelectedValid       : boolean = true;
    let dailyTradeLimitSelectedValid  : boolean = true;

    if(isShareHolder === true)
    {
      if (!isNaN(shareHolderPercentage!))
      {
        let reg = new RegExp(REGEX['number[1-100]']);
        if(!reg.test(shareHolderPercentage!.toString()))
        {
          shareHolderPercentageValid = false;
        }
      }else{
        shareHolderPercentageValid = false;
      }
    }

    if(tradeLimitSelected == '' || tradeLimitSelected == undefined){tradeLimitSelectedValid = false}
    if(dailyTradeLimitSelected == '' || dailyTradeLimitSelected == undefined){dailyTradeLimitSelectedValid = false}

    setValidations({
      shareHolderPercentage: validInputClass(shareHolderPercentage.toString()),
      tradeLimitSelected: validInputClass(tradeLimitSelected),
      dailyTradeLimitSelected: validInputClass(dailyTradeLimitSelected),
    });
    return(
      shareHolderPercentageValid  &&
      tradeLimitSelectedValid     &&
      dailyTradeLimitSelectedValid
    );
  }

  return (
    <div className="row center-xs">
      <div className="row center-xs">
        <div className="col-md-6 col-xs-12 start-sm start-xs" >
          {/*Is Share holder*/}
          <div className="row">
            <div className="col-md-6 col-xs-6">
              <span className="input-label end-lg end-sm end-xs">{getResource('IsShareholder')}</span>
            </div>
            <div id="contact-is-shareholder-div" className="col-md-6 col-xs-6 start-lg start-sm start-xs">
              <Switch
                checkedChildren   = {<CheckOutlined />}
                unCheckedChildren = {<CloseOutlined />}
                defaultChecked    = {false}
                checked           = {isShareHolder}
                onChange          = {(event:any)=>{switchActions(event, 'isshareholder');}}
              />
            </div>
          </div>
        </div>
      </div>
      {isShareHolder && <div className="row center-xs">
        <div className="col-md-7 col-xs-12">
          {/*Share Holder Percentage*/}
          <div className="row center-xs">
            <span className="input-label">{getResource('ShareHolderPercentage')} (*)</span>
          </div>
          <div className="row center-xs">
            <div className="col-md-6 col-xs-6">
              <Input
                id="contact-shareholder-percentage"
                type     = 'number' 
                value    = {shareHolderPercentage} 
                onChange = {(event:any)=>{
                  inputsActions(event.target.value, 'shareholderpercentage')
                }}
                className={formatValidInputClass(validations.shareHolderPercentage)}
              />
            </div>
          </div>
        </div>
      </div>}
      <div className="row center-xs">
        <div className="col-md-6 col-xs-12 start-sm start-xs" >
          {/*Require Online Access*/}
          <div className="row">
            <div className="col-md-6 col-xs-6">
              <span className="input-label end-lg end-sm end-xs">{getResource('RequireOnlineAccess')}</span>
            </div>
            <div id="contact-online-access-div" className="col-md-6 col-xs-6 start-lg start-sm start-xs">
              <Switch
                checkedChildren   = {<CheckOutlined />}
                unCheckedChildren = {<CloseOutlined />}
                defaultChecked    = {false}
                checked           = {requireOnlineAccess}
                onChange          = {(event:any)=>{switchActions(event, 'requireonlineaccess');}}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-7 col-xs-12">
          {/*Trade Limit*/}
          <div className="row center-xs">
            <span className="input-label">{getResource('TradeLimit')} (*)</span>
          </div>
          <div className="row center-xs">
            <div className="col-md-4 col-xs-5">
              <Input
                id="contact-trade-limit-input"
                type     = 'number' 
                value    = {tradeLimit}
                onChange = {(event:any)=>{
                  inputsActions(event.target.value, 'tradelimit')
                }}
              />
            </div>
            <div style={{width:'2%'}}/>
            <div className="col-md-4 col-xs-5">
              <Dropdown 
                id="contact-trade-limit-dropdown"
                name      = "dropdown-tradelimit" 
                title     = "Select..." 
                list      = {tradeLimitList}
                onChange  = {(item: any) =>{dropdownsActions(item, 'tradelimit')}}
                select    = {{ value: tradeLimitSelected }}
                className={formatValidInputClass(validations.tradeLimitSelected)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-7 col-xs-12">
          {/*Daily Trade Limit*/}
          <div className="row center-xs">
            <span className="input-label">{getResource('DailyTradeLimit')} (*)</span>
          </div>
          <div className="row center-xs">
            <div className="col-md-4 col-xs-5">
              <Input
                id="contact-daily-trade-limit-input"
                type     = 'number' 
                value    = {dailyTradeLimit} 
                onChange = {(event:any)=>{
                  inputsActions(event.target.value, 'dailytradelimit')
                }}
              />
            </div>
            <div style={{width:'2%'}}/>
            <div className="col-md-4 col-xs-5">
              <Dropdown 
                id="contact-daily-trade-limit-input"
                name      = "dropdown-dailytradelimit" 
                title     = "Select..." 
                list      = {tradeLimitList}
                onChange  = {(item: any) =>{dropdownsActions(item, 'dailytradelimit')}}
                select    = {{ value: dailyTradeLimitSelected }}
                className={formatValidInputClass(validations.dailyTradeLimitSelected)}
              />
            </div>
          </div>
        </div>
      </div>
      {/*Buttons*/}
      <div className="col-md-12 col-xs-12">
        <div className="row center-md">
          <div className="col-md-4 col-xs-12">
            <Button 
              id="contacts-trade-info-cancel"
              type    = "secondary" 
              onClick = {() => { props.onCancel(); }}
            >Cancel</Button>
          </div>
          <div className="col-md-4 col-xs-6">
            <Button 
              id="contacts-trade-info-previous"
              type    = "primary" 
              onClick = {(event: any) => {

                let entityProperties: EntityProperty[] = [];

                if(isShareHolder)
                {
                    let element1: EntityProperty = {
                        EntityId            : props.contact.ContactID,
                        EntityType          : 'Contact',
                        PropertyDescription : 'Share Holder',
                        PropertyValue       : 'Yes',
                        CreatedBy           : localStorage.getItem('PersonID') as string,
                        UpdateToken         : tokenIsShareHolder
                    }

                    entityProperties.push(element1);

                    var element2: EntityProperty = {
                        EntityId            : props.contact.ContactID,
                        EntityType          : 'Contact',
                        PropertyDescription : 'Share Holder Percent',
                        PropertyValue       : shareHolderPercentage,
                        CreatedBy           : localStorage.getItem('PersonID') as string,
                        UpdateToken         : tokenShareHolderPercentage
                    }

                    entityProperties.push(element2);
                }else
                {
                    var element1: EntityProperty = {
                        EntityId            : props.contact.ContactID,
                        EntityType          : 'Contact',
                        PropertyDescription : 'Share Holder',
                        PropertyValue       : 'No',
                        CreatedBy           : localStorage.getItem('PersonID') as string,
                        UpdateToken         : tokenIsShareHolder
                    }

                    entityProperties.push(element1);

                    var element2: EntityProperty = {
                        EntityId            : props.contact.ContactID,
                        EntityType          : 'Contact',
                        PropertyDescription : 'Share Holder Percent',
                        PropertyValue       : 0,
                        CreatedBy           : localStorage.getItem('PersonID') as string,
                        UpdateToken         : tokenShareHolderPercentage
                    }

                    entityProperties.push(element2);
                }

                let returTradePrev: TradeInformation = {
                  OnlineAccess: requireOnlineAccess,
                  TradeLimit: tradeLimit,
                  TradeLimitWoE: tradeLimitSelected,
                  DailyTradeLimit: dailyTradeLimit,
                  DailyTradeLimitWoE: dailyTradeLimitSelected,
                  EntityPropertyList: entityProperties
                };

                if(validateFields())
                {
                  props.onPreviousStep('finish', returTradePrev, false);
                }else{
                  props.onPreviousStep('error', returTradePrev, true);
                }
              }}>Previous</Button>
          </div>
          <div className="col-md-4 col-xs-6">
            <Button 
              id="contacts-trade-info-save"
              type    = "primary" 
              onClick={(event: any) => {
                let entityProperties: EntityProperty[] = [];
                let userSession = JSON.parse(localStorage.getItem('UserSession')!);

                if(isShareHolder)
                {
                  let element1: EntityProperty = {
                      EntityId            : props.contact.ContactID,
                      EntityType          : 'Contact',
                      PropertyDescription : 'Share Holder',
                      PropertyValue       : 'Yes',
                      CreatedBy           : userSession.PersonID,
                      UpdateToken         : tokenIsShareHolder === '' ? '0' : tokenIsShareHolder
                  }

                  entityProperties.push(element1);

                  var element2: EntityProperty = {
                      EntityId            : props.contact.ContactID,
                      EntityType          : 'Contact',
                      PropertyDescription : 'Share Holder Percent',
                      PropertyValue       : shareHolderPercentage,
                      CreatedBy           : userSession.PersonID,
                      UpdateToken         : tokenShareHolderPercentage === '' ? '0' : tokenShareHolderPercentage
                  }

                  entityProperties.push(element2);
                }else
                {
                  var element1: EntityProperty = {
                      EntityId            : props.contact.ContactID,
                      EntityType          : 'Contact',
                      PropertyDescription : 'Share Holder',
                      PropertyValue       : 'No',
                      CreatedBy           : userSession.PersonID,
                      UpdateToken         : tokenIsShareHolder === '' ? '0' : tokenIsShareHolder
                  }

                  entityProperties.push(element1);

                  var element2: EntityProperty = {
                      EntityId            : props.contact.ContactID,
                      EntityType          : 'Contact',
                      PropertyDescription : 'Share Holder Percent',
                      PropertyValue       : 0,
                      CreatedBy           : userSession.PersonID,
                      UpdateToken         : tokenShareHolderPercentage === '' ? '0' : tokenShareHolderPercentage
                  }

                  entityProperties.push(element2);
                }

                let returTradePrev: TradeInformation = {
                  OnlineAccess: requireOnlineAccess,
                  TradeLimit: tradeLimit,
                  TradeLimitWoE: tradeLimitSelected,
                  DailyTradeLimit: dailyTradeLimit,
                  DailyTradeLimitWoE: dailyTradeLimitSelected,
                  EntityPropertyList: entityProperties
                };

                if(validateFields())
                {
                  props.onSave('finish', returTradePrev, false);
                }else{
                  props.onSave('error', returTradePrev, true);
                }
              }}>Save</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TradeInformationStep