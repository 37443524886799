import { Steps } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { getResource } from '../../../helpers/ResourcesHelper';
import { Deal } from '../../models/Deal';
import PaymentDisburse from '../disburse/PaymentDisburse';
import Summary from '../summary/Summary';
import Spot from './Spot';

const { Step } = Steps;

interface SpotContainerProps {
  fromOtherScreenDealObj: Deal;
  fromOtherScreenSpot: string;
  objOrderScreen?: {} | null;
}

const SpotContainer: FunctionComponent<SpotContainerProps> = (props) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [dealID, setDealID] = useState(0);
  const [sellCurrency, setSellCurrency] = useState('');
  const [buyCurrency, setBuyCurrency] = useState('');
  const [valueDate, setValueDate] = useState('');
  const [settlementInstrument, setSettlementInstrument] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [customerRate, setCustomerRate] = useState(0.0);
  const [amountToDisburse, setAmountToDisburse] = useState(0.0);

  const [fromOtherScreenDealObj, setFromOtherScreenDealObj] = useState<Deal>(new Deal(0, '', '', '', 0, 0, '', '', ''));
  const [fromOtherScreen, setfromOtherScreen] = useState<string>('');

  useEffect(() => {
    if(props.fromOtherScreenSpot === 'balances')
    {
      setfromOtherScreen(props.fromOtherScreenSpot);
      setFromOtherScreenDealObj(props.fromOtherScreenDealObj);
    }
  }, [props.fromOtherScreenSpot])

  const onAcceptSpot = (dealHeaderID: number, sellCurrencyID: string, buyCurrencyID: string, amount: number, valueDate: string, instrument: string, delMethod: string, customRate: number, changePage: boolean) => {
    setDealID(dealHeaderID);
    setSellCurrency(sellCurrencyID);
    setBuyCurrency(buyCurrencyID);
    setAmountToDisburse(amount);
    setDeliveryMethod(delMethod);
    setCustomerRate(customRate);
    setValueDate(valueDate);
    setSettlementInstrument(instrument);
    if (changePage) { changeStep() }
  };
  const changeStep = () => {
    setCurrentScreen(1);
  }
  const onSubmitDisbursements = () => {
    setCurrentScreen(2);
  };

  const onNewTradeClick = () => {
    setCurrentScreen(0);
  };

  return (
    <React.Fragment>
      <div className="screen-container">
        <Steps current={currentScreen}>
          <Step title={getResource('Btn_GetQuote')} />
          <Step title={getResource('Disburse')} />
          <Step title={getResource('ViewPrintSummary')} />
        </Steps>
      </div>
      {currentScreen === 0 && <Spot onAcceptTrade={onAcceptSpot} dealObj={fromOtherScreenDealObj} dealHeaderID={dealID} fromOtherScreen={fromOtherScreen} objOrderScreen={props.objOrderScreen} changeStep={changeStep} />}
      {currentScreen === 1 && (
        <PaymentDisburse dealObject={null} sellCurrency={sellCurrency} buyCurrency={buyCurrency} amount={amountToDisburse} dealHeaderID={dealID} valueDate={valueDate} settlementInstrument={settlementInstrument} onSubmit={onSubmitDisbursements} deliveryMethod={deliveryMethod} customerRate={customerRate} />
      )}
      {currentScreen === 2 && <Summary dealHeaderID={dealID} useNewTradeButton={true} onClickNewTrade={onNewTradeClick} useConfirmationButton={true} />}
    </React.Fragment>
  );
};

export default SpotContainer;
