import React, { useState } from 'react';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { OnlineRightsNames } from '../../helpers/OnlineRightsHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import BeneficiaryPaymentsContainer from './beneficiaryPayments/BeneficiaryPaymentsContainer';
import TransactionContainer from './transactions/TransactionsContainer';

const TransactionsScreen = (props: any) => {
  const [currentScreen, setCurrentScreen] = useState('transaction');

  //rights
  let OL_TransactionsHistory = useOnlineRights(OnlineRightsNames.OL_TransactionsHistory);
  let OL_TransactionsBeneficiaryPayments = useOnlineRights(OnlineRightsNames.OL_TransactionsBeneficiaryPayments);


  const onChangeScreen = (screen: string) => {
    setCurrentScreen(screen);
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case 'transaction':
        return (
          OL_TransactionsHistory && <TransactionContainer
          />
        );
      case 'beneficiaryPayment':
        return OL_TransactionsBeneficiaryPayments && <BeneficiaryPaymentsContainer />;
      default:
        return <div></div>;
    }
  };

  return (
    <React.Fragment>
      <div className="navbar-horizontal">
        <div className="row">
          {OL_TransactionsHistory && <div
            className={currentScreen === 'transaction' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('transaction');
            }}
          >
            <span>{getResource('Title.Transactions')}</span>
          </div>}

          {OL_TransactionsBeneficiaryPayments && <div
            className={currentScreen === 'beneficiaryPayment' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('beneficiaryPayment');
            }}
          >
            <span>{getResource('Title.BeneficiaryPayments')}</span>
          </div>}

        </div>
      </div>
      {renderScreen()}
    </React.Fragment>
  );
};

export default TransactionsScreen;
