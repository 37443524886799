import { Steps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { saveContact } from '../../../../api/NetworkingContacts';
import { Contact } from '../../../../api/NetworkingCustomers';
import AppContext from '../../../../App.context';
import { getResource } from '../../../../helpers/ResourcesHelper';
import AddressStep, { ContactAddress } from './steps/AddressStep';
import MainInformationStep, { MainInformation } from './steps/MainInformationStep';
import TradeInformationStep, { TradeInformation } from './steps/TradeInformationStep';

const { Step } = Steps;

const AddEditContact = (props: any) => {

  const [currentScreen, setCurrentScreen]   = useState<number>(0);
  const [currentContact, setCurrentContact] = useState<Contact | null>(null);
  const [mainInformationStatus, setMainInformationStatus]   = useState<'wait' | 'process' | 'finish' | 'error' | undefined>('process');
  const [addressStatus, setAddressStatus]                   = useState<'wait' | 'process' | 'finish' | 'error' | undefined>('wait');
  const [tradeInformationStatus, setTradeInformationStatus] = useState<'wait' | 'process' | 'finish' | 'error' | undefined>('wait');

  const [mainInfoValid, setMainInfoValid] = useState<boolean>(false);
  const [addressValid, setAddressValid] = useState<boolean>(false);
  const [tradeInfoValid, setTradeInfoValid] = useState<boolean>(false);

  const {showModal} = useContext(AppContext);

  useEffect(() => {
    if(props.contactObj !== null)
    {
      setCurrentContact(props.contactObj);
    }else{
      setCurrentContact({
        Address2: '',
        AddressValue: '',
        City: '',
        ContactID: 0,
        CountryId: '',
        CountryName: '',
        CustomerStatus: '',
        DailyTradeLimit: 0,
        DailyTradeLimitWarningOrEnforce: '',
        DateOfBirth: '',
        Email: '',
        EntityProperties: '',
        FirstName: '',
        LastName: '',
        MainContact: false,
        OnlineAccess: false,
        OtherState: '',
        PhoneNumber: '',
        MobileNumber: '',
        StateId: 0,
        StateName: '',
        Status: '',
        TradeLimit: 0,
        TradeLimitWarningOrEnforce: '',
        UpdateToken: '',
        ZipCode: '',
        EntityPropertiesList: []
      });
    }
  }, [props.contactObj])

  return (
    <div className="row center-xs" >
      <div className="col-lg-12 col-md-12 col-xs-12" >
        <div className="row center-xs">
          <h3 className="component-title">{(currentContact == null || currentContact.ContactID === 0) ? getResource('New Contact') : getResource('Contact') + ' ' + currentContact.ContactID + ': ' + currentContact.FirstName + ' ' + currentContact.LastName}</h3>
        </div>
      </div>
      <div className="col-lg-2 col-md-1 col-xs-12" />
      <div className="col-lg-8 col-md-10 col-xs-12" >
        <div className="screen-container">
          <Steps current={currentScreen}>
            <Step title={getResource('MainInformation')} status={mainInformationStatus} />
            <Step title={getResource('Label.Addresses')} status={addressStatus} />
            <Step title={getResource('TradeInformation')} status={tradeInformationStatus} />
          </Steps>
        </div>
      </div>
      <div className="col-lg-2 col-md-1 col-xs-12" />
      {currentScreen === 0 && <MainInformationStep
        do                = {props.do}
        onCancel          = {() => { props.onCancelAddEdit(); }} 
        contact           = {currentContact}
        hasMainContact    = {props.hasMainContact}
        onNextStep = {
          (currentStatus: 'wait' | 'process' | 'finish' | 'error' | undefined, newMainInfo: MainInformation, valid: boolean) => { 
            setMainInformationStatus(currentStatus);
            setAddressStatus('process');
            setCurrentScreen(1);
            if(currentContact !== null)
            {
              let auxContact = currentContact;
              auxContact.MainContact = newMainInfo.MainContact;
              auxContact.FirstName = newMainInfo.FirstName;
              auxContact.LastName = newMainInfo.LastName;
              auxContact.PhoneNumber = newMainInfo.PrimaryPhone;
              auxContact.MobileNumber = newMainInfo.SecondaryPhone;
              auxContact.Email = newMainInfo.Email;
              auxContact.DateOfBirth = newMainInfo.DateOfBirth;
              setCurrentContact(auxContact);
            }
            setMainInfoValid(valid);
          }
        }
        validForm = {mainInfoValid}
      />}
      {currentScreen === 1 && <AddressStep
        do              = {props.do}
        onCancel        = {() => { props.onCancelAddEdit(); }} 
        contact         = {currentContact}
        onPreviousStep  = {
          (currentStatus: 'wait' | 'process' | 'finish' | 'error' | undefined, newAddress: ContactAddress, valid: boolean) => {
            setMainInformationStatus('process');
            setAddressStatus(currentStatus);
            setCurrentScreen(0);
            if(currentContact !== null)
            {
              let auxContactP = currentContact;
              auxContactP.AddressValue = newAddress.Address1;
              auxContactP.Address2 = newAddress.Address2;
              auxContactP.City = newAddress.City;
              auxContactP.ZipCode = newAddress.PostalCode;
              auxContactP.CountryId = newAddress.Country;
              //auxContactP.CountryName = newAddress.CountryName;
              auxContactP.StateId = newAddress.State;
              setCurrentContact(auxContactP);
            }
            setAddressValid(valid);
          }
        }
        onNextStep = {
          (currentStatus: 'wait' | 'process' | 'finish' | 'error' | undefined, newAddress: ContactAddress, valid: boolean) => {
            setAddressStatus(currentStatus);
            setCurrentScreen(2); 
            setTradeInformationStatus('process');
            if(currentContact !== null)
            {
              let auxContactN = currentContact;
              auxContactN.AddressValue = newAddress.Address1;
              auxContactN.Address2 = newAddress.Address2;
              auxContactN.City = newAddress.City;
              auxContactN.ZipCode = newAddress.PostalCode;
              auxContactN.CountryId = newAddress.Country;
              //auxContactN.CountryName = newAddress.CountryName;
              auxContactN.StateId = newAddress.State;
              setCurrentContact(auxContactN);
            }
            setAddressValid(valid);
          }
        }
        validForm = {addressValid}
      />}
      {currentScreen === 2 && <TradeInformationStep
        do              = {props.do}
        onCancel        = {() => { props.onCancelAddEdit(); }} 
        contact         = {currentContact}
        onPreviousStep  = {
          (currentStatus: 'wait' | 'process' | 'finish' | 'error' | undefined, newTrade: TradeInformation, valid: boolean) => {
            setCurrentScreen(1);
            setTradeInformationStatus(currentStatus);
            setAddressStatus('process');

            if(currentContact !== null)
            {
              let auxTradeP = currentContact;
              auxTradeP.EntityPropertiesList = newTrade.EntityPropertyList;
              auxTradeP.OnlineAccess = newTrade.OnlineAccess;
              auxTradeP.TradeLimit = newTrade.TradeLimit;
              auxTradeP.TradeLimitWarningOrEnforce = newTrade.TradeLimitWoE;
              auxTradeP.DailyTradeLimit = newTrade.DailyTradeLimit;
              auxTradeP.DailyTradeLimitWarningOrEnforce = newTrade.DailyTradeLimitWoE;
              setCurrentContact(auxTradeP);
            }
            setTradeInfoValid(valid);
          }
        }
        onSave = {
          (currentStatus: 'wait' | 'process' | 'finish' | 'error' | undefined, newTrade: TradeInformation, valid: boolean) => {
            //setTradeInformationStatus(currentStatus);
            
            if(currentContact !== null && valid === false && mainInfoValid === false && addressValid === false)
            {
              let userSession = JSON.parse(localStorage.getItem('UserSession')!);

              var model = {
                "CustomerID"            : userSession.CustomerID,
                "ContactID"             : currentContact.ContactID,
                "MainContact"           : currentContact.MainContact === true ? 1 : 0,
                "FirstName"             : currentContact.FirstName,
                "LastName"              : currentContact.LastName,
                "DateOfBirth"           : currentContact.DateOfBirth,
                "AddressValue"          : currentContact.AddressValue,
                "Address2"              : currentContact.Address2,
                "City"                  : currentContact.City,
                "CountryID"             : currentContact.CountryId,
                "CountryName": currentContact.CountryName,
                "StateID"               : currentContact.StateId,
                "OtherState"            : currentContact.OtherState,
                "PostalCode"            : currentContact.ZipCode,
                "PhoneNumber"           : currentContact.PhoneNumber,
                "MobileNumber"          : currentContact.MobileNumber,
                "Email"                 : currentContact.Email,
                "EntityPropertiesJSON"  : JSON.stringify(newTrade.EntityPropertyList),
                "UpdatedBy"             : userSession.PersonID,
                "UpdateToken"           : currentContact.UpdateToken,
                "TradeLimit"            : newTrade.TradeLimit,
                "TradeLimitWoE"         : newTrade.TradeLimitWoE,
                "DailyTradeLimit"       : newTrade.DailyTradeLimit,
                "DailyTradeLimitWoE"    : newTrade.DailyTradeLimitWoE,
                "OnlineAccess"          : newTrade.OnlineAccess === true ? 1 : 0
              }
              
              console.log("model -> ", model);

              saveContact(model).then((response) => 
              {
                  console.log('saveContact: '+ JSON.stringify(response));
                  /*saveContact: 
                  {
                      "contactId":9365,
                      "updateToken":"",
                      "httpStatusCode":200
                  }*/

                  if(response !== undefined)
                  {
                      if (response.contactId !== undefined) 
                      {
                        showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfully'));
                        props.onCancelAddEdit();
                      }
                      else
                      {
                        if(response.Message !== undefined)
                        {
                          let messageToAlert = ''
                          switch(response.Message)
                          {
                            case 'concurrency':
                            {
                              messageToAlert = getResource('Js_MessageError_Concurrency');
                              break;
                            }
                            default:
                            {
                              messageToAlert = response.Message;
                              break;
                            }
                          }

                          showModal(getResource('Js_MessageError_TitleError'), messageToAlert);
                        }
                      }
                  }else{
                    showModal(getResource('Js_MessageError_TitleError'), getResource('SomethingWentWrongTryAgain'));
                  }
              });
            }else{
              showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
            }
          }
        }
        validForm = {tradeInfoValid}
      />}
    </div>
  );
}

export default AddEditContact