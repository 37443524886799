import React, { FunctionComponent, useContext, useState } from 'react';
import { Message, replyMessage } from '../../../api/NetworkingMessages';
import AppContext from '../../../App.context';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';

interface MessageReplyProps {
  subject: string;
  message: Message | null;
  handleBackLink: (showSuccessAlert?: boolean) => void;
}

const MessageReply: FunctionComponent<MessageReplyProps> = ({ subject, message, handleBackLink: returnToMessageContent }) => {
  const { showModal } = useContext(AppContext);
  const [content, setContent] = useState('');

  const handleSendReply = async () => {
    const model = {
      content: content,
      senderId: message!.ReceiverId,
      sentOn: new Date().toISOString(),
      subject: subject,
      receiverId: message!.SenderId
    };
    const json = await replyMessage(model);
    if (json != null) {
      if (json.httpStatusCode !== 200) {
        showModal(getResource('Js_MessageError_TitleError'), json.httpErrorMessage);
      } else {
        // showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Message_Successfully_Sent'));
        returnToMessageContent(true)
      }
    }
  };

  const onChangeContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
  };

  const handleBackLink = () => {
    returnToMessageContent();
  };

  return (
    <div className="screen-container">
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Table_TH_Subject')}</span>
          </div>
          <div className="row">
            <input id="reply-subject" disabled={true} value={subject} type="text" />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Message_To')}</span>
          </div>
          <div className="row">
            <input id="reply-to" disabled={true} value={message?.Sender} type="text" />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Content')}</span>
          </div>
          <div className="row">
            <textarea id="reply-content" value={content} onChange={onChangeContent} />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row center-xs">
            <Button key="go-back-address" type="secondary" onClick={handleBackLink}>
              {getResource('Button.Cancel')}
            </Button>
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row center-xs">
            <Button key="save-address" onClick={handleSendReply}>
              {getResource('Button.Send')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageReply;
