import { DownloadOutlined, MessageOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getListOfMessages, Message } from '../../api/NetworkingMessages';
import AppContext from '../../App.context';
import { getResource } from '../../helpers/ResourcesHelper';
import DataGrid from '../table/DataGrid';
import MessageContent from './messages/MessageContent';

const MyMessages = () => {
  const { showModal } = useContext(AppContext);
  const [messages, setMessages] = useState<Message[]>([]);
  const [updating, setUpdating] = useState(false);
  const [viewMessageContent, setViewMessageContent] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);

  useEffect(() => {
    const loadMessages = async () => {
      const json = await getListOfMessages();
      if (json != null && json.messages != null) {
        setMessages(json.messages);
      }
    };
    loadMessages();
  }, [updating]);

  const selectMessageToReply = (record: Message) => {
    setSelectedMessage(record);
    setViewMessageContent(true);
  };

  const handleBackLink = (showSuccessAlert: boolean = false) => {
    if (showSuccessAlert) {
      showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Message_Successfully_Sent'));
    }
    setSelectedMessage(null);
    setViewMessageContent(false);
    setUpdating(!updating);
  };

  const columns = [
    {
      title: getResource('Table_TH_Date'),
      dataIndex: 'SentOn',
      key: 'sentOn',
      width: '15%',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a: Message, b: Message) => {
        return a['MessageId'] - b['MessageId'];
      },
    },
    {
      title: getResource('Table_TH_Sender'),
      dataIndex: 'Sender',
      key: 'sender',
      width: '10%',
      align: 'center',
      sorter: (a: Message, b: Message) => {
        return a['Sender'].localeCompare(b['Sender']);
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: getResource('Table_TH_Subject'),
      dataIndex: 'Subject',
      key: 'subject',
      width: '10%',
      align: 'center',
      sorter: (a: Message, b: Message) => {
        return a['Subject'].localeCompare(b['Subject']);
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Body',
      dataIndex: 'Content',
      width: '55%',
      editable: true,
      align: 'center',
      sorter: (a: Message, b: Message) => {
        return a['Content'].localeCompare(b['Content']);
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: getResource('Table_TH_Action'),
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_: any, record: Message) => (
        <div>
          <Tooltip title={getResource('Js_Link_ViewMessage')}>
            <Typography.Link onClick={() => selectMessageToReply(record)}>
              <MessageOutlined style={{ fontSize: 18 }} />
            </Typography.Link>
          </Tooltip>
        </div>
      )
    }
  ];

  if (viewMessageContent) {
    return <MessageContent message={selectedMessage} handleBackLink={handleBackLink} />;
  }

  return (
    <div className="row center-xs">
      <DataGrid className="" columns={columns} dataSource={messages}
        exportableProps={{
          fileName: 'MyMessages',
          btnProps: {
            type: "default",
            icon: <DownloadOutlined />,
          }
        }} />
    </div>
  );
};

export default MyMessages;
