import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { getListOfRelatedCustomers } from "../../../api/NetworkingCustomers";
import { logout, updateSession } from '../../../api/NetworkingSecurity';
import { getCompanyLogo } from "../../../helpers/PackageJsonHelper";
// import logo from '../../../images/logo_generic.png';
//import logo from '../../../images/Logo_Premier.png';
import Dropdown from '../../inputs/Dropdown';

const logo = getCompanyLogo();

interface BrandBarProps {
  onLogout: Function;
  onChangeScreen: Function;
  onClickProfile: Function;
  onUpdateSession: (newSession: any) => void;
}

const BrandBar: FunctionComponent<BrandBarProps> = (props) => {
  const userSession = useMemo(() => JSON.parse(localStorage.getItem('UserSession')!), []);
  const [customerID, setCustomerID] = useState(userSession.CustomerID.toString());
  const [relatedCustomers, setRelatedCustomers] = useState([]);

  useEffect(() => {
    const getRelatedCustomers = async () => {
      const json = await getListOfRelatedCustomers(userSession.CustomerID);
      if (json != null && json.Customers != null) {
        const customers = json.Customers.map((c: any) => ({ ...c, value: c.CustomerID.toString(), label: c.Name, status: c.Status }));
        setRelatedCustomers(customers);
      }
    };
    getRelatedCustomers();
  }, [userSession.CustomerID]);

  const onChangeCustomer = async (value: any) => {
    if (value != null && value.value != null) {
      if (value.value !== customerID) {
        const json = await updateSession(value.value, userSession.InstallationID);
        if (json != null && json.OnlineUserSession !== null) {
          props.onUpdateSession(json.OnlineUserSession);
        }
        setCustomerID(value.value);
      }
    }
  };
  const onChangeScreen = () => {
    props.onChangeScreen('dashboard');
  }
  const onLogoutClick = () => {
    logout().then((logoutResponse: any) => {
      localStorage.clear();
      props.onLogout();
    })
  }
  return (
    <React.Fragment>
      <div className="row brandbar">
        <div className="col-lg-1 col-md-1 col-xs-12"></div>
        <div className="col-lg-2 col-md-2 none-medium">
          <span><a href="#"><img className="col-xs-8" src={logo} alt="" onClick={onChangeScreen} /></a></span>
        </div>
        <div className="col-lg-4 col-md-4 none-medium"></div>
        <div className="col-lg-2 col-md-2 col-xs-5 row middle-xs end-xs">
          <Dropdown key={'related-customers'} name="related-customers" title="Select a related customer..." select={{ value: customerID }} list={relatedCustomers} onChange={(value: any) => onChangeCustomer(value)} />
        </div>
        <div className="col-lg-1 col-md-1 col-xs-5 row middle-xs end-xs">
          <div className="col-lg-12 col-md-11 col-xs-11" onClick={() => props.onClickProfile()}>
            <p className="profile-contact-name">{userSession.ContactName}</p>
          </div>
          {/*<div className="col-lg-2 col-md-3 col-xs-3 row" onClick={()=>props.onClickProfile()}>
                    <img className="profile-image middle-xs" src={profile} alt=""/>
                </div>*/}
        </div>
        <div className="col-lg-1 col-md-1 col-xs-2 row middle-xs end-xs">
          <span className="material-icons-round navbar-icon profile-logout" onClick={() => onLogoutClick()}>logout</span>
        </div>
        <div className="col-lg-1 col-md-1 none-medium"></div>
      </div>
    </React.Fragment>
  );
};

export default BrandBar;