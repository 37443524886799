import { DownloadOutlined } from '@ant-design/icons';
import { Table } from 'ant-table-extensions';
import { Breadcrumb, Collapse } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as mime from 'mime-types';
import moment, { Moment } from 'moment';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { getBeneficiariesByCustomerMin } from '../../api/NetworkingBeneficiaries';
import { getCurrenciesByCustomer } from '../../api/NetworkingCurrencies';
import { getAttachmentInvoice, getInvoicesByDate } from '../../api/NetworkingInvoice';
import AppContext from '../../App.context';
import { formatDate, formatDateString, formatToCurrency } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import DatePicker from '../inputs/DatePicker';
import InvoiceAddEditFormDialog from './InvoiceAddEditFormDialog';
import InvoiceUpload from './InvoiceUpload';
const dateFormat = 'YYYY/MM/DD';

const { Panel } = Collapse;
interface InvoiceScreenProps {
}
export class ResponseGetInvoicing {
    'Invoice Number': string;
    'Invoice Date': string;
    'External Customer Name': string;
    'Invoice Description': string;
    'Total Invoice': string;
    'External Customer Email': string;
    Terms: number;
    BeneficiaryID: number;
    Beneficiary: string;

    constructor(invoiceNumber: string, invoiceDate: string, externalCustName: string, invoiceDescription: string, totalInvoice: string, externalCustEmail: string, terms: number, beneficiaryID: number, beneficiary: string) {
        this['Invoice Number'] = invoiceNumber;
        this['Invoice Date'] = invoiceDate;
        this['External Customer Name'] = externalCustName;
        this['Invoice Description'] = invoiceDescription;
        this['Total Invoice'] = totalInvoice;
        this['External Customer Email'] = externalCustEmail;
        this.Terms = terms;
        this.BeneficiaryID = beneficiaryID;
        this.Beneficiary = beneficiary;
    }
}
const InvoiceScreen: FunctionComponent<InvoiceScreenProps> = (props) => {
    const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
    const [updating, setUpdating] = useState<boolean>(true);
    const [showUploadScreen, setShowUploadScreen] = useState(false);
    const [invoiceType, setInvoiceType] = useState('Payable');
    const [showAddNew, setShowAddNew] = useState(false);
    const [currentTab, setCurrentTab] = useState('Payable');
    const [fromDate, setFromDate] = useState(moment(new Date(), dateFormat).format(dateFormat));
    const [toDate, setToDate] = useState(moment(new Date(), dateFormat).format(dateFormat));
    const [today, setToday] = useState(moment(new Date(), dateFormat).format(dateFormat));
    const [momentFromDate, setMomentFromDate] = useState<Moment>(moment());
    const [momentToDate, setMomentToDate] = useState<Moment>(moment());

    const [validations, setValidations] = useState({
        fromDate: true,
        toDate: true
    });
    const [invoiceListSearch, setInvoiceListSearch] = useState([]);
    const [invoiceListSearchFilter, setInvoiceListSearchFilter] = useState([]);
    const [settlementCurrencies, setSettlementCurrencies] = useState([]);
    const [currentFileName, setCurrentFileName] = useState('');
    const [currentFileURLList, setCurrentFileURLList] = useState([]);
    const [showOpenAlert, setShowOpenAlert] = useState(false);
    const [currentBlob, setCurrentBlob] = useState({} as any);
    const [currentExtension, setCurrentExtension] = useState('');
    const [openAttachment, setOpenAttachment] = useState(false);
    const [beneficiaries, setBeneficiaries] = useState([]);

    //-----------For Render------------
    const [classNamePreviousDealsP, setClassNamePreviousDealsP] = useState('');
    const [listHiddenR, setListHiddenR] = useState('');
    const [classNamePreviousDealsR, setClassNamePreviousDealsR] = useState('');
    const [listHiddenP, setListHiddenP] = useState('');
    const [listOfInvoiceExportPayable, setListOfInvoiceExportPayable] = useState([]);
    const [listOfInvoiceExportReceivable, setListOfInvoiceExportReceivable] = useState([]);


    useEffect(() => {
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        getCurrenciesByCustomer(userSession.CustomerID).then(
            (json) => {
                if (json !== undefined) {
                    let jsonCurrencies = json.currencies;

                    var settlementHelper = [] as any;
                    if (jsonCurrencies !== undefined) {
                        for (var i = 0; i < jsonCurrencies.length; i++) {
                            var currencyElement = {
                                value: jsonCurrencies[i].CurrencyID,
                                label: jsonCurrencies[i].CurrencyIDName,
                                numberOfDecimals: jsonCurrencies[i].NumberOfDecimals,
                                currencyType: jsonCurrencies[i].CurrencyType
                            };
                            switch (jsonCurrencies[i].Type) {
                                case 'Settlement':
                                    settlementHelper.push(currencyElement);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    setSettlementCurrencies(settlementHelper);
                }
            }
        );

        getBeneficiariesByCustomerMin(userSession.CustomerID, 'Authorized,Pending Activation').then(
            (response) => {
                if (typeof response !== undefined &&
                    response.httpStatusCode === 200 &&
                    response.beneficiaries !== undefined) {
                    var benes = response.beneficiaries;
                    var helper = [] as any;
                    for (var i = 0; i < benes.length; i++) {
                        benes[i].value = benes[i]["BeneficiaryID"];
                        benes[i].label = benes[i]["Name"];
                        helper.push(benes[i]);
                    }
                    setBeneficiaries(helper);
                }
            });
    }, [updating]);

    const validateDates = (fromDate: any, toDate: any) => {
        var result = true;
        var tempValidations = validations;
        tempValidations = resetValidations(tempValidations);
        if (fromDate === undefined || fromDate === '' || fromDate === 'NaN/NaN/NaN') {
            tempValidations.fromDate = false;
            result = false;
        }

        if (toDate === undefined || toDate === '' || toDate === 'NaN/NaN/NaN') {
            tempValidations.toDate = false;
            result = false;
        }

        if (fromDate > toDate) {
            tempValidations.fromDate = false;
            tempValidations.toDate = false;
            result = false;
        }
        setValidations(tempValidations);
        return result;
    }

    const getInvoicesByDates = async (fromDate: any, toDate: any, customerID: any, invoiceType: any) => {
        if (validateDates(fromDate, toDate)) {
            const json = await getInvoicesByDate(fromDate, toDate, customerID, invoiceType);
            if (json !== undefined) {
                console.log(json);
                var invoices = json.invoices;
                setInvoiceListSearch(invoices);
                hideLoadingModal();
            }
            else {
                setInvoiceListSearch([]);
                hideLoadingModal()
            }
        }
    }

    useEffect(() => {
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        var auxFromDate = formatDate(fromDate);
        var auxToDate = formatDate(toDate);
        const loadInvoices = async () => {
            getInvoicesByDates(auxFromDate, auxToDate, userSession.CustomerID, invoiceType)
        }
        loadInvoices();
    }, [currentTab]);

    useEffect(() => {
        isRender();
    }, [invoiceListSearch]);

    const handleAddNewClick = () => {
        setShowAddNew(true);
    }

    const onCloseAddNew = () => {
        setShowAddNew(false);
    }

    const updateTab = (tab: any) => {
        setCurrentTab(tab);
        setInvoiceType(tab);
        setInvoiceListSearch([]);
    }

    const resetValidations = (validations: any) => {
        validations.fromDate =
            validations.toDate = true
        return validations;
    }

    const SearchInvoices = () => {
        var auxFromDate = formatDate(fromDate);
        var auxToDate = formatDate(toDate);
        showLoadingModal();
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        /*if (validateDates(auxFromDate, auxToDate)) {
            getInvoicesByDate(auxFromDate, auxToDate, userSession.CustomerID, invoiceType).then(
                (json) => {
                    if (json !== undefined) {
                        console.log(json);
                        var invoices = json.invoices;
                        setInvoiceListSearch(invoices);
                        hideLoadingModal();
                    }
                    else {
                        setInvoiceListSearch([]);
                        hideLoadingModal()
                    }

                }
            );
        }*/
        getInvoicesByDates(auxFromDate, auxToDate, userSession.CustomerID, invoiceType);
    }

    const handleUpdateFromDate = (date: Moment | null, dateString: string) => {
        setFromDate(dateString);
        setMomentFromDate(date as Moment);
    }

    const handleUpdateToDate = (date: Moment | null, dateString: string) => {
        setToDate(dateString);
        setMomentToDate(date as Moment);
    }

    const Clear = () => {
        setInvoiceListSearch([]);
        setInvoiceListSearchFilter([]);
    }

    const formatValidDatePicker = (valid: any) => { return valid ? "DayPickerInput" : "error"; }

    const onViewAttachment = (InvoiceIssuedID: any, FileName: string) => {
        getAttachmentInvoice(InvoiceIssuedID).then(
            (value) => {
                console.log(value);
                var byteCharacters = atob(value.data);
                var byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var bytes = new Uint8Array(byteNumbers);
                var blob = new Blob(
                    [bytes], {
                    type: mime.lookup(File.name) as string
                });
                /*var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = obj.Filename;
                link.click();*/

                var arrayName = FileName.split(".")

                let fileList = [{
                    uri: window.URL.createObjectURL(blob),
                    fileType: arrayName[arrayName.length - 1].toLowerCase(),
                    fileName: FileName
                }] as any;
                if (arrayName.length !== 0) {
                    if (arrayName[arrayName.length - 1] === 'xlsx' || arrayName[arrayName.length - 1] === 'xls' ||
                        arrayName[arrayName.length - 1] === 'docx' || arrayName[arrayName.length - 1] === 'doc' ||
                        arrayName[arrayName.length - 1] === 'docm') {
                        setShowOpenAlert(true);
                        setCurrentBlob(blob);
                        setCurrentFileName(FileName);
                        setCurrentExtension(arrayName[arrayName.length - 1]);
                    } else {
                        setCurrentFileName(File.name);
                        setCurrentFileURLList(fileList);
                        setOpenAttachment(true);
                    }
                }
            });
    }

    const onCloseViewAttachment = () => {
        setCurrentFileName([] as any);
        setCurrentFileURLList([] as any);
        setOpenAttachment(false);
    }

    const handleUploadClickFromScreen = () => {
        setShowUploadScreen(true);
    }

    const closeUploadScreen = () => {
        setShowUploadScreen(false);
    }

    const onLoadInvoices = () => {
        debugger;
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        var auxFromDate = formatDate(fromDate);
        var auxToDate = formatDate(toDate);
        const loadInvoices = async () => {
            getInvoicesByDates(auxFromDate, auxToDate, userSession.CustomerID, invoiceType)
        }
        loadInvoices();
    }

    const isRender = () => {
        var auxFromDate = formatDate(fromDate);
        var auxToDate = formatDate(toDate);
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        switch (currentTab) {
            case 'Payable':
            default:
                //List View
                setClassNamePreviousDealsR('');
                setListHiddenP('');
                setClassNamePreviousDealsP('active');
                setListHiddenR(' hidden');
                break;
            case 'Receivable':
                //List View
                setClassNamePreviousDealsR('active');
                setListHiddenP(' hidden');
                setClassNamePreviousDealsP('');
                setListHiddenR('');
                break;
        }
        var listOfInvoiceExport = [] as any;

        if (invoiceListSearch != null && invoiceListSearch.length > 0) {
            invoiceListSearch.forEach((element: any) => {
                var id = element.InvoiceIssuedID;
                var total = formatToCurrency(element.Total, element.CurrencyID) + ' ' + element.CurrencyID;
                var hasFile = '' as any;
                if (invoiceType === 'Receivable') {
                    if (element.FileName !== '' && element.FileName != null) {
                        hasFile = <a href="#"><span className="" title={getResource('Label.View')}><i onClick={onViewAttachment.bind(this, element.InvoiceIssuedID, element.FileName)} className='fa fa-paperclip'></i></span>
                            <span className="hidden tooltip-hover" style={{ left: "-10%" }}>{getResource('Label.View')}</span></a>;
                    }
                    listOfInvoiceExport.push(
                        {
                            'Invoice Number': element.InvoiceNumber,
                            'Invoice Date': element.InvoiceDate,
                            'External Customer Name': element.ExternalCustomerName,
                            'Invoice Description': element.InvoiceDescription,
                            'Total Invoice': total,
                            'External Customer Email': element.Email,
                            'Terms': element.Terms
                        }
                    );
                    setListOfInvoiceExportReceivable(listOfInvoiceExport);
                }
                else {
                    if (element.FileName !== '' && element.FileName != null) {
                        hasFile = <a href="#"><span className="" title={getResource('Label.View')}><i onClick={onViewAttachment.bind(this, element.InvoiceIssuedID, element.FileName)} className='fa fa-paperclip'></i></span>
                            <span className="hidden tooltip-hover" style={{ left: "-10%" }}>{getResource('Label.View')}</span></a>;
                    }
                    listOfInvoiceExport.push(
                        {
                            'Invoice Number': element.InvoiceNumber,
                            'Invoice Date': element.InvoiceDate,
                            'BeneficiaryID': element.BeneficiaryID,
                            'Beneficiary': element.BeneficiaryName,
                            'Invoice Description': element.InvoiceDescription,
                            'Total Invoice': total,
                            'Terms': element.Terms
                        }
                    );
                    setListOfInvoiceExportPayable(listOfInvoiceExport);
                }
            }
            );
        }
    }

    const invoicingColumnsPayable: ColumnsType<ResponseGetInvoicing> = [
        {
            title: getResource('Label.InvoiceNumber'),
            dataIndex: 'Invoice Number',
            key: 'history_invoiceNumber',
            align: 'left',
            sorter: (a, b) => a['Invoice Number'].localeCompare(b['Invoice Number'])
        },
        {
            title: getResource('Label.InvoiceDate'),
            dataIndex: 'Invoice Date',
            key: 'history_invoiceDate',
            align: 'center',
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{formatDateString(value)}</span>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a['Invoice Date']).getTime() - new Date(b['Invoice Date']).getTime()
        },
        {
            title: getResource('Label.InvoiceBeneficiaryID'),
            dataIndex: 'BeneficiaryID',
            key: 'history_beneficiaryID',
            align: 'left',
            sorter: (a, b) => a['BeneficiaryID'] - b['BeneficiaryID']
        },
        {
            title: getResource('Label.InvoiceBeneficiaryName'),
            dataIndex: 'Beneficiary',
            key: 'history_beneficiary',
            align: 'left',
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{value}</span>
            ),
            sorter: (a, b) => a['Beneficiary'].localeCompare(b['Beneficiary'])
        },
        {
            title: getResource('Label.InvoiceDescription'),
            dataIndex: 'Invoice Description',
            key: 'history_invoiceDescription',
            sorter: (a, b) => a['Invoice Description'].localeCompare(b['Invoice Description']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTotal'),
            dataIndex: 'Total Invoice',
            key: 'history_totalInvoice',
            sorter: (a, b) => a['Total Invoice'].localeCompare(b['Total Invoice']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTerms'),
            dataIndex: 'Terms',
            key: 'history_terms',
            sorter: (a, b) => a.Terms - b.Terms,
            align: 'left',
        },
    ];
    const invoicingColumnsReceivable: ColumnsType<ResponseGetInvoicing> = [
        {
            title: getResource('Label.InvoiceNumber'),
            dataIndex: 'Invoice Number',
            key: 'history_invoiceNumber',
            align: 'left',
            sorter: (a, b) => a['Invoice Number'].localeCompare(b['Invoice Number'])
        },
        {
            title: getResource('Label.InvoiceDate'),
            dataIndex: 'Invoice Date',
            key: 'history_invoiceDate',
            align: 'center',
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{formatDateString(value)}</span>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a['Invoice Date']).getTime() - new Date(b['Invoice Date']).getTime()
        },
        {
            title: getResource('Label.InvoiceSenderName'),
            dataIndex: 'External Customer Name',
            key: 'history_externalCustomerName',
            sorter: (a, b) => a['External Customer Name'].localeCompare(b['External Customer Name']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceDescription'),
            dataIndex: 'Invoice Description',
            key: 'history_invoiceDescription',
            sorter: (a, b) => a['Invoice Description'].localeCompare(b['Invoice Description']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTotal'),
            dataIndex: 'Total Invoice',
            key: 'history_totalInvoice',
            sorter: (a, b) => a['Total Invoice'].localeCompare(b['Total Invoice']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceSenderEmail'),
            dataIndex: 'External Customer Email',
            key: 'history_externalCustomerEmail',
            sorter: (a, b) => a['External Customer Email'].localeCompare(b['External Customer Email']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTerms'),
            dataIndex: 'Terms',
            key: 'history_terms',
            sorter: (a, b) => a.Terms - b.Terms,
            align: 'left',
        },
    ];
    const renderScreen = () => {
        switch (currentTab) {
            case 'Payable':
                return <section className={'board board-spot square-relative' + listHiddenP}>
                    <Collapse defaultActiveKey={['1']} style={{ marginBottom: '15px' }}>
                        <Panel header={'Filter Options'} key="1">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-2 col-xs-12">
                                        <div className="row">
                                            <span className="input-label">{getResource('DateFrom')}:</span>
                                        </div>
                                        <div className="row">
                                            <DatePicker
                                                id="ratewatch-datepicker-from"
                                                key="ratewatch-datepicker-from"
                                                //className={formatValidDatePicker(validations.fromDate)}
                                                onChange={handleUpdateFromDate}
                                                value={momentFromDate}
                                                submit={true}
                                                errorMessage={"Required"}
                                                valueValidation={fromDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-xs-12">
                                        <div className="row">
                                            <span className="input-label">{getResource('DateTo')}:</span>
                                        </div>
                                        <div className="row">
                                            <DatePicker
                                                id="ratewatch-datepicker-todate"
                                                key="ratewatch-datepicker-todate"
                                                onChange={handleUpdateToDate}
                                                value={momentToDate}
                                                submit={true}
                                                errorMessage={"Required"}
                                                valueValidation={toDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                                        <Button type="secondary" onClick={SearchInvoices}>
                                            {getResource('ApplyFilter')}
                                        </Button>
                                    </div>
                                    <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                                        <Button type="primary" onClick={Clear}>
                                            {getResource('Reset')}
                                        </Button>
                                    </div>
                                    <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                                        <Button type="primary" onClick={handleAddNewClick}>
                                            {getResource('AddNew')}
                                        </Button>
                                    </div>
                                    <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                                        <Button type="primary" onClick={handleUploadClickFromScreen}>
                                            {getResource('Upload')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>
                    <Table
                        className='col-md-12'
                        key={'invoicing-table'}
                        columns={invoicingColumnsPayable}
                        dataSource={listOfInvoiceExportPayable}
                        exportableProps={{
                            fileName: 'Invoices',
                            btnProps: {
                                type: "default",
                                icon: <DownloadOutlined />,
                            }
                        }}
                    />
                </section>;
            case 'Receivable':
                return <section className={'board board-spot square-relative' + listHiddenR}>
                    <Collapse defaultActiveKey={['1']} style={{ marginBottom: '15px' }}>
                        <Panel header={'Filter Options'} key="1">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-2 col-xs-12">
                                        <div className="row">
                                            <span className="input-label">{getResource('DateFrom')}:</span>
                                        </div>
                                        <div className="row">
                                            <DatePicker
                                                id="ratewatch-datepicker-from"
                                                key="ratewatch-datepicker-from"
                                                //className={formatValidDatePicker(validations.fromDate)}
                                                onChange={handleUpdateFromDate}
                                                value={momentFromDate}
                                                submit={true}
                                                errorMessage={"Required"}
                                                valueValidation={fromDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-xs-12">
                                        <div className="row">
                                            <span className="input-label">{getResource('DateTo')}:</span>
                                        </div>
                                        <div className="row">
                                            <DatePicker
                                                id="ratewatch-datepicker-todate"
                                                key="ratewatch-datepicker-todate"
                                                onChange={handleUpdateToDate}
                                                value={momentToDate}
                                                submit={true}
                                                errorMessage={"Required"}
                                                valueValidation={toDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                                        <Button type="secondary" onClick={SearchInvoices}>
                                            {getResource('ApplyFilter')}
                                        </Button>
                                        </div>
                                    <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                                        <Button type="primary" onClick={Clear}>
                                            {getResource('Reset')}
                                        </Button>
                                        </div>
                                    <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                                        <Button type="primary" onClick={handleAddNewClick}>
                                            {getResource('AddNew')}
                                        </Button>
                                    </div>
                                    <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                                        <Button type="primary" onClick={handleUploadClickFromScreen}>
                                            {getResource('Upload')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>
                    <Table
                        className='col-md-12'
                        key={'invoicing-table'}
                        columns={invoicingColumnsReceivable}
                        dataSource={listOfInvoiceExportReceivable}
                        exportableProps={{
                            fileName: 'Invoices',
                            btnProps: {
                                type: "default",
                                icon: <DownloadOutlined />,
                            }
                        }}
                    />
                </section>;
            default:
                return <div></div>;
        }
    };
    return (
        <div className="main-container" >
            <div className="breadcrumb-container">
                <Breadcrumb separator="">
                    <Breadcrumb.Item>{getResource('Title.Invoicing')}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="navbar-horizontal">
                <div className="row">
                    <div
                        className={currentTab === 'Payable' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
                        onClick={() => {
                            updateTab('Payable')
                        }}
                    >
                        <span>{getResource('Payables')}</span>
                    </div>
                    <div
                        className={currentTab === 'Receivable' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
                        onClick={() => {
                            updateTab('Receivable')
                        }}
                    >
                        <span>{getResource('Receivables')}</span>
                    </div>
                </div>
            </div>

            {renderScreen()}

            {
                showAddNew &&
                <InvoiceAddEditFormDialog
                    onClose={onCloseAddNew}
                    settlementCurrencies={settlementCurrencies}
                    InvoiceType={invoiceType}
                    beneficiaries={beneficiaries}
                    onLoadInvoices={onLoadInvoices} />
            }
            {
                showUploadScreen &&
                <InvoiceUpload
                    onClose={closeUploadScreen}
                    InvoiceType={invoiceType} />
            }
            {/*
            <ViewAttachment currentFileName={currentFileName}
                currentFileURLList={currentFileURLList}
                showOpenAlert={showOpenAlert}
                currentBlob={currentBlob}
                currentExtension={currentExtension}
                openAttachment={openAttachment}
                onCloseAttachment={onCloseViewAttachment}
                closeAlert={closeAlert}></ViewAttachment>
            */}
        </div >
    );
}
export default InvoiceScreen;