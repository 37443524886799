import { DownloadOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Table } from 'ant-table-extensions';
import { Collapse, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { getListOfPendingApprovalDeals } from '../../../api/NetworkingDeals';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import { Deal } from '../../models/Deal';

const { Panel } = Collapse;

const PendingDealsTable = (props: any) => {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [dealHeaderID, setDealHeaderID] = useState('');

  useEffect(() => {
    getListOfPendingApprovalDeals(JSON.parse(localStorage.getItem('UserSession')!).CustomerID, JSON.parse(localStorage.getItem('UserSession')!).ContactID, 1, 100).then((response) => {
      //getListOfDeals(0, stringDealType, '', fromDate, toDate, JSON.parse(localStorage.getItem('UserSession')!).CustomerID, 0, 1, 0).then((response) => {
      if (response != null && response.dealsToApprove != null) {
        response.dealsToApprove.forEach((deal: any) => {
          deal.key = deal.DealHeaderID;
        });
        setDeals(response.dealsToApprove);
      } else {
        setDeals([])
      }
    });
  }, [updating]);

  const columns: ColumnsType<Deal> = [
    {
      title: getResource('Table_TH_DealNo'),
      dataIndex: 'DealHeaderID',
      key: 'deal_dealid',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.DealID - b.DealID
    },
    {
      title: getResource('Table_TH_Type'),
      dataIndex: 'DealType',
      key: 'deal_dealtype',
      align: 'center',
      sorter: (a, b) => a.DealType.localeCompare(b.DealType)
    },
    {
      title: getResource('Table_TH_BuyAmount'),
      dataIndex: 'SellCurrency',
      key: 'deal_buyamount',
      align: 'center',
      sorter: (a, b) => a.SellAmount! - b.SellAmount!,
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.SellAmount, obj.SellCurrency)} {obj.SellCurrency}
        </span>
      )
    },
    {
      title: getResource('Table_TH_CustomerRate'),
      dataIndex: 'CustomerRate',
      key: 'deal_customerrate',
      align: 'center',
      sorter: (a, b) => a.CustomerRate! - b.CustomerRate!,
      render: (value: any, obj: any, index: any) => <span>{obj.CustomerRate}</span>
    },
    {
      title: getResource('Table_TH_SellAmount'),
      dataIndex: 'BuyCurrency',
      key: 'deal_sellamount',
      align: 'center',
      sorter: (a, b) => a.BuyAmount! - b.BuyAmount!,
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.BuyAmount, obj.BuyCurrency)} {obj.BuyCurrency}
        </span>
      )
    },
    {
      title: getResource('Table_TH_ValueDate'),
      dataIndex: 'ValueDate',
      key: 'deal_valuedate',
      align: 'center',
      sorter: (a, b) => new Date(a.ValueDate).getTime() - new Date(b.ValueDate).getTime()
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('Js_Link_ViewDeal')}>
            <Typography.Link
              onClick={() => {
                selectRow(obj);
              }}
            >
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];

  const selectRow = (record: any) => {
    props.selectRow(record.DealHeaderID);
  };

  return (
    <React.Fragment>
      <Table
        className=""
        key={'pending-deals-table'}
        columns={columns}
        dataSource={deals}
      /*onRow={(record, rowIndex) => ({
        onClick: () => {
          selectRow(record);
        }
      })}*/
        exportableProps={{
          fileName: 'PendingDeals',
          btnProps: {
            type: "default",
            icon: <DownloadOutlined />,
          }
        }}
      />
    </React.Fragment>
  );
};

export default PendingDealsTable;
