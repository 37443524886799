import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import useCurrencies, { Currency } from '../../../../api/hooks/useCurrencies';
import { getListOfCustomerBankAccounts } from '../../../../api/NetworkingBankAccount';
import { getOnlineBeneficiaries } from '../../../../api/NetworkingBeneficiaries';
import AppContext from '../../../../App.context';
import { formatValidInputClass } from '../../../../helpers/FormatHelper';
import { getResource } from '../../../../helpers/ResourcesHelper';
import Button from '../../../inputs/Button';
import Dropdown from '../../../inputs/Dropdown';
import Input from '../../../inputs/Input';
import { CurrencyDetailsModel } from '../AddEditRegularPayment';
type StepStatus = 'wait' | 'process' | 'finish' | 'error' | undefined;
interface CurrencyDetailsProps {
  details: CurrencyDetailsModel | null;
  action: string;
  onCancel: () => void;
  onStep: (currenStatus: StepStatus, newCurrencyDetails: CurrencyDetailsModel, valid: boolean) => void;
}

interface Validation {
  valid: boolean;
  validations: {
    accountToDebit: boolean;
    paymentAccount: boolean;
    sendInput: boolean;
    sendCurrency: boolean;
  };
}

const CurrencyDetails: React.FC<CurrencyDetailsProps> = ({ details, action, onCancel, onStep }) => {
  const { showModal } = useContext(AppContext);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [allCurrencies] = useCurrencies();
  const [currencies, setCurrencies] = useState([]);
  const [accountToDebit, setAccountToDebit] = useState('');
  const [beneficiaryPayTo, setBeneficiaryPayTo] = useState('');
  const [sendInput, setSendInput] = useState<number | string>('');
  const [sendCurrency, setSendCurrency] = useState('');
  const [beneficiaryID, setBeneficiaryID] = useState(0);
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [settlementCurrencyID, setSettlementCurrencyID] = useState('');
  const [paymentCurrencyID, setPaymentCurrencyID] = useState('');
  const [scaleSendAmount, setScaleSendAmount] = useState(2);
  const [validations, setValidations] = useState({
    accountToDebit: true,
    paymentAccount: true,
    sendInput: true,
    sendCurrency: true
  });
  const initialLoad = useRef(true);

  const validateFields = useCallback((model: CurrencyDetailsModel): Validation => {
    const accountToDebit = model.accountToDebit !== '';
    const paymentAccount = model.beneficiary !== '';
    const sendInput = model.sendAmount > 0;
    const sendCurrency = model.sendCurrency !== '';
    return {
      valid: accountToDebit && paymentAccount && sendInput && sendCurrency,
      validations: { accountToDebit, paymentAccount, sendInput, sendCurrency }
    };
  }, []);

  useEffect(() => {
    if (details != null && initialLoad.current) {
      setAccountToDebit(details.accountToDebit);
      setBeneficiaryPayTo(details.beneficiary);
      setSendInput(details.sendAmount);
      setSendCurrency(details.sendCurrency);
      initialLoad.current = false;
    }
  }, [details, validateFields]);

  useEffect(() => {
    const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
    const loadBankAccounts = async () => {
      const json = await getListOfCustomerBankAccounts('', customerID);
      if (json != null && json.BankAccounts != null) {
        const _bankAccounts = json.BankAccounts.map((ba: any) => ({
          ...ba,
          value: ba['BankAccount ID'].toString(),
          label: `${ba['Bank Name']} (${ba['Currency ID']})`
        }));
        setBankAccounts(_bankAccounts);
      }
    };
    loadBankAccounts();
  }, []);

  useEffect(() => {
    const loadBeneficiaries = async () => {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const json = await getOnlineBeneficiaries(customerID);
      if (json != null && json.Beneficiaries != null) {
        const _beneficiaries = json.Beneficiaries.filter((b: any) => b.BankAccounts != null);
        const _bankAccounts = _beneficiaries.flatMap((b: any) =>
          b.BankAccounts.map((ba: any) => ({
            ...ba,
            BeneficiaryID: b.BeneficiaryID,
            BeneficiaryName: b.BeneficiaryName,
            value: ba.BankAccountID.toString(),
            /*label: `${ba.BankName} (${ba.CurrencyID})`
            value: b.BeneficiaryID.toString(),*/
            label: `${b.BeneficiaryName} - (${ba.CurrencyID}) - (${ba.AccountNo})`
          }))
        );
        setBeneficiaries(_bankAccounts);
      }
    };
    loadBeneficiaries();
  }, []);

  useEffect(() => {
    if (allCurrencies != null && allCurrencies.length > 0) {
      if (settlementCurrencyID !== '' || paymentCurrencyID !== '') {
        // Set doesn't add element if is a duplicate
        let set = new Set<string>().add(settlementCurrencyID).add(paymentCurrencyID);
        if (set.has('')) {
          set.delete('');
        }
        let list: Currency[] = [];
        set.forEach((currencyID: string) => {
          const currency = (allCurrencies as Currency[]).find((c: Currency) => c.CurrencyID === currencyID);
          if (currency != null) {
            list.push(currency);
          }
        });
        setCurrencies(list as []);
      }
    }
  }, [settlementCurrencyID, paymentCurrencyID, allCurrencies]);

  const trySendNext = () => {
    const currencyDetails: CurrencyDetailsModel = {
      accountToDebit: accountToDebit,
      beneficiary: beneficiaryPayTo,
      sendAmount: sendInput,
      sendCurrency: sendCurrency,
      beneficiaryID: beneficiaryID,
      beneficiaryName: beneficiaryName,
      settlementCurrencyID: settlementCurrencyID,
      paymentCurrencyID: paymentCurrencyID
    };
    const { valid, validations } = validateFields(currencyDetails);
    setValidations(validations);
    if (valid) {
      onStep('finish', currencyDetails, true);
    } else {
      onStep('error', currencyDetails, false);
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
  };

  const onChangeAccountToDebit = (bankAccount: any) => {
    setAccountToDebit(bankAccount.value);
    setSettlementCurrencyID(bankAccount['Currency ID']);
    setValidations({ ...validations, accountToDebit: true });
  };

  const onChangeBeneficiary = (bankAccount: any) => {
    setBeneficiaryPayTo(bankAccount.value);
    setBeneficiaryID(bankAccount.BeneficiaryID);
    setBeneficiaryName(bankAccount.BeneficiaryName);
    setPaymentCurrencyID(bankAccount.CurrencyID);
    setValidations({ ...validations, paymentAccount: true });
  };

  const onChangeSendInput = (value: number) => {
    setSendInput(value);
    setValidations({ ...validations, sendInput: true });
  };

  const onChangeSendCurrency = (currency: any) => {
    setSendCurrency(currency.value);
    setScaleSendAmount(currency.NumberOfDecimals);
    setValidations({ ...validations, sendCurrency: true });
  };

  return (
    <React.Fragment>
      <div className="screen-container">
        <div className="row center-xs">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.CurrencyDetailsAccountToDebit') + '(*)'}</span>
                </div>
                <div className="row">
                  <Dropdown
                    className={formatValidInputClass(validations.accountToDebit)}
                    id="payment-account-to-debit"
                    // submit={!valid}
                    errorMessage={getResource('DataMsgRequired')}
                    name="payment-account-to-debit"
                    title={getResource('SelectItem')}
                    select={{ value: accountToDebit }}
                    list={bankAccounts}
                    onChange={onChangeAccountToDebit}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.CurrencyDetailsBeneficiaryPayto') + '(*)'}</span>
                </div>
                <div className="row">
                  <Dropdown
                    className={formatValidInputClass(validations.paymentAccount)}
                    id="payment-beneficiary"
                    name="payment-beneficiary"
                    title={getResource('SelectItem')}
                    list={beneficiaries}
                    select={{ value: beneficiaryPayTo }}
                    onChange={onChangeBeneficiary}
                    // submit={!valid}
                    errorMessage={getResource('DataMsgRequired')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-6">
                <div className="row">
                  <span className="input-label">{getResource('Label.CurrencyDetailsSend') + '(*)'}</span>
                </div>
                <div className="row">
                  <Input
                    id="payment-send-input"
                    key="payment-send-input"
                    name="payment-send-input"
                    className={formatValidInputClass(validations.sendInput)}
                    type="number"
                    value={sendInput}
                    onValueChange={(value: number) => onChangeSendInput(value)}
                    // submit={!valid}
                    errorMessage={getResource('DataMsgRequired')}
                    decimalScale={scaleSendAmount}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-6">
                <div className="row">
                  <span className="input-label">{getResource('Js_TH_Currency')}(*)</span>
                </div>
                <div className="row">
                  <Dropdown
                    id="payment-send-currency"
                    name="payment-send-curriency"
                    className={formatValidInputClass(validations.sendCurrency)}
                    title={getResource('SelectItem')}
                    select={{ value: sendCurrency }}
                    list={currencies}
                    onChange={onChangeSendCurrency}
                    // submit={!valid}
                    errorMessage={getResource('DataMsgRequired')}
                    disabled={currencies.length === 0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="padding-top-20" />
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-6 col-xs-6">
          <Button
            id="contacts-main-info-cancel"
            type="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            {getResource('Button.Cancel')}
          </Button>
        </div>
        <div className="col-md-5 col-xs-6">
          <Button id="contacts-main-info-next" type="primary" onClick={trySendNext}>
            {getResource('Button.Next')}
          </Button>
        </div>
        <div className="col-md-1"></div>
      </div>
    </React.Fragment>
  );
};

export default CurrencyDetails;
