import { Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FunctionComponent, useEffect, useState } from "react";
import useOnlineRights from "../../api/hooks/useOnlineRights";
import { usePrevious } from "../../api/hooks/usePrevious";
import { formatToCurrency } from "../../helpers/FormatHelper";
import { OnlineRightsNames } from "../../helpers/OnlineRightsHelper";
import { getResource } from "../../helpers/ResourcesHelper";
import ApprovalsScreen from "../approvals/ApprovalsScreen";
import BeneficiariesScreen from "../beneficiaries/BeneficiariesScreen";
import DashboardScreen from "../dashboard/DashboardScreen";
import InvoiceScreen from "../invoicing/InvoiceScreen";
import { Deal } from "../models/Deal";
import { OnlineOrder } from "../models/OnlineOrder";
import MyAccount from "../myAccount/MyAccount";
import MyBalances, { ResponseGetListOfFundsOnHold } from "../myAccount/MyBalances";
import QuickBooks from "../others/QuickBooks";
import Payments from '../payments/Payments';
import TransactionsScreen from "../payments/TransactionsScreen";
import RateWatchScreen from "../rates/ratewatches/RateWatchScreen";
import BrandBar from "./brandbar/BrandBar";
import Footer from "./footer/Footer";
import NavBar from './navbar/NavBar';

interface WorkSpaceProps {
  onLogout: Function;
  onUpdateSession: (newSession: any) => void;
  listPendingDisburseDeals: [],
  onlineRights: ''
}

const WorkSpace: FunctionComponent<WorkSpaceProps> = (props) => {
  const [currentScreen, setCurrentScreen] = useState('dashboard');
  const prevCurrentScreen = usePrevious(currentScreen);
  const [fromRateWatch, setFromRateWatch] = useState<OnlineOrder|null>(null);
  const [fromMyAccountBalance, setFromMyAccountBalance] = useState<ResponseGetListOfFundsOnHold | null>(null);
  const [showListPendingDisburseDeals, setShowListPendingDisburseDeals] = useState(false);

  let OL_BeneficiaryAccess = useOnlineRights(OnlineRightsNames.OL_BeneficiaryAccess);
  let OL_RatesSection = useOnlineRights(OnlineRightsNames.OL_RatesSection);
  let OL_RatesSpotAccess = useOnlineRights(OnlineRightsNames.OL_RatesSpotAccess);
  let OL_RatesForwardAccess = useOnlineRights(OnlineRightsNames.OL_RatesForwardAccess);
  let OL_SpotAccess = useOnlineRights(OnlineRightsNames.OL_SpotAccess);
  let OL_ForwardAccess = useOnlineRights(OnlineRightsNames.OL_ForwardAccess);
  let OL_RegularPaymentAccess = useOnlineRights(OnlineRightsNames.OL_RegularPaymentAccess);
  let OL_OrderAccess = useOnlineRights(OnlineRightsNames.OL_OrderAccess);
  let OL_TransactionsHistoryAccess = useOnlineRights(OnlineRightsNames.OL_TransactionsHistoryAccess);

  let view_Dashboardscreen = false;
  let view_Widget_Beneficiary = false;
  let view_Widget_Transactions = false;
  let view_Widget_Payments = false;
  let view_Widget_Rate = false;
  if (OL_RatesSection && (OL_RatesSpotAccess || OL_RatesForwardAccess)) { view_Widget_Rate = true; }
  if (OL_SpotAccess || OL_ForwardAccess || OL_RegularPaymentAccess || OL_OrderAccess) { view_Widget_Payments = true; }
  if (OL_TransactionsHistoryAccess) { view_Widget_Transactions = true; }
  if (OL_BeneficiaryAccess) { view_Widget_Beneficiary = true; }
  if (view_Widget_Beneficiary || view_Widget_Rate || view_Widget_Payments || view_Widget_Transactions) { view_Dashboardscreen = true; }

  useEffect(() => {
    if (props.listPendingDisburseDeals != null && props.listPendingDisburseDeals.length > 0) {
      setShowListPendingDisburseDeals(true);
    }
  }, [props.listPendingDisburseDeals]);

  useEffect(() => {
    if(prevCurrentScreen === 'payment' && currentScreen !== "payment")
    {
      setFromRateWatch(null);
      setFromMyAccountBalance(null);
    }
  }, [currentScreen]);

  const onChangeScreen = (screen: string) => {
      setCurrentScreen(screen);
  }

  const onClickProfile = () => {
      setCurrentScreen('profile');
  }

  const onUpdateSession = (newSession: any) => {
    props.onUpdateSession(newSession);
  };
  const onRateFromBoardClick = (objRateFromBoard: any) => {
    setFromRateWatch(objRateFromBoard);
    setCurrentScreen('payment');
  }
  const renderScreen = () => {
      switch (currentScreen) 
      {
        case 'dashboard':
          return view_Dashboardscreen && <DashboardScreen
            onRateFromBoardClick={onRateFromBoardClick}
            viewWidgetBeneficiary={view_Widget_Beneficiary}
            viewWidgetTransactions={view_Widget_Transactions}
            viewWidgetPayments={view_Widget_Payments}
            viewWidgetRate={view_Widget_Rate}
          />;
        case 'rates':
          return (
            <RateWatchScreen 
              key   = {'RateWatchScreen'} 
              onBuyNowClick = {
                (rateWatchSelected:OnlineOrder) => {
                  console.log("Buy Now: ", rateWatchSelected);
                    setFromRateWatch(rateWatchSelected);
                    onChangeScreen('payment');
                }
              }
          />
          );
        case 'payment':
          return (
            <Payments 
              fromRateWatchObj = {fromRateWatch}
              fromMyAccountBalance = {fromMyAccountBalance}
            />
          );
        case 'balance':
          return (
            <MyBalances
              convertBalance={(balanceObj: ResponseGetListOfFundsOnHold) => {
                setFromMyAccountBalance(balanceObj);
                onChangeScreen('payment');
              }}
            />
          );
        case 'transaction':
          return (
            <TransactionsScreen />
          );
        case 'beneficiaries':
          return <BeneficiariesScreen />;
        case 'quickbooks':
          return <QuickBooks />;
        case 'profile':
          return (
            <MyAccount 
              /* onBalanceClick = {
                (responseGetListOfFundsOnHold: ResponseGetListOfFundsOnHold) =>{
                  setFromMyAccountBalance(responseGetListOfFundsOnHold);
                  onChangeScreen('payment');
                }
              } */
            />
          );
        case 'approvals':
          return <ApprovalsScreen />;
        case 'invoicing':
          return <InvoiceScreen />;
        default:
          return <div></div>;
      }
  }

  const handleCancelAlert = () => {
    setShowListPendingDisburseDeals(false);
  }

  const columnsPendingDisburseDeals: ColumnsType<Deal> = [
    {
      title: 'Deal No',
      dataIndex: 'DealID',
      key: 'deal_dealid',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.DealID - b.DealID
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'deal_balance',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.Balance || 0, obj.SellCurrency || '')} {obj.SellCurrency || ''}
        </span>
      )
    }
  ]

  console.log(props.onlineRights)
  return (
    <React.Fragment>
      <BrandBar onChangeScreen={onChangeScreen} onClickProfile={onClickProfile} onUpdateSession={onUpdateSession} onLogout={() => { props.onLogout(); }} />
      <div className="row prime-container">
        <div className="col-lg-1 none-medium"></div>
        <div className="col-lg-2 col-md-2 col-xs-2">
          <NavBar
            onChangeScreen={onChangeScreen}
            currentScreen={currentScreen}
            onlineRights={props.onlineRights}
          />
        </div>
        <div className="col-lg-8 col-md-10 col-xs-10 workspace-container">{renderScreen()}</div>
        <div className="col-lg-1 none-medium"></div>
        <Footer />
      </div>

      <Modal
        title={<h3 className="component-title">{getResource('Title.Warning')}</h3>}
        centered
        visible={showListPendingDisburseDeals}
        footer={null}
        onCancel={handleCancelAlert}
        maskClosable={false}
        width={'25%'}
        zIndex={1000}
        closable={true}
      >
        <div className="row">
          {getResource('Message_PendingDisburseDeals')}
        </div>
        <div className="row center-md">
          <Table
            key={'pendingDisburseDeals-table'}
            columns={columnsPendingDisburseDeals}
            dataSource={props.listPendingDisburseDeals}
          />
        </div>
        <div className="row">
          {getResource('Message_PendingDisburseDeals_2')}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default WorkSpace;