import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { AutoComplete, Switch } from 'antd';
import moment, { Moment } from 'moment';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import useStates, { State } from '../../../../api/hooks/useStates';
import { GetAddress, getAddressByID, saveAddress } from '../../../../api/NetworkingAddresses';
import { getAllCountries, getCountryByName } from '../../../../api/NetworkingCountries';
import AppContext from '../../../../App.context';
import { formatValidInputClass } from '../../../../helpers/FormatHelper';
import { getResource } from '../../../../helpers/ResourcesHelper';
import Button from '../../../inputs/Button';
import DatePicker from '../../../inputs/DatePicker';
import Dropdown from '../../../inputs/Dropdown';
import Input from '../../../inputs/Input';
const dateFormat = 'YYYY/MM/DD';

interface AddressProps {
  addressID: string;
  handleBackLink: () => void;
}

const Address: FunctionComponent<AddressProps> = ({ addressID, handleBackLink: returnToGrid }) => {
  const { showModal } = useContext(AppContext);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');
  const states = useStates(country);
  const [addressType, setAddressType] = useState('Main Address')
  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [state, setState] = useState(0);
  const [otherState, setOtherState] = useState('');
  const [sinceWhen, setSinceWhen] = useState(moment(new Date(), dateFormat).format(dateFormat));
  const [updateToken, setUpdateToken] = useState('');
  const [addressReadOnlyProperties, setAddressReadonlyProperties] = useState<GetAddress>({
    Address2: '',
    AddressId: '',
    AddressType: 'Main Address',
    AddressValue: '',
    City: '',
    CountryId: '',
    CountryName: '',
    CreatedBy: JSON.parse(localStorage.getItem('UserSession')!).PersonID,
    CreatedOn: moment(new Date(), dateFormat).format(dateFormat),
    DefaultAddress: false,
    District: '',
    OtherState: '',
    POBox: '',
    SinceWhen: '',
    StateId: 0,
    StateName: '',
    Status: '',
    UpdateToken: '',
    UpdatedBy: JSON.parse(localStorage.getItem('UserSession')!).PersonID,
    UpdatedOn: moment(new Date(), dateFormat).format(dateFormat),
    ZipCode: ''
  });

  const [valid, setValid] = useState({
    address: true,
    city: true,
    zipCode: true,
    country: true,
    state: true,
    otherState: true,
    sinceWhen: true
  });


  useEffect(() => {
    if (addressID !== '0' && addressID !== '' && addressID !== undefined) {
      const loadAddress = async () => {
        const json = await getAddressByID(addressID);
        if (json != null) {
          setAddressType(json.AddressType);
          setAddress(json.AddressValue);
          setAddress2(json.Address2);
          setCity(json.City);
          setZipCode(json.ZipCode);
          if (json.CountryName != null) setCountry(json.CountryId + ' - ' + json.CountryName);
          setState(json.StateId);
          setOtherState(json.OtherState);
          setSinceWhen(json.SinceWhen);
          setUpdateToken(json.UpdateToken);
          setAddressReadonlyProperties(json);
        }
        const loadCountries = async () => {
          const json = await getAllCountries();
          if (json != null && json.countries != null) {
            console.log(json);
            const formattedCountries = json.countries.map((c: any) => ({ ...c, label: `${c.Country} - ${c['Country Name']}`, value: c.Country }));
            formattedCountries.push({ label: getResource('SelectCountry'), value: '' });
            setCountries(formattedCountries);
          } else {
            setCountries(json.CountryId);
          }
        }
        loadCountries();
      };

      loadAddress();
    }
  }, [addressID]);

  const datesAcctions = (date: (Moment | null), dateString: string, type: string) => {
    let auxValid = { ...valid };
    let objSetters: { [key: string]: any } = {
      ['sinceWhen']: () => {
        setSinceWhen(date === null ? '' : date.format('YYYY/MM/DD'));
        auxValid.sinceWhen = true;
      },
    }
    setValid(auxValid);
    return (objSetters[type]())
  }

  const getDate = (dateString: string) => {
    if (dateString === '') {
      return undefined;
    }
    const date = moment(dateString, dateFormat);
    return date && date.isValid() ? date : undefined;
  };

  const validateFields = () => {
    let valid = true;
    let valids: any = {
      address: true,
      city: true,
      zipCode: true,
      country: true,
      state: true,
      otherState: true,
      sinceWhen: true
    };

    if (address === undefined || address === null || address === '') { valid = false; valids.address = false; }
    if (city === undefined || city === null || city === '') { valid = false; valids.city = false; }
    if (zipCode === undefined || zipCode === null || zipCode === '') { valid = false; valids.zipCode = false; }
    if (country === undefined || country === null || country === '') { valid = false; valids.country = false; }
    if (states.length > 0) {
      if (state === undefined || state === null || state === 0) { valid = false; valids.state = false; }
    } else {
      if (otherState === undefined || otherState === null || otherState === '') { valid = false; valids.otherState = false; }
    }
    if (sinceWhen === undefined || sinceWhen === null || sinceWhen === '') { valid = false; valids.sinceWhen = false; }

    setValid(valids);
    return valid;
  }

  const handleSaveAddress = async () => {
    if (validateFields()) {
      const model = {
        PersonID: localStorage.getItem('CustomerPersonID')!,
        CreatedBy: addressReadOnlyProperties.CreatedBy,
        CreatedOn: addressReadOnlyProperties.CreatedOn,
        AddressID: addressID,
        AddressValue: address,
        CountryID: country,
        StateID: state,
        City: city,
        Address2: address2,
        ZipCode: zipCode,
        OtherState: otherState,
        SinceWhen: sinceWhen,
        UpdateToken: updateToken,
        AddressType: addressType,
        DefaultAddress: addressReadOnlyProperties.DefaultAddress,
        Status: addressReadOnlyProperties.Status,
        District: addressReadOnlyProperties.District,
        POBox: addressReadOnlyProperties.POBox,
        UpdatedBy: addressReadOnlyProperties.UpdatedBy,
        updateToken: addressReadOnlyProperties.UpdateToken
      };
      const json = await saveAddress(model);
      if (json != null) {
        if (json.httpStatusCode !== 200) {
          showModal(getResource('Js_MessageError_TitleError'), json.httpErrorMessage);
        } else {
          showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Message_AddressSuccessfullySaved'));
          handleBackLink();
        }
      }
    } else {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
  };

  const handleBackLink = () => {
    returnToGrid();
  };

  const inputsActions = (event: any, type: string) => {
    let auxValid = { ...valid };
    let objSetters: { [key: string]: any } = {
      ['address']: () => { setAddress(event.target.value); auxValid.address = true; },
      ['address2']: () => { setAddress2(event.target.value); },
      ['city']: () => { setCity(event.target.value); auxValid.city = true; },
      ['zipCode']: () => { setZipCode(event.target.value); auxValid.zipCode = true; },
      ['otherState']: () => { setOtherState(event.target.value); auxValid.otherState = true; },
    };
    setValid(auxValid);
    return (objSetters[type]());
  }

  const onChangeCountry = (item: any, option: any) => {
    if (option != null && !option.label.includes('Select')) {
      let auxValid = { ...valid };
      auxValid.country = true;
      auxValid.state = false;
      auxValid.otherState = false;
      setCountry(option.label);
      setState(0);
      setValid(auxValid);
    }
  }

  const onSearch = (value: any) => {
    setCountry(value);
    let newValue = value.split(' -');
    getCountryByName(newValue[0]).then(
      (json) => {
        if (json.countries != null) {
          const formattedCountries = json.countries.map((c: any) => ({ ...c, label: c.CountryIdName, value: c.CountryId }));
          //formattedCountries.push({ label: 'Select a Country...', value: '' });
          setCountries(formattedCountries);
          if (value === '') {
            let auxValid = { ...valid };
            auxValid.country = false;
            auxValid.state = false;
            auxValid.otherState = false;
            setValid(auxValid);
          }
        }
      }
    )
  }

  const onChangeState = (value: State) => {
    if (value.value.toString() !== '0') {
      let auxValid = { ...valid }
      auxValid.state = true;
      auxValid.otherState = true;
      setState(value['State ID']);
      setValid(auxValid);
    }
  };

  return (
    <div className="row center-xs">
      <div className="col-lg-12 col-md-12 col-xs-12" >
        <div className="row center-xs">
          <h3 className="component-title">{(addressID == '') ? getResource('NewAddress') : getResource('EditAddress')}</h3>
        </div>
      </div>
      <div className="row center-xs">
        <div className="row col-md-5 col-xs-12 center-xs middle-xs">
          <div className="col-md-6 col-xs-6">
            <span className="input-label end-xs">{getResource('MainAddress')}</span>
          </div>
          <div className="col-md-6 col-xs-6 start-lg start-sm start-xs">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={false}
              checked={addressType === 'Main Address'}
              onChange={(checked: boolean, event: MouseEvent) => { checked ? setAddressType('Main Address') : setAddressType('Administrative Offices') }}
            />
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.SinceWhen')} (*)</span>
          </div>
          <div className='row'>
            <DatePicker
              className={formatValidInputClass(valid.sinceWhen)}
              id="address-since-when"
              key="address-since-when"
              onChange={
                (date: Moment | null, dateString: string) => {
                  datesAcctions(date, dateString, 'sinceWhen');
                }
              }
              valueValidation={sinceWhen}
              value={getDate(sinceWhen)}
            />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.Address')} (*)</span>
          </div>
          <div className="row">
            <Input
              className={formatValidInputClass(valid.address)}
              id="address-address"
              value={address}
              onChange={(event: any) => inputsActions(event, 'address')}
              type="text"
            />
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.Address2')}</span>
          </div>
          <div className="row">
            <Input
              id="address-address2"
              value={address2}
              onChange={(event: any) => inputsActions(event, 'address2')}
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.City')} (*)</span>
          </div>
          <div className="row">
            <Input
              className={formatValidInputClass(valid.city)}
              id="address-city"
              value={city}
              onChange={(event: any) => inputsActions(event, 'city')}
              type="text"
            />
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.PostalZip')} (*)</span>
          </div>
          <div className="row">
            <Input
              className={formatValidInputClass(valid.zipCode)}
              id="address-zipcode"
              value={zipCode}
              onChange={(event: any) => inputsActions(event, 'zipCode')}
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.Country')} (*)</span>
          </div>
          <div className="row">
            {/* <Dropdown name="country" title="Select an Item..." select={{ value: country }} list={countries} onChange={onChangeCountry} /> */}
            <AutoComplete
              className={formatValidInputClass(valid.country)}
              allowClear
              options={countries}
              placeholder={getResource('SelectCountry')}
              notFoundContent={getResource('PlaceHolder.NoResults')}
              value={country}
              onSelect={onChangeCountry}
              onSearch={onSearch}
            />
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.ProvinceState')} (*)</span>
          </div>
          <div className="row">
            {states.length > 0 && <Dropdown
              className={formatValidInputClass(valid.state)}
              name="state"
              title={getResource('SelectState')}
              select={{ value: state }}
              list={states}
              onChange={onChangeState}
            />}
            {states.length === 0 && <Input
              className={formatValidInputClass(valid.otherState)}
              type="text"
              placeholder={getResource('EnterNameState')}
              disabled={false}
              value={otherState}
              onChange={(event: any) => inputsActions(event, 'otherState')}
            />}
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row center-xs">
            <Button key="go-back-address" type="secondary" onClick={handleBackLink}>
              Cancel
            </Button>
          </div>
        </div>
        <div className="col-md-5 col-xs-12">
          <div className="row center-xs">
            <Button
              key="save-address"
              onClick={() => {
                handleSaveAddress();
              }}
            >Save</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
