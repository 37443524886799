import moment from 'moment';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import useCurrencyPairs from '../../../api/hooks/useCurrencyPairs';
import useCustomerCurrencies from '../../../api/hooks/useCustomerCurrencies';
import useHolidays from '../../../api/hooks/useHolidays';
import { LookUpTableDetail } from '../../../api/hooks/useLookUpTables';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import { getListOfSettlementCurrenciesForPaymentOrders } from '../../../api/NetworkingCurrencies';
import { getValueDate, saveDealHeader, validateCustomerAndContactLimits } from '../../../api/NetworkingDeals';
import AppContext from '../../../App.context';
import { formatDate, formatToCurrency, formatValidInputClass, getTimehhmm, scaleByCurrency, validInputClass } from '../../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import DatePicker from '../../inputs/DatePicker';
import Dropdown from '../../inputs/Dropdown';
import Input from '../../inputs/Input';
import RadioButton from '../../inputs/RadioButton';
import { Deal } from '../../models/Deal';
import ConfirmationModal from '../../others/ConfirmationModal';
import QuoteBox from '../../rates/quoteBox/QuoteBox';
import SendApprovals from '../validates/SendApprovals';


interface ICustomerCurrencies {
  payment: any[];
  settlement: any[];
}

interface ForwardProps {
  onAcceptTrade: Function;
  dealObj: Deal;
  fromOtherScreen: string;
  onBack: Function;
  forwardTypes: LookUpTableDetail[];
  objOrderScreen?: {} | null;
}

const Forward: FunctionComponent<ForwardProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [forwardType, setForwardType] = useState('Closed');
  const [lockedSide, setLockedSide] = useState('sell');
  const [currencyPairs] = useCurrencyPairs();
  const [holidays] = useHolidays();
  const [customerCurrencies] = useCustomerCurrencies();
  const [settlementMethods, setSettlementMethods] = useState([{ label: getResource('SelectSettlementMethod'), value: '' }]);
  const [settlementMethod, setSettlementMethod] = useState({} as any);
  const [buyCurrency, setBuyCurrency] = useState(JSON.parse(localStorage.getItem('UserSession')!)['DefaultSellCurrencyID']);
  const [sellCurrency, setSellCurrency] = useState(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
  const [valueDate, setValueDate] = useState('');
  const [momentValueDate, setMomentValueDate] = useState(undefined);
  const [nearDate, setNearDate] = useState('');
  const [momentNearDate, setMomentNearDate] = useState(undefined);
  const [farDate, setFarDate] = useState('');
  const [momentFarDate, setMomentFarDate] = useState(undefined);
  const [momentStartDate, setMomentStartDate] = useState(undefined);
  const [momentEndDate, setMomentEndDate] = useState(undefined);
  const [buyAmount, setBuyAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [quoteAmount, setQuoteAmount] = useState(0.0);
  const [days, setDays] = useState(0);
  const [daysString, setDaysString] = useState('');
  const [deposit, setDeposit] = useState(0.0);
  const [validates, setValidates] = useState(true);

  const [dealID, setDealID] = useState(0);
  const [customerRateDeal, setCustomerRateDeal] = useState(0.0);
  const [showApprovalsPopUp, setShowApprovalsPopUp] = useState(false);
  const [custApprovals, setApprovals] = useState('');

  const contactID = localStorage.getItem('ContactID');
  const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];
  const defaultDepositPercent = JSON.parse(localStorage.getItem('UserSession')!)['DefaultDepositPercent'];
  const defaultSettlementInstrument = JSON.parse(localStorage.getItem('UserSession')!)['DefaultSettlementInstrument'];
  const onlineInterface = JSON.parse(localStorage.getItem('UserSession')!).OnlineInterface;

  // --- For Input Number Scale ----------------------------------------------------------------------
  const [sellScale, setSellScale] = useState(2);
  const [buyScale, setBuyScale] = useState(2);
  //rights
  let OL_ForwardAddNew = useOnlineRights(OnlineRightsNames.OL_ForwardAddNew);
  let OL_ForwardGetQuote = useOnlineRights(OnlineRightsNames.OL_ForwardGetQuote);

  //after contact limit validation
  const [objDealHeaderAfterValidation, setObjDealHeaderAfterValidation] = useState({});
  const [showConfirmModalValidation, setShowConfirmModalValidation] = useState<boolean>(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState('');

  const calculateEndDate = (startDate: string | Date, buyCurrencyCalculate: string, sellCurrencyCalculate: string) => {
    //let forwardDays = 30;
    let forwardDays = Number(localStorage.getItem('MaximumOpenForwardWindow'));
    if (Number(JSON.parse(localStorage.getItem('UserSession')!)['MaxOpenForwardDays']) > 0) {
      forwardDays = Number(JSON.parse(localStorage.getItem('UserSession')!)['MaxOpenForwardDays']);
    }

    let holidaysHelper = [];

    for (var i = 0; i < holidays.length; i++) {
      //@ts-ignore
      if (holidays[i].CurrencyID === 'ANY' || holidays[i].CurrencyID === buyCurrencyCalculate || holidays[i].CurrencyID === sellCurrencyCalculate) {
        //@ts-ignore
        holidaysHelper.push(holidays[i].Date);
      }
    }

    let endDate = new Date(startDate);
    let daysToAdd = forwardDays;
    while (daysToAdd > 0) {
      endDate.setDate(endDate.getDate() + 1);
      if (endDate.getDay() === 6 || endDate.getDay() === 0 || holidaysHelper.includes(formatDate(endDate))) {
        //Nothing at all
        daysToAdd--;
      } else {
        daysToAdd--;
      }
    }
    return checkEndDate(endDate, holidaysHelper);
  };

  const validateHoliday = (date: any) => {
    let holidaysHelper = [];

    for (var i = 0; i < holidays.length; i++) {
      //@ts-ignore
      if (holidays[i].CurrencyID === 'ANY' || holidays[i].CurrencyID === buyCurrency || holidays[i].CurrencyID === sellCurrency) {
        //@ts-ignore
        holidaysHelper.push(holidays[i].Date);
      }
    }

    return holidaysHelper.includes(formatDate(date));
  };
  //@ts-ignore
  const checkEndDate = (endDate: Date, holidaysHelper: any) => {
    if (endDate.getDay() === 6 || endDate.getDay() === 0 || holidaysHelper.includes(formatDate(endDate))) {
      endDate.setDate(endDate.getDate() + 1);
      return checkEndDate(endDate, holidaysHelper);
    } else {
      return endDate;
    }
  };

  useEffect(() => {
    getValueDateForCalendar();
  }, [buyCurrency, sellCurrency]);

  useEffect(() => {
    getValueDateForCalendar();
  }, []);

  useEffect(() => {
    if (props.objOrderScreen != null) {
      let obj = props.objOrderScreen as any;
      setBuyCurrency(obj.BuyCurrencyID);
      setSellCurrency(obj.SellCurrencyID);
      setBuyAmount(obj.BuyAmount);
      setSellAmount(obj.SellAmount);

      if (obj.OpenFor > 0) {
        setForwardType('Open');
        setDays(obj.OpenFor);
        setDaysString(obj.OpenFor.toString());
      }
    }
  }, [props.objOrderScreen]);

  const validateFields = () => {
    var fields = [validInputClass(buyAmount.toString()), validInputClass(sellAmount.toString())];
    fields.forEach((element) => {
      if (!element) {
        setValidates(true);
        return true;
      } else {
        setValidates(false);
        return true; //must change this
      }
    });
  };

  const disabledDateNearDate = (current: any) => {
    if (forwardType === 'Closed') {
      return current > momentFarDate! || current < momentStartDate! || current > momentEndDate! || moment(current).day() === 0 || moment(current).day() === 6 || validateHoliday(current);
    } else {
      return moment(current).day() === 0 || moment(current).day() === 6 || validateHoliday(current);
    }
  };

  const disabledDateFarDate = (current: any) => {
    return current < momentNearDate! || current < momentStartDate! || current > momentEndDate! || moment(current).day() === 0 || moment(current).day() === 6 || validateHoliday(current);
  };

  const getValueDateForCalendar = () => {
    if (sellCurrency !== '' && buyCurrency !== '') {
      let cutoffTime = localStorage.getItem('CutoffTime');

      if (cutoffTime === null) {
        cutoffTime = '12:00';
      }

      let currentDate = new Date();
      let startDate = new Date(localStorage.getItem('SystemDate')!);

      startDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0);

      if (getTimehhmm(startDate) > cutoffTime) {
        startDate.setDate(startDate.getDate() + 1);
      }

      let afexSession = JSON.parse(localStorage.getItem('UserSession')!);
      if (afexSession.OnlineInterface != null && afexSession.OnlineInterface.includes('AFEX - Pass Thru')) {
        getValueDate(customerID, sellCurrency + buyCurrency).then((valueDateResponse: any) => {
          if (valueDateResponse.httpStatusCode !== 200) {
          } else {
            if (valueDateResponse.error != undefined && valueDateResponse.error != '') {
            } else {
              if (valueDateResponse.valueDate != undefined) {
                let date = Date.parse(valueDateResponse.valueDate);
                console.log(date);
                setValueDate(formatDate(valueDateResponse.valueDate));
                setMomentValueDate(moment(date) as any);
                setMomentFarDate(moment(date) as any);

                //if (forwardType === 'Open') {
                setMomentNearDate(moment(date) as any);
                //}

                setFarDate(formatDate(valueDateResponse.valueDate));
                setNearDate(formatDate(valueDateResponse.valueDate));

                let endDate = calculateEndDate(formatDate(valueDateResponse.valueDate), sellCurrency, buyCurrency);

                setMomentStartDate(moment(date) as any);
                setMomentEndDate(moment(endDate) as any);
              }
            }
          }
        });
      } else {
        setMomentFarDate(moment(startDate) as any);
        //if (forwardType === 'Open') {
        setMomentNearDate(moment(startDate) as any);
        //}
        setMomentValueDate(moment(startDate) as any);
        setValueDate(formatDate(startDate));
        setFarDate(formatDate(startDate));
        setNearDate(formatDate(startDate));

        let endDate = calculateEndDate(startDate, sellCurrency, buyCurrency);

        setMomentStartDate(moment(startDate) as any);
        setMomentEndDate(moment(endDate) as any);
      }
    }
  };

  const onForwardTypeChange = (item: any, name: string) => {
    setForwardType(item.value);
    if (item.value === 'Closed') {
      setDays(0);
      setDaysString('');
      setNearDate(valueDate);
      setMomentNearDate(momentValueDate);
      setFarDate(valueDate);
      setMomentFarDate(momentValueDate);
    }

    if (item.value === 'Open') {
      setNearDate(valueDate);
      setMomentNearDate(momentValueDate);
      setMomentFarDate(undefined);
    }
  };

  const radioChangeHandler = (event: any) => {
    setLockedSide(event.target.value);
    if (event.target.value === 'buy') {
      setBuyAmount(0);
      setQuoteAmount(0.0);
    } else {
      setSellAmount(0);
      setQuoteAmount(0.0);
    }
  };

  const dropdownBuyChangeHandler = (item: any, name: string) => {
    setBuyCurrency(item.value);
    setBuyScale(item.NumberOfDecimals);
    getValueDateForCalendar();
    //const buyAmountAux = Number(formatToCurrency(buyAmount.toString(), undefined, item.NumberOfDecimals));
    setBuyAmount(buyAmount);
    setQuoteAmount(buyAmount);
  };

  const dropdownSellChangeHandler = (item: any, name: string) => {
    setSellCurrency(item.value);
    getListOfSettlementMethods(item.value);
    setSellScale(item.NumberOfDecimals);
    getValueDateForCalendar();
    //const sellAmountAux = Number(formatToCurrency(sellAmount.toString(), undefined, item.NumberOfDecimals));
    setSellAmount(sellAmount);
    setQuoteAmount(sellAmount);
    setDeposit((defaultDepositPercent / 100) * Number(sellAmount));
  };

  const onChangeBuyAmount = (value: number) => {
    if (lockedSide === 'sell') {
      setBuyAmount(value);
      setQuoteAmount(value);
    }
  };

  const onChangeSellAmount = (value: number) => {
    if (lockedSide === 'buy') {
      setSellAmount(value);
      setQuoteAmount(value);
      setDeposit((defaultDepositPercent / 100) * (value));
    }
  };

  const dropdownSettlementsChangeHandler = (item: any, name: string) => {
    setSettlementMethod(item);
    //setValidates(false)
    console.log(item);
  };

  const getListOfSettlementMethods = (currencyID: string) => {
    getListOfSettlementCurrenciesForPaymentOrders(customerID, currencyID).then((jsonResponse: any) => {
      if (jsonResponse.items) {
        const formattedSettlemnts = jsonResponse.items.map((c: any) => {
          const details = c.details.map((d: any) => {
            if (d.label === 'Balance') {
              return { ...d, description: formatToCurrency(d.description, sellCurrency) }
            }
            return d;
          });
          return ({ ...c, label: c.name, details: details });
        });
        formattedSettlemnts.push({ label: getResource('SelectSettlementMethod'), value: '' });
        setSettlementMethods(formattedSettlemnts);
        formattedSettlemnts.forEach((element: any) => {
          if (element.value.includes(defaultSettlementInstrument)) {
            setSettlementMethod(element);
          }
        });
      }
    });
  };

  const onChangeDays = (event: any) => {

    setDaysString(event.target.value);
    if (event.target.value !== '') {

      let localDays = Number(event.target.value)
      //@ts-ignore
      let currentMomentNearDate = momentNearDate.clone();
      //@ts-ignore
      let newFarDate = currentMomentNearDate.add(localDays, 'days');

      //Handle weekend and/or holidays
      let holidaysHelper = [];
      for (var i = 0; i < holidays.length; i++) {
        //@ts-ignore
        if (holidays[i].CurrencyID === 'ANY' || holidays[i].CurrencyID === sellCurrency || holidays[i].CurrencyID === buyCurrency) {
          //@ts-ignore
          holidaysHelper.push(holidays[i].Date);
        }
      }

      let additionalDays = 0;
      let newFarDateLocal = new Date(newFarDate)

      while (newFarDateLocal.getDay() === 6 ||
        newFarDateLocal.getDay() === 0 ||
        holidaysHelper.includes(formatDate(newFarDateLocal))) {
        newFarDateLocal.setDate(newFarDateLocal.getDate() + 1);
        additionalDays++;
      }

      newFarDate = currentMomentNearDate.add(additionalDays, 'days');

      setDays(localDays + additionalDays);
      //setDaysString((localDays + additionalDays).toString());
      setMomentFarDate(newFarDate);
      setFarDate(formatDate(newFarDate));
    } else {
      setDays(0);
    }

  }

  const onBlurDays = (event: any) => {
    setDaysString(days.toString());
  }

  const onChangeNearDate = (date: any, dateString: string) => {
    setNearDate(dateString);
    setMomentNearDate(date);
    let endDate = calculateEndDate(dateString, sellCurrency, buyCurrency);
    setMomentStartDate(date as any);
    setMomentEndDate(moment(endDate) as any);
  };

  const onChangeFarDate = (date: any, dateString: string) => {
    if (date !== null) {
    setFarDate(dateString);
    setMomentFarDate(date);
    if (forwardType === 'Closed') {
      setNearDate(dateString);
      setMomentNearDate(date);
    } else {
      setDays(date.diff(momentNearDate, 'days'));
      setDaysString(date.diff(momentNearDate, 'days').toString());
    }
    }
  };

  const amountCalc = (amount: number) => {
    console.log(amount);
    if (lockedSide === 'buy') {
      setBuyAmount(amount);
    } else {
      setSellAmount(amount);
    }
  };

  const onNewTradeClick = () => {
    setLockedSide('sell');
    //setSettlementMethods([{ label: 'Select Settlement Method...', value: '' }]);
    setBuyCurrency(JSON.parse(localStorage.getItem('UserSession')!)['DefaultSellCurrencyID']);
    setSellCurrency(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
    //getListOfSettlementMethods(JSON.parse(localStorage.getItem('UserSession')!)['DefaultBuyCurrencyID']);
    //setValueDate('');
    setBuyAmount(0);
    setSellAmount(0);
    setQuoteAmount(0.0);
    setNearDate('');
    setMomentNearDate(undefined);
    setFarDate('');
    setMomentFarDate(undefined);
    setDaysString('');
    //setSettlementMethod({ label: 'Select Settlement Method...', value: '' });
    //setMomentValueDate(undefined);
  };

  const onCancelQuoteClick = () => {
    if (lockedSide === 'buy') {
      setBuyAmount(0);
    } else {
      setSellAmount(0);
    }
  };

  const onAcceptClick = async (rateQuoteID: string, customerRate: number, marketRate: number, profit: number, coverCustomerID: number) => {
    let fixedLockedSide;
    if (lockedSide === 'buy') {
      fixedLockedSide = 'Buy';
    } else {
      fixedLockedSide = 'Sell';
    }
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    let objDealHeader = {
      BuyAmount: sellAmount.toFixed(scaleByCurrency(sellCurrency)),
      //BuyAmount: sellAmount,
      BuyCurrency: sellCurrency,
      CoverCustomerID: coverCustomerID,
      CreatedBy: localStorage.getItem('UserID'),
      CustomerContactID: localStorage.getItem('ContactID'),
      CustomerID: userSession.CustomerID,
      CustomerRate: customerRate,
      CustomerRefNo: '',
      ExternalInterface: userSession.OnlineInterface,
      ExternalInterfaceType: userSession.OnlineInterfaceType,
      IncomingFunds: [] as any,
      LockedSide: fixedLockedSide,
      MarketRate: marketRate,
      OutgoingFunds: [],
      ProfitAmount: profit,
      RateQuoteID: rateQuoteID,
      SellAmount: buyAmount.toFixed(scaleByCurrency(buyCurrency)),
      //SellAmount: buyAmount,
      SellCurrency: buyCurrency,
      Status: userSession.DealApproveRequired ? 'Pending for Approval' : 'Accepted',
      DealOrigin: 'Online Deal',
      DealType: 4,
      UpdateBuyAmountField: fixedLockedSide === 'Sell' ? true : false,
      UpdatedBy: localStorage.getItem('UserID'),
      ValueDate: nearDate,
      MaturityDate: farDate,
      ForwardType: forwardType,
      RequireDeposits: true,
      DepositPercent: defaultDepositPercent,
      DepositAmount: Number((sellAmount * defaultDepositPercent) / 100).toFixed(scaleByCurrency(sellCurrency)),
      //DepositAmount: ((sellAmount * defaultDepositPercent) / 100),
      DepositCurrency: sellCurrency,
      DealHeaderID: 0
    };

    if (defaultDepositPercent > 0) {
      let array = settlementMethod.value.split(';');
      let deliveryMethod = settlementMethod.details.filter((i: any) => i.label.includes('Delivery Method'));
      objDealHeader.IncomingFunds.push({
        Amount: Number((sellAmount * defaultDepositPercent) / 100).toFixed(scaleByCurrency(sellCurrency)),
        //Amount: ((sellAmount * defaultDepositPercent) / 100),
        BankAccountID: array[1],
        SettlementInstrument: array[2],
        SettlementDeliveryMethod: deliveryMethod && deliveryMethod.length > 0 ? deliveryMethod[0].translationlabel/*deliveryMethod[0].description*/ : ''
      });
    }
    setObjDealHeaderAfterValidation(objDealHeader);

    let responseLimit = await validateCustomerAndContactLimits(objDealHeader.DealType, buyAmount, buyCurrency, objDealHeader.CustomerID, Number(objDealHeader.CustomerContactID));
    let alertTitle = '';
    let alertMessage = '';

    if (responseLimit.httpStatusCode === 200) {
      if (responseLimit.ValidationMessage === '') {
        callSaveDealHeader(objDealHeader);
      }
      else {
        if (responseLimit.LimitValidationStatus.toLowerCase() === 'warning') {
          setConfirmationModalTitle(getResource('Title.Warning'));
          setConfirmationModalMessage(responseLimit.ValidationMessage);
          setShowConfirmModalValidation(true);
        }
        else {
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = responseLimit.ValidationMessage;
          showModal(alertTitle, alertMessage);
          hideLoadingModal();
        }
      }
    }
    else {
      alertTitle = getResource('Js_MessageError_TitleError');
      alertMessage = getResource('Js_Message_TryAgainOrContactAdministrator');
      showModal(alertTitle, alertMessage);
      hideLoadingModal();
    }

    /*
    saveDealHeader(objDealHeader).then((jsonDealResponse) => {
      let alertTitle = '',
        alertMessage = '';
      switch (Number(jsonDealResponse.httpStatusCode)) {
        case 200: {
          objDealHeader.DealHeaderID = jsonDealResponse.DealHeaderID;
          objDealHeader.Status = jsonDealResponse.Status;
          setDealID(jsonDealResponse.DealHeaderID);
          var approvals = jsonDealResponse.ApprovalsJson;

          alertTitle = getResource('Js_MessageError_TitleSuccess');
          alertMessage = getResource('Message_DealSaved').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

          switch (jsonDealResponse.Status) {
            case 'Accepted': {
              alertMessage = getResource('Message_OrderCommitted').replace('DealHeaderID', jsonDealResponse.DealHeaderID);
              if (jsonDealResponse.HeldFundBalance != null && jsonDealResponse.HeldFundBalance > -1) {
                alertMessage = alertMessage + ' ' + getResource('YourCurrentBalance') + ': ' + formatToCurrency(jsonDealResponse.HeldFundBalance, sellCurrency) + ' ' + sellCurrency;
                getListOfSettlementMethods(sellCurrency);
              }
              if (jsonDealResponse.LimitValidationMessage != undefined && jsonDealResponse.LimitValidationMessage != undefined) {
                alertMessage = alertMessage + " , " + jsonDealResponse.LimitValidationMessage;
              }
              props.onAcceptTrade(forwardType, jsonDealResponse.DealHeaderID);
              showModal(alertTitle, alertMessage);
              break;
            }
            case 'Pending for Approval': {
              if (approvals === undefined) {
                approvals = '';
              }
              if (approvals === '') {
                alertTitle = getResource('Js_MessageError_TitleError');
                alertMessage = getResource('Message_DealMissingApprovals');
                setShowApprovalsPopUp(false);
                props.onAcceptTrade(forwardType, jsonDealResponse.DealHeaderID);
                showModal(alertTitle, alertMessage);
              } else {
                setApprovals(approvals);
                setCustomerRateDeal(customerRate);
                setShowApprovalsPopUp(true);
              }
              break;
            }
            default: {
              alertMessage = getResource('Message_OrderSaved').replace('DealHeaderID', jsonDealResponse.DealHeaderID);
              props.onAcceptTrade(forwardType, jsonDealResponse.DealHeaderID);
              showModal(alertTitle, alertMessage);
              break;
            }
          }
          break;
        }
        default: {
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = getResource('Message_DealError');

          if (jsonDealResponse.ValidationMessage !== undefined) {
            //validation message from API
            alertTitle = getResource('Js_MessageError_TitleValidation');
            alertMessage = jsonDealResponse.ValidationMessage;
          } else {
            if (jsonDealResponse.httpErrorMessage !== undefined) {
              //message from networking based on status code
              alertMessage = jsonDealResponse.httpErrorMessage;
            }
          }
          if (jsonDealResponse.Message == 'SentToInterface') {
            alertMessage = getResource('Message_DealErrorSentInterface');
          }
          props.onAcceptTrade(forwardType, jsonDealResponse.DealHeaderID);
          showModal(alertTitle, alertMessage);
          break;
        }
      }
    });*/
  };

  const callSaveDealHeader = (objDealHeader: any) => {

    saveDealHeader(objDealHeader).then((jsonDealResponse) => {
      //alert(jsonDealResponse.stringify(json));
      hideLoadingModal();
      var alertTitle = '', alertMessage = '';
      switch (Number(jsonDealResponse.httpStatusCode)) {
        case 200:
          objDealHeader.DealHeaderID = jsonDealResponse.DealHeaderID;
          objDealHeader.Status = jsonDealResponse.Status;
          setDealID(jsonDealResponse.DealHeaderID);
          var approvals = jsonDealResponse.ApprovalsJson;
          //var showApprovalsPopUp = false;
          alertTitle = getResource('Js_MessageError_TitleSuccess');
          alertMessage = getResource('Message_DealSaved').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

          switch (jsonDealResponse.Status) {
            case 'Accepted':
              alertMessage = getResource('Message_OrderCommitted').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

              if (jsonDealResponse.HeldFundBalance != null && jsonDealResponse.HeldFundBalance > -1) {
                alertMessage = alertMessage + " " + getResource('YourCurrentBalance') + ": " + formatToCurrency(jsonDealResponse.HeldFundBalance.toString(), sellCurrency) + ' ' + sellCurrency;
                getListOfSettlementMethods(sellCurrency);
              }
              if (jsonDealResponse.LimitValidationMessage != undefined && jsonDealResponse.LimitValidationMessage != undefined) {
                alertMessage = alertMessage + " , " + jsonDealResponse.LimitValidationMessage;
              }
              //--this.sendDealEmail(jsonDealResponse.DealHeaderID);
              //this.sendConfirmationDirect(jsonDealResponse.DealHeaderID);
              props.onAcceptTrade(forwardType, jsonDealResponse.DealHeaderID);
              showModal(alertTitle, alertMessage);
              break;
            case 'Pending for Approval':
              if (approvals === undefined) {
                approvals = '';
              }
              if (approvals === '') {
                alertTitle = getResource('Js_MessageError_TitleError');
                alertMessage = getResource('Message_DealMissingApprovals');
                setShowApprovalsPopUp(false);
                props.onAcceptTrade(forwardType, jsonDealResponse.DealHeaderID);
                showModal(alertTitle, alertMessage);
              } else {
                setApprovals(approvals);
                setCustomerRateDeal(objDealHeader.CustomerRate);
                setShowApprovalsPopUp(true);
              }
              break;
            case 'Pending':
              alertMessage = getResource('Message_OrderSavedAsPending').replace('DealHeaderID', jsonDealResponse.DealHeaderID);

              let complianceRulesFound = jsonDealResponse != undefined &&
                ((jsonDealResponse.ComplianceSoftRules !== undefined && jsonDealResponse.ComplianceSoftRules !== null
                  && Array.isArray(jsonDealResponse.ComplianceSoftRules) && jsonDealResponse.ComplianceSoftRules.length > 0)
                  ||
                  (
                    jsonDealResponse.ComplianceRules !== undefined && jsonDealResponse.ComplianceRules !== null
                    && Array.isArray(jsonDealResponse.ComplianceRules) && jsonDealResponse.ComplianceRules.length > 0)
                )

              if (complianceRulesFound) {
                alertMessage = getResource('Message_DealCompliance');
              } else {
                alertMessage = alertMessage + jsonDealResponse.MessagePending;
              }

              showModal(alertTitle, alertMessage);
              onNewTradeClick()

              break;
            default:
              alertMessage = getResource('Message_OrderSaved').replace('DealHeaderID', jsonDealResponse.DealHeaderID);
              props.onAcceptTrade(forwardType, jsonDealResponse.DealHeaderID);
              showModal(alertTitle, alertMessage);
              break;
          }
          break;
        default:
          alertTitle = getResource('Js_MessageError_TitleError');
          alertMessage = getResource('Message_DealError');

          if (jsonDealResponse.ValidationMessage !== undefined) {
            //validation message from API
            alertTitle = getResource('Js_MessageError_TitleValidation');
            alertMessage = jsonDealResponse.ValidationMessage;
          }
          else {
            if (jsonDealResponse.httpErrorMessage !== undefined) {
              //message from networking based on status code
              alertMessage = jsonDealResponse.httpErrorMessage;
            }
          }
          if (jsonDealResponse.Message == 'SentToInterface') {
            alertMessage = getResource('Message_DealErrorSentInterface');
          }

          props.onAcceptTrade(forwardType, jsonDealResponse.DealHeaderID);
          showModal(alertTitle, alertMessage);
          break;
      }
    });
  }

  const closeSendEmailApproval = () => {
    setShowApprovalsPopUp(false);
    var alertTitle = '',
      alertMessage = '';

    alertTitle = getResource('Js_MessageError_TitleSuccess');
    alertMessage = getResource('Message_CreditApproval');
    showModal(alertTitle, alertMessage);
    props.onAcceptTrade(forwardType, dealID);
  };

  const yesLimitValidationAlert = () => {

    let objDealHeader = objDealHeaderAfterValidation;

    setShowConfirmModalValidation(false);
    callSaveDealHeader(objDealHeader);
  }

  const closeLimitValidationAlert = () => {
    hideLoadingModal();
    setShowConfirmModalValidation(false);
  }

  return (
    <React.Fragment>
      <div className="screen-container">
        <div className="row center-xs">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.ForwardType')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="forwardType"
                    title={getResource('SelectForwardType')}
                    list={props.forwardTypes.filter(x => !['Select a Type...'].includes(x.value))}
                    onChange={onForwardTypeChange}
                    select={{ value: forwardType }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.BuyCurrency')}</span>
                </div>
                <div className="row">
                  <Dropdown name="buyCurrency" title={getResource('SelectCurrencyBuy')} list={(customerCurrencies as ICustomerCurrencies).payment} onChange={dropdownBuyChangeHandler} select={{ value: buyCurrency }} />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row between-xs">
                  <div className="col-xs-4" style={{ padding: 0 }}>
                    <span className="input-label" style={{ justifyContent: 'flex-start' }}>
                      {getResource('Form.Label.BuyAmount')}
                    </span>
                  </div>
                  <div className="col-xs-4">
                    <RadioButton changed={radioChangeHandler} id="1" isSelected={lockedSide === 'sell'} label={getResource('Lock')} value="sell" />
                  </div>
                </div>
                <div className="row">
                  <Input
                    type="number"
                    className={validates ? '' : lockedSide === 'buy' ? '' : formatValidInputClass(validInputClass(buyAmount.toString()))}
                    value={buyAmount}
                    onValueChange={onChangeBuyAmount}
                    decimalScale={buyScale}
                    disabled={lockedSide === 'buy'}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.SellCurrency')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="sellCurrency"
                    title={getResource('SelectCurrencySell')}
                    list={(customerCurrencies as ICustomerCurrencies).settlement}
                    onChange={(item: any, name: string) => {
                      dropdownSellChangeHandler(item, name);
                    }}
                    select={{ value: sellCurrency }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row between-xs">
                  <div className="col-xs-4" style={{ padding: 0 }}>
                    <span className="input-label" style={{ justifyContent: 'flex-start' }}>
                      {getResource('Form.Label.SellAmount')}
                    </span>
                  </div>
                  <div className="col-xs-4">
                    <RadioButton changed={radioChangeHandler} id="2" isSelected={lockedSide === 'buy'} label={getResource('Lock')} value="buy" />
                  </div>
                </div>
                <div className="row">
                  <Input
                    type="number"
                    className={validates ? '' : lockedSide === 'sell' ? '' : formatValidInputClass(validInputClass(sellAmount.toString()))}
                    value={sellAmount}
                    onValueChange={onChangeSellAmount}
                    decimalScale={sellScale}
                    disabled={lockedSide === 'sell'}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.NearDate')}</span>
                </div>
                <div className="row">
                  <DatePicker
                    key="app-datepicker-neardate"
                    className={validates ? '' : formatValidInputClass(validInputClass(nearDate))}
                    disabled={forwardType === 'Closed'}
                    onChange={onChangeNearDate}
                    value={momentNearDate}
                    disabledDate={disabledDateNearDate}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.ForwardDays')}</span>
                </div>
                <div className="row">
                  <input type="number" disabled={forwardType === 'Closed' || momentNearDate == null} value={daysString} onChange={onChangeDays} onBlur={onBlurDays} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.FarDate')}</span>
                </div>
                <div className="row">
                  <DatePicker
                    key="app-datepicker-fardate"
                    className={validates ? '' : formatValidInputClass(validInputClass(farDate))}
                    onChange={onChangeFarDate}
                    value={momentFarDate}
                    disabledDate={disabledDateFarDate}
                  />
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.DepositRequired')} ({defaultDepositPercent}%)</span>
                </div>
                <div className="row">
                  <input type="number" disabled={true} value={deposit} />
                </div>
              </div>
              {defaultDepositPercent > 0 && (
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <span className="input-label">{getResource('Form.Label.SettlementMethod')}</span>
                  </div>
                  <div className="row">
                    <Dropdown
                      name="settlementMethod"
                      title={getResource('SelectSettlementMethod')}
                      className={validates ? formatValidInputClass(validInputClass(settlementMethod.value)) : ''}
                      settlementMode={true}
                      list={settlementMethods}
                      onChange={dropdownSettlementsChangeHandler}
                      //submit={true}
                      select={{ value: settlementMethod.value }}
                      disabled={sellCurrency === '' ? true : false}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row start-xs around-xs">
            <div className="col-md-4 col-xs-12 middle-xs">
              <Button
                type="secondary"
                onClick={() => {
                  props.onBack();
                }}
              >
                {getResource('Btn_Back')}
              </Button>
            </div>
            <div className="col-md-4 col-xs-12 middle-xs">
              <Button type="secondary" onClick={onNewTradeClick}>
                {getResource('NewTrade')}
              </Button>
            </div>
            <div className="col-md-4 col-xs-12 middle-xs">
              {OL_ForwardAddNew && OL_ForwardGetQuote && <QuoteBox
                mode="modal"
                settlementMethod={settlementMethod}
                dealType={4}
                buyCurrency={buyCurrency}
                sellCurrency={sellCurrency}
                customerID={Number.parseInt(customerID)}
                contactID={Number.parseInt(contactID!)}
                valueDate={farDate}
                forwardNearDate={nearDate}
                amount={quoteAmount}
                lockedSide={lockedSide}
                totalFeesAmount={0}
                amountCalc={amountCalc}
                onNewTradeClick={onNewTradeClick}
                onAcceptClick={onAcceptClick}
                onCancelQuote={onCancelQuoteClick}
                validateFields={validateFields}
                showDeposit={true}
                depositRate={defaultDepositPercent / 100}
              />}
            </div>
          </div>
        </div>
      </div>
      <div className="disclaimer-container">
        <div className="row center-xs">
          <div className="col-xs-12 row rate-disclaimer">
            <span>{getResource('AFEX_Message')}</span>
          </div>
          {(onlineInterface != null && onlineInterface.includes('AFEX - Pass Thru')) &&
            <span className="afex-disclaimer col-xs-12">
            {getResource('AFEX_Message_TC')}
          </span>
          }
        </div>
      </div>
      <SendApprovals show={showApprovalsPopUp} dealHeaderID={dealID} onClose={closeSendEmailApproval} approvals={custApprovals} />
      <ConfirmationModal visible={showConfirmModalValidation} cancel={closeLimitValidationAlert} confirm={yesLimitValidationAlert} mainTitle={confirmationModalTitle} title={confirmationModalMessage} />
    </React.Fragment>
  );
};

export default Forward;
