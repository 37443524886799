import { BankOutlined, CaretDownOutlined, CaretUpOutlined, CheckOutlined, DollarCircleOutlined, DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { Breadcrumb, Collapse, Modal, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { getOnlineBeneficiaries, getQuickBooksDataForExport, importQuickBooksEntities, uploadBeneficiaryTemplate } from '../../api/NetworkingBeneficiaries';
import AppContext from '../../App.context';
import DataGrid from '../../components/table/DataGrid';
import { downloadTemplateCSV } from "../../helpers/FormatHelper";
import { OnlineRightsNames } from "../../helpers/OnlineRightsHelper";
import { getResource } from '../../helpers/ResourcesHelper';
import ApprovalsHistoryByEntityForm from '../approvals/ApprovalsHistoryByEntityForm';
import BankAccountScreen from '../bankAccount/BankAccountScreen';
import Button from '../inputs/Button';
import UploadTransactionFileErrors from "../others/UploadTransactionFileErrors";
import PaymentDetail from "../payments/beneficiaryPayments/PaymentDetail";
import PaymentsTable from "../payments/beneficiaryPayments/PaymentsTable";
import Summary from "../payments/summary/Summary";
import BeneficiaryWizard from './BeneficiaryWizard';
import UploadModal from "./UploadModal";

const BeneficiariesScreen = (props: any) => {

  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const { Panel } = Collapse;

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiaryID, setBeneficiaryID] = useState(0);
  const [bankAccountID, setBankAccountID] = useState(0);
  const [showBeneficiaryInformartion, setShowBeneficiaryInformartion] = useState(false);
  const [showBankAccountInformartion, setShowBankAccountInformartion] = useState(false);
  const OL_BeneficiaryAddNew = useOnlineRights(OnlineRightsNames.OL_BeneficiaryAddNew);//OnlineRightsNames.OL_BeneficiaryAddNew);
  const OL_BeneficiaryEdit = useOnlineRights(OnlineRightsNames.OL_BeneficiaryEdit);
  const OL_BeneficiaryImport = useOnlineRights(OnlineRightsNames.OL_BeneficiaryImport);
  const userSession = JSON.parse(localStorage.getItem('UserSession')!);
  const [load, setLoad] = useState(false);
  const [showApprovalsHistory, setShowApprovalsHistory] = useState(false);

  const [updloadModalTitle, setUpdloadModalTitle] = useState<string>();
  const [fileUploaded, setFileUploaded] = useState<any>();
  const [fileUploadAccept, setFileUploadAccept] = useState<string>();
  const [showModalUpload, setShowModalUpload] = useState<boolean>(false);
  const [quickBookType, setQuickBookType] = useState<string>("");
  const [paymentClick, setPaymentClick] = useState(false);
  const [paymentPerBene, setPaymentPerBene] = useState(false);
  const [selectedPaymentID, setSelectedPaymentID] = useState(0);
  const [dealID, setSelectedDealID] = useState(0);
  const [beneficiaryImportTemplate, setBeneficiaryImportTemplate] = useState([]);
  const [showUploadTemplate, setShowUploadTemplate] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fileUploadedTemplate, setFileUploadedTemplate] = useState<any>();
  const [fileUploadedTemplateName, setFileUploadedTemplateName] = useState<string>("");
  const [showUploadErrors, setShowUploadErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const importColumns = 'FirstName,Name,BeneficiaryType,ContactName,ContactPhone,ContactEmail,PreferredMethodOfPayment,PreferredDeliveryMethod,DetailsOfPayment,Address,Country,City,State,AccountNumber,AccountCurrency,BankCountry,BankName,BankSwift,BankAba,TransitNo,BranchName,BranchAdress,BranchCity,BranchPostalCode,BranchState'

  const importBeneSample1 = 'Elmer,Huerta,Personal,Lapadula,987227272,test122@nexolink.com,Electronic Funds Transfer,Wire Transfer,Detail 1,Avenue 1212,USA,Miami,Florida,US1212211,USD,USA,HSBC,MRMDUS33XXX,123,343,Branch123,Avenue 23123,Miami,123 3,Florida'
  const importBeneSample2 = ',MINSA,Corporate,Dos Santos,9836363,bene122@nexolink.com,Electronic Funds Transfer,Wire Transfer,Detail 2,Avenue 1223,CAN,Ottawa,Ontario,US12847411,EUR,USA,HSBC,MRMDUS33XXX,345,2323,Branch456,Avenue 63636,New York,7327v,New York'
  const importBeneSample3 = ',RPP,Corporate,Markarian,983600363,bene1290@nexolink.com,Electronic Funds Transfer,Wire Transfer,Detail 3,Avenue 8745,PER,Lima,LIMA,PE12847411,PEN,PER,CLAE,CLPERU01,PE22,111,Barranco,Avenue 92223,Lima,1223,Lima'

  const onBeneficiaryClick = (beneficiaryID: number) => {
    setBeneficiaryID(beneficiaryID);
    setShowBeneficiaryInformartion(true);
  }

  const onBankAccountClick = (bankAccountID: number, beneficiaryID: number) => {
    setBeneficiaryID(beneficiaryID);
    setBankAccountID(bankAccountID);
    setShowBankAccountInformartion(true);
  };

  const loadBeneficiaries = () => {
    if (userSession != null && userSession.CustomerID > 0) {
      getOnlineBeneficiaries(userSession.CustomerID).then((response) => {
        setBeneficiaries(response.Beneficiaries);
        setLoad(true);
      });
    }
  }

  const onCancelClick = () => {
    loadBeneficiaries();
    setSelectedPaymentID(0);
    setSelectedDealID(0);
    setPaymentPerBene(false);
    setPaymentClick(false);
    setShowBeneficiaryInformartion(false);
    setShowBankAccountInformartion(false);
  }

  const onApprovalsHistoryClick = (BeneficiaryID: number) => {
    setBeneficiaryID(BeneficiaryID);
    setShowApprovalsHistory(true);
  };

  const onPaymentsClick = (BeneficiaryID: number) => {
    setBeneficiaryID(BeneficiaryID);
    setSelectedPaymentID(0);
    setSelectedDealID(0);
    setPaymentPerBene(true);
    setPaymentClick(true);
    setShowBeneficiaryInformartion(true);
  }
  const selectRow = (paymentID: number) => {
    setSelectedPaymentID(paymentID);
    setSelectedDealID(0);
    setPaymentPerBene(false);
    setPaymentClick(true);
  }
  const selectDealRow = (dealID: number) => {
    setSelectedPaymentID(0);
    setSelectedDealID(dealID);
    setPaymentPerBene(false);
    setPaymentClick(true);
  }
  const getActions = (BeneficiaryID: number) => {
    return (
      <div>
        <a onClick={() => {
          onPaymentsClick(BeneficiaryID);
        }}>
          <Tooltip title={getResource('Js_Action_Payments')} arrowPointAtCenter>
            <DollarCircleOutlined />
          </Tooltip>
        </a>
        {OL_BeneficiaryEdit && (
          <a
            onClick={() => {
              onBankAccountClick(0, BeneficiaryID);
            }}
          >
            <Tooltip title={getResource('Js_Action_AddBankAccount')} arrowPointAtCenter>
              <BankOutlined />
            </Tooltip>
          </a>
        )}
        {userSession.BeneApproveRequired && (
          <a
            onClick={() => {
              onApprovalsHistoryClick(BeneficiaryID);
            }}
          >
            <Tooltip title={getResource('Label_Approvals')} arrowPointAtCenter>
              <CheckOutlined />
            </Tooltip>
          </a>
        )}
        {OL_BeneficiaryEdit && <a onClick={() => { onBeneficiaryClick(BeneficiaryID) }}>
          <Tooltip title='Edit' arrowPointAtCenter>
            <EditOutlined />
          </Tooltip>
        </a>}
      </div>
    );
  };

  const columns = [
    {
      title: getResource('Js_TH_Beneficiary'),
      dataIndex: 'BeneficiaryName',
      key: 'BeneficiaryName',
      //@ts-ignore
      render: (text, record) => {
        var beneName = <a
          onClick={() => {
            onBeneficiaryClick(record.BeneficiaryID);
          }}
        >
          {text}
        </a>;
        if (!OL_BeneficiaryEdit) {
          beneName = text;
        }
        return beneName;
      }
    },
    { title: getResource('Js_TH_Country'), dataIndex: 'CountryName', key: 'CountryName' },
    { title: getResource('Js_TH_Status'), dataIndex: 'Status', key: 'Status' },
    {
      title: getResource('Js_TH_Action'),
      dataIndex: '',
      key: 'x',
      //@ts-ignore
      render: (record) => getActions(record.BeneficiaryID, record)
    }
  ];

  const getBeneImportSampleData = () => {

    let beneImportSampleData = [] as any;
    let columns = importColumns.split(',') || []
    let sample1Data = importBeneSample1.split(',') || []
    let sample2Data = importBeneSample2.split(',') || []
    let sample3Data = importBeneSample3.split(',') || []

    let sample1 = {} as any;
    sample1Data.forEach((x: any, i: number) => {
      sample1[columns[i]] = x
    })
    beneImportSampleData.push(sample1);

    let sample2 = {} as any;
    sample2Data.forEach((x: any, i: number) => {
      sample2[columns[i]] = x
    })
    beneImportSampleData.push(sample2);

    let sample3 = {} as any;
    sample3Data.forEach((x: any, i: number) => {
      sample3[columns[i]] = x
    })
    beneImportSampleData.push(sample3);

    setBeneficiaryImportTemplate(beneImportSampleData);
  };

  useEffect(() => {
    loadBeneficiaries();
    //template    
    getBeneImportSampleData();

  }, [load]);

  const expandedRow = (records: [], beneficiaryID: number) => {
    //@ts-ignore
    var expanded = [];
    if (records != undefined) {
      //@ts-ignore
      var expandedRows = [];
      records.forEach((b) => {
        expandedRows.push(
          <tr>
            <td>
              {
                //@ts-ignore
                b.BankName
              }
            </td>
            <td>
              {
                //@ts-ignore
                b.CountryName
              }
            </td>
            <td>
              {
                //@ts-ignore
                b.CurrencyID
              }
            </td>
            <td>
              {OL_BeneficiaryEdit && <a
                onClick={() => {
                  //@ts-ignore
                  onBankAccountClick(b.BankAccountID, beneficiaryID);
                }}
              >
                {
                  //@ts-ignore
                  b.AccountNo
                }
              </a>}
              {!OL_BeneficiaryEdit &&
                //@ts-ignore
                b.AccountNo
              }

            </td>
          </tr>
        );
      });
      //@ts-ignore
      expanded = (
        <div>
          <table className="table-details">
            {
              //@ts-ignore
              expandedRows
            }
          </table>
        </div>
      );
    }
    //@ts-ignore
    return expanded;
  };

  const onAddBeneficiaryClick = () => {
    setBeneficiaryID(0);
    setShowBeneficiaryInformartion(true);
  }

  const handleCloseModalApprovalsHistory = () => {
    setShowApprovalsHistory(false);
  };

  const downloadBlankTemplate = () => {
    downloadTemplateCSV(beneficiaryImportTemplate, 'BeneficiariesTemplate');
  }

  const uploadTemplate = () => {
    setShowUploadTemplate(true);
  }

  const beneficiariesList = () => {
    return (
      <div>
        {OL_BeneficiaryAddNew && (
          <div className="col-xs-10 col-md-10 row">
            <div className="col-md-6">
              <Button type="primary" onClick={() => onAddBeneficiaryClick()}>
                {getResource('Tab.HeaderAddNewBeneficiary')}
              </Button>
            </div>
          </div>
        )}
        {OL_BeneficiaryImport && (
          <div className="col-xs-10 col-md-10 row">
            <div className="col-md-6">
              <Button type="primary" onClick={downloadBlankTemplate}>
                {getResource('SubMenu_DownloadTemplateForBeneficiaries')}
              </Button>
            </div>
            <div className="col-md-6">
              <Button type="primary" onClick={uploadTemplate}>
                {getResource('UploadBeneficiariesTemplate')}
              </Button>
            </div>
          </div>
        )
        }
        <DataGrid
          className=""
          columns={columns}
          expandable={{
            //@ts-ignore
            expandedRowRender: (record) => <div class="ant-expandable"> {expandedRow(record.BankAccounts, record.BeneficiaryID)} </div>,

            //@ts-ignore
            rowExpandable: (record) => record.BankAccounts != null,
            //@ts-ignore
            expandIcon: ({ expanded, onExpand, record }) =>
              record.BankAccounts != null ? expanded ? <CaretUpOutlined onClick={(e) => onExpand(record, e)} /> : <CaretDownOutlined onClick={(e) => onExpand(record, e)} /> : <div></div>
          }}
          dataSource={beneficiaries}
          exportableProps={{
            fileName: 'Beneficiaries',
            btnProps: {
              type: "default",
              icon: <DownloadOutlined />,
            }
          }}
        />
      </div>
    );
  }

  const importFile = () => {

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    readFileAsBase64(fileUploaded).then(
      (data: any) => {
        //console.log(data)
        let fileContent = data.replace(/^data:.+;base64,/, '');

        importQuickBooksEntities(fileContent, userSession.PersonID, fileUploaded.name, userSession.CustomerID, quickBookType).then((response) => {
          if (response !== undefined && response !== null) {
            let responseStatus = response.httpStatusCode
            switch (responseStatus) {
              case 200:
                if (response.ErrorMessage !== '') {
                  let message = getResource('Js_Message_FileImportedSuccessfully');
                  showModal(getResource('Js_Message_SubmitSucessful'), message);
                } else {
                  showModal(getResource('Js_MessageError_TitleSubmit'), response.ErrorMessage);
                }
                break;
              case 409:
                showModal(getResource('Js_MessageError_TitleFormValidation'), response.httpErrorMessage);
                break;
              case (responseStatus >= 400 && responseStatus < 500):
                showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
                break;
              case (responseStatus >= 500):
                showModal(getResource('Js_MessageError_TitleSubmit'), 'Internal Server Error: ' + response.Message);
                break;
              default:
                showModal(getResource('Js_MessageError_TitleFormValidation'), 'Something went wrong');
                break;
            }
          }
          //}
        }).finally(() => {
          setShowModalUpload(false);
          setQuickBookType("");
          setFileUploadAccept("");
          setFileUploaded("");
        });
      }
    )
  }

  const readFileAsBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const onFileChangeHandler = (event: any) => {
    setFileUploaded(event.target.files[0])
  }

  const exportQuickBooksData = (qbType: string, extension: string) => {

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    getQuickBooksDataForExport(userSession.CustomerID, qbType).then((response) => {
      if (response !== undefined && response !== null) {
        console.log(response);


        let responseStatus = response.httpStatusCode
        switch (responseStatus) {
          case 200:
            let fileName = "quickbook_export_" + qbType + extension
            let csvContent = "data:text/plain;charset=utf-8,"
            let csvDataAsRows = response.data
            let header = ''
            let encodedUri = ''
            let link: HTMLElement

            encodedUri = encodeURI(csvContent + header + csvDataAsRows);
            link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", fileName);
            link.click();
            document.body.appendChild(link); // Required for FF
            break;
          case (responseStatus >= 400 && responseStatus < 500):
            showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
            break;
          case (responseStatus >= 500):
            showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + response.Message);
            break;
          default:
            showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
            break;
        }
      }
    }).finally(() => {
      setShowModalUpload(false);
      setQuickBookType("");
      setFileUploadAccept("");
      setFileUploaded("");
    });;


  }

  const onUploadFile = () => {
    showLoadingModal();

    readFileAsBase64(fileUploaded).then(
      (data: any) => {
        let fileContent = data.replace(/^data:.+;base64,/, '');

        var fileName = fileUploaded.name

        if (fileContent != null && fileName != '') {
          setFileUploadedTemplate(fileContent);
          setFileUploadedTemplateName(fileName);
          hideLoadingModal();
          setShowConfirmation(true);
          setShowUploadTemplate(false);
        }
      });
  }
  const closeAlert = () => {
    setShowConfirmation(false);
    setFileUploadedTemplate(undefined);
    setFileUploadedTemplateName("");
    setFileUploaded(undefined);
  }

  const yesAlert = () => {
    showLoadingModal();
    setShowConfirmation(false);

    let userID = localStorage.getItem('UserID');

    uploadBeneficiaryTemplate(userSession.CustomerID, fileUploadedTemplate, fileUploadedTemplateName).then((response) => {
      if (response !== undefined && response !== null) {
        let responseStatus = response.httpStatusCode
        //debugger;
        switch (responseStatus) {
          case 200:
            if (response.ErrorMessage !== '') {
              setShowUploadErrors(false);
              let message = getResource('Js_Message_FileImportedSuccessfully');
              showModal(getResource('Js_Message_SubmitSucessful'), message);
              loadBeneficiaries();
            } else {
              showModal(getResource('Js_MessageError_TitleSubmit'), response.ErrorMessage);
            }
            break;
          case 409:
            showModal(getResource('Js_MessageError_TitleFormValidation'), response.httpErrorMessage);
            break;
          case (responseStatus >= 400 && responseStatus < 500):
            showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
            break;
          case 503:
            debugger;
            //showModal(getResource('Js_MessageError_TitleSubmit'), 'Internal Server Error: ' + response.Message);
            setErrors(response.Errors);
            var temp = errors;
            setShowUploadErrors(true);
            break;
          default:
            showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
            break;
        }
      }
    }).finally(() => {
      hideLoadingModal();
    });
  }

  const onErrorsClose = (error: any, showErrors: boolean) => {
    setErrors(errors);
    setShowUploadErrors(showErrors);
    setFileUploadedTemplate(undefined);
    setFileUploadedTemplateName("");
    setFileUploaded(undefined);
  };

  return (
    <div>
      <UploadModal showModalUpload={showModalUpload} title={updloadModalTitle} fileUploaded={fileUploaded} accept={fileUploadAccept} setShowModalUpload={setShowModalUpload}
        onFileChangeHandler={onFileChangeHandler} importFile={importFile}>
      </UploadModal>

      <div className="breadcrumb-container">
        <Breadcrumb separator=">">
          <Breadcrumb.Item onClick={onCancelClick}>Beneficiaries</Breadcrumb.Item>
          {showBeneficiaryInformartion && beneficiaryID === 0 && !paymentClick && <Breadcrumb.Item>New Beneficiary</Breadcrumb.Item>}
          {showBeneficiaryInformartion && beneficiaryID !== 0 && !paymentClick && <Breadcrumb.Item>Beneficiary Information</Breadcrumb.Item>}
          {showBeneficiaryInformartion && paymentClick && <Breadcrumb.Item onClick={() => onPaymentsClick(beneficiaryID)}>{'Beneficiary ' + beneficiaryID}</Breadcrumb.Item>}
          {showBeneficiaryInformartion && selectedPaymentID !== 0 && <Breadcrumb.Item>{'Payment ' + selectedPaymentID}</Breadcrumb.Item>}
          {showBeneficiaryInformartion && dealID !== 0 && <Breadcrumb.Item>{'Deal ' + dealID}</Breadcrumb.Item>}
          {showBankAccountInformartion && <Breadcrumb.Item>Bank Account Information</Breadcrumb.Item>}
        </Breadcrumb>
      </div>
      {!showBeneficiaryInformartion && !showBankAccountInformartion && !paymentClick && beneficiariesList()}
      {showBeneficiaryInformartion && !paymentClick && (
        //<BeneficiaryInformation beneficiaryID={beneficiaryID} />

        <BeneficiaryWizard beneficiaryID={beneficiaryID} customerID={userSession.CustomerID} onCancelClick={onCancelClick} />
      )}
      {/*---------------ACTION PAYMENTS----------------- */}
      {paymentPerBene && paymentClick && <PaymentsTable beneficiaryID={beneficiaryID} selectRow={selectRow} selectDealRow={selectDealRow} />}
      {selectedPaymentID !== 0 && <PaymentDetail paymentID={selectedPaymentID} />}
      {dealID !== 0 && <Summary dealHeaderID={dealID} useConfirmationButton={true} />}
      {/*---------------ACTION PAYMENTS----------------- */}
      <ApprovalsHistoryByEntityForm entityID={beneficiaryID} entityType="Beneficiary" showApprovalsHistory={showApprovalsHistory} onClose={handleCloseModalApprovalsHistory} />
      {showBankAccountInformartion && <BankAccountScreen beneficiaryID={beneficiaryID} bankAccountID={bankAccountID} canEdit={OL_BeneficiaryAddNew || OL_BeneficiaryEdit} onCancelClick={onCancelClick} />}

      {/*-----UPLOAD BENEFICIARY TEMPLATE-------*/}
      <UploadModal showModalUpload={showUploadTemplate} title={'Upload File'} fileUploaded={fileUploaded} accept={'.csv'}
        setShowModalUpload={() => {
          setFileUploaded(undefined);
          setShowUploadTemplate(false);
        }}
        onFileChangeHandler={onFileChangeHandler} importFile={onUploadFile}>
      </UploadModal>
      {showConfirmation ?
        <Modal
          title={<h3 className="component-title">{'Confirm'}</h3>}
          centered
          visible={showConfirmation}
          footer={null}
          //onCancel={props.changeState()}
          maskClosable={false}
          width={'25%'}
          zIndex={1010}
          closable={false}
        >
          <div>
            <div>
              <h4>{getResource('AreYouSureYouWantToSave')}</h4>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="col">
                  <Button id="confirmation-afex-OK" type="primary" onClick={yesAlert}>
                    {'Yes'}
                  </Button>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="col">
                  <Button id="confirmation-afex-cancel" type="primary" onClick={closeAlert}>
                    {'No'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal> : null}

      {showUploadErrors ?
        <Modal
          title={<h3 className="component-title">{getResource('Js_MessageError_TitleError')}</h3>}
          centered
          visible={showUploadErrors}
          footer={null}
          //onCancel={props.changeState()}
          maskClosable={false}
          width={'30%'}
          zIndex={1010}
          closable={false}
        >
          <div>
            <UploadTransactionFileErrors errors={errors} onErrorsClose={onErrorsClose} likePopup={true} />
          </div>
        </Modal> : null}
    </div>
  );
}

export default BeneficiariesScreen;