import { DownloadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { getResource } from '../../../helpers/ResourcesHelper';
import DataGrid from '../../table/DataGrid';
import { getBankAccountsByPerson_min } from './../../../api/NetworkingBankAccount';
import BankAccountScreen from './../../bankAccount/BankAccountScreen';

const MyBankAccounts = () => {

  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankAccountID, setBankAccountID] = useState(0);
  const [showBankAccountScreen, setShowBankAccountScreen] = useState(false);
  
  useEffect(() => {    
    const personID = localStorage.getItem('CustomerPersonID')!;
    loadBankAccounts(personID);
  }
  , []);

  const loadBankAccounts=(personId:any)=>{
    getBankAccountsByPerson_min(personId).then(response => {      
      if (response != null && response != undefined && response.httpStatusCode == 200) {
        if (response.bankAccounts !== undefined) {
          let bankAccounts = response.bankAccounts;
          bankAccounts.forEach((element: any) => { element['Bank'] = element['Bank'] + (element['Branch'] !== null ? ' - ' + element['Branch'] : '') });
          setBankAccounts(bankAccounts);
        }
      }
    });
  }

  const onBeneficiaryClick = (bankAccountID: number) =>
    {
      setBankAccountID(bankAccountID);
      setShowBankAccountScreen(true);
    }

    const onCancelClick = () => 
    {
      setShowBankAccountScreen(false);
    }
    
  const columns = [
    {
      title: getResource('Table_TH_Currency'),
      dataIndex: 'Currency',
      key: 'currency',
      //@ts-ignore
      render: (text,record) => <a onClick={() => {onBeneficiaryClick(record['Account ID'])}}>{text}</a>
    },
    {
      title: getResource('Js_TH_BankAccountID'),
      dataIndex: 'Account ID',
      key: 'accountID'
    },
    {
      title: getResource('Js_TH_BankName'),
      dataIndex: 'Bank',
      key: 'bankName'
    },
    {
      title: getResource('Js_TH_AccountNo'),
      dataIndex: 'Account Number',
      key: 'accountNo'
    }
  ];

  const bankAccountTable = () =>
  {
    return (
      <div className="screen-container">
        <div className="screen-container">
          <div className="row center-xs">
            <DataGrid size="large" columns={columns} dataSource={bankAccounts}
              exportableProps={{
                fileName: 'MyBankAccounts',
                btnProps: {
                  type: "default",
                  icon: <DownloadOutlined />,
                }
              }} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className=''>
      {!showBankAccountScreen && bankAccountTable()}
      {showBankAccountScreen && (<BankAccountScreen bankAccountID={bankAccountID} onCancelClick={onCancelClick}/>)}
    </div>
  );
};

export default MyBankAccounts;
