import { DownloadOutlined, EditOutlined, MinusCircleOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { disableContact } from '../../../../api/NetworkingContacts';
import { Contact, getListOfCustomerContacts } from '../../../../api/NetworkingCustomers';
import { getResource } from '../../../../helpers/ResourcesHelper';
import Button from '../../../inputs/Button';
import DataGrid from '../../../table/DataGrid';
import AddEditContact from './AddEditContact';
import SummaryContact from './summary/SummaryContact';
interface Item extends Contact {
}

const Contacts = () => {
  const [updating, setUpdating] = useState<boolean>(true);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [editContact, setEditContact] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const [currentContactID, setCurrentContactID] = useState(0);
  const [currentContact, setCurrentContact] = useState<Contact|null>(null);
  const [hastMainContact, setHastMainContact] = useState<object>({'can': false, 'id':-1});
  const [viewContact, setViewContact] = useState(false);


  useEffect(() => {
    const loadContacts = async () => {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const json = await getListOfCustomerContacts(customerID);
      if (json != null && json.Contacts) {
        json.Contacts.forEach(contact => {
          if(contact.MainContact === 1)
          {
            setHastMainContact({'can': true, 'id': contact.ContactID});
          }
        });
        setContacts(json.Contacts);
      }
    };
    loadContacts();
  }, [updating]);

  const disabledContact = (record: Item) => {
    disableContact(record.ContactID).then(
      (json) => {
        if (json.httpStatusCode === 200) {
          if (json.result) {
            setUpdating(!updating);
          }
        }
      }
    )
  }
  const edit = (record: Item) => {
    setCurrentContactID(record.ContactID);
    setCurrentContact(record);
    setEditContact(true);
  }

  const view = (record: Item) => {
    setCurrentContactID(record.ContactID);
    setCurrentContact(record);
    setViewContact(true);
  }

  const columns = [
    {
      title: getResource('Label.FirstName'),
      dataIndex: 'FirstName',
      key: 'FirstName'
    },
    {
      title: getResource('Label.LastName'),
      dataIndex: 'LastName',
      key: 'LastName'
    },
    {
      title: getResource('Label.Email'),
      dataIndex: 'Email',
      key: 'Email'
    },
    {
      title: getResource('MainContact'),
      dataIndex: 'MainContact',
      key: 'MainContact',
      align: 'center',
      render: (_: any, record: Item) => (
        <>
          {(record.MainContact == 1) ? getResource('Yes') : getResource('No')}
        </>
      )
    },
    {
      title: getResource('Label.Status'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'Actions',
      align: 'center',
      width: '20%',
      fixed: 'right',
      render: (_: any, record: Item) => (
        <>
          <Tooltip title={getResource('Js.Link.Disabled')}>
            <Typography.Link
              onClick={() => {
                disabledContact(record);
              }}
            >
              <MinusCircleOutlined />
            </Typography.Link>
          </Tooltip>
          <Tooltip title={getResource('Js.Link.Edit')}>
          <Typography.Link 
            key={`contacts-${record.ContactID}-edit`}
            id={`contacts-${record.ContactID}-edit`}
            onClick = {
              () => edit(record)
            }
          >
            <EditOutlined style={{fontSize: 18}}/>
          </Typography.Link>
        </Tooltip>
          <Tooltip title={getResource('Button.View')}>
            <Typography.Link
              onClick={() => {
                view(record);
              }}
            >
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];
  
  return (
    <>
      {!editContact && !newContact && !viewContact && <div className="row center-xs">
        <div className="row center-md col-xs-12">
          <div className="col-md-4 col-xs-6 ">
            <Button 
              id="contacts-add-contact"
              type    = "secondary" 
              onClick = {() => { setNewContact(true); }}
            >{getResource('AddContact')} </Button>
          </div>
        </div>
        <div className='col-xs-10'>
          <DataGrid            
            columns={columns}
            dataSource={contacts}
            exportableProps={{
              fileName: 'Transactions',
              btnProps: {
                type: "default",
                icon: <DownloadOutlined />,
              }
            }}
          />
        </div>
      </div>}
      {newContact && <AddEditContact
        contactID       = {0}
        contactObj      = {null}
        onCancelAddEdit = {() => { 
          setEditContact(false); 
          setNewContact(false); 
          setUpdating(!updating);
        }}
        do                = {'add'}
        hasMainContact = {hastMainContact}
      />}
      {editContact && <AddEditContact
        contactID       = {currentContactID}
        contactObj      = {currentContact}
        onCancelAddEdit = {() => { 
          setEditContact(false); 
          setUpdating(!updating)
        }}
        do                = {'edit'}
        hasMainContact = {hastMainContact}
      />}
      {viewContact && <SummaryContact
        contactID={currentContactID}
        contactObj={currentContact}
        onClickCancel={() => setViewContact(false)}
      />}
    </>
  );
};

export default Contacts;