import React, { useState } from 'react';
import { getResource } from '../../../helpers/ResourcesHelper';
import EmailTemplateSettings from './emailTemplateSettings';
import MyDefaults from './myDefaults';

const MyDefaultsScreen = () => {
  const [showTab, setShowTab] = useState('currencies');
  const customerType = JSON.parse(localStorage.getItem('UserSession')!).CustomerType;

  const onClick = (tab: string) => {
    setShowTab(tab);
  };

  const tabSelected = (tab: string) => {
    return showTab === tab ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option';
  };

  return (
    <React.Fragment>
      <div className="navbar-horizontal">
        <div className="row">
          {<div id="my-currencies" onClick={() => onClick('currencies')} className={tabSelected('currencies')}>
            <span>{getResource('Details')}</span>
          </div>}
          {<div id="my-templates" onClick={() => onClick('templates')} className={tabSelected('templates')}>
            <span>{getResource('EmailTemplates')}</span>
          </div>}
        </div>
      </div>
      {showTab === 'currencies' && <MyDefaults />}
      {showTab === 'templates' && <EmailTemplateSettings />}
    </React.Fragment>
  );
};

export default MyDefaultsScreen;
