import { DeleteTwoTone, PaperClipOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { Divider } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import useCustomerCurrencies from '../../../../api/hooks/useCustomerCurrencies';
import useForceUpdate from '../../../../api/hooks/useForceUpdate';
import useInstruments from '../../../../api/hooks/useInstruments';
import useUserDefinedProperties from '../../../../api/hooks/useUserDefinedProperties';
import { getBeneficiaryForDisbursementsScreen } from '../../../../api/NetworkingBeneficiaries';
import { getListOfSettlementCurrenciesForPaymentOrders } from '../../../../api/NetworkingCurrencies';
import { getDealByID, getFullDealHeader, saveDealHeader } from '../../../../api/NetworkingDeals';
import { calculateWireItemFee } from '../../../../api/NetworkingFees';
import { saveOutgoingFunds } from '../../../../api/NetworkingFunds';
import AppContext from '../../../../App.context';
import { formatDate, formatToCurrency, formatValidInputClass, scaleByCurrency, validInputClass } from '../../../../helpers/FormatHelper';
import { getResource } from '../../../../helpers/ResourcesHelper';
import AttachmentsForm from '../../../attachments/AttachmentsForm';
import Button from '../../../inputs/Button';
import DatePicker from '../../../inputs/DatePicker';
import Dropdown from '../../../inputs/Dropdown';
import Input from '../../../inputs/Input';
import { ICustomerCurrencies } from '../../../orders/currencyDetails/CurrencyDetails';
import { Disbursement } from '../../../orders/ordersDisbursement/OrdersDisbursement';

interface DrawdownMultipleBeneficiariesProps {
  onFinish: Function;
  onExit: Function;
  dealHeaderID: number;
}

const DrawdownMultipleBeneficiaries: FunctionComponent<DrawdownMultipleBeneficiariesProps> = (props) => {
  const [addProperties] = useUserDefinedProperties('OutgoingFund - Online');
  const [customerCurrencies] = useCustomerCurrencies();
  const [instruments, instrumentsForNonAccounts] = useInstruments();
  const forceUpdate = useForceUpdate();
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [updating, setUpdating] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [showAttachmentForm, setShowAttachmentForm] = useState(false);
  const [currentAttachmentProp, setCurrentAttachmentProp] = useState<any>({ id: -1, description: '', currentDisbursementID: -1 });

  //really
  const [deal, setDeal] = useState<any>();
  const [fullDeal, setFullDeal] = useState<any>();
  const [disburses, setDisburses] = useState<Disbursement[]>([]);

  const [balance, setBalance] = useState(0.00);
  const [remainingBalance, setRemainingBalance] = useState(0.00);
  const [valueDate, setValueDate] = useState('')
  const [momentValueDate, setMomentValueDate] = useState(undefined);
  const [momentStartDate, setMomentStartDate] = useState(undefined);
  const [momentEndDate, setMomentEndDate] = useState(undefined);
  const [submit, setSubmit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [buyCurrencyItem, setBuyCurrencyItem] = useState<any>();

  const [showNotFullAmount, setShowNotFullAmount] = useState(false);

  const [validSend, setValidSend] = useState(true);
  const [validPay, setValidPay] = useState(true);

  // --- For Input Number Scale yanr
  const [sellScale, setSellScale] = useState(2);
  const [buyScale, setBuyScale] = useState(2);

  const [settlementMethods, setSettlementMethods] = useState([{ label: getResource('SelectSettlementMethod'), value: '' }]);
  const [settlementMethod, setSettlementMethod] = useState({} as any);
  const [validates, setValidates] = useState(true);
  const defaultSettlementInstrument = JSON.parse(localStorage.getItem('UserSession')!)['DefaultSettlementInstrument'];

  //Al iniciar -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];

    getDealByID(props.dealHeaderID).then((getDealByIDResponse: any) => {
      console.log();
      if (getDealByIDResponse.deals[0]['Deal Type'] === 4) {
        setDeal(getDealByIDResponse.deals[0]);
      }
    });

    getFullDealHeader(props.dealHeaderID).then((dealResponse: any) => {
      console.log("getFullDealHeader -> ", dealResponse);
      if (dealResponse !== undefined) {
        setFullDeal(dealResponse);
        getBeneficiaryForDisbursementsScreen(customerID, dealResponse.SellCurrency).then((jsonBene: any) => {
          if (jsonBene.beneficiaries) {
            const finalJsonBene = jsonBene.beneficiaries.filter((item: any) => item.BeneficiaryStatus === 'Authorized').map((c: any) => ({ ...c, label: c.Description, value: c.Value, BeneficiaryType: 0 }));
            setBeneficiaries(finalJsonBene);
          }
        });
        getListOfSettlementMethods();
      }
    });
  }, [updating]);
  //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (fullDeal !== undefined && addProperties.length > 0 && beneficiaries !== undefined && beneficiaries.length > 0) {
      //alert("se crea el primer row...");
      if (fullDeal.OutgoingFunds !== undefined && fullDeal.OutgoingFunds !== null) {
        if (Array.isArray(fullDeal.OutgoingFunds)) {
          /*
            0:
            BeneRefNumber: ""
            BeneficiaryBankAccountID: 1012386
            BeneficiaryID: 13711
            BeneficiaryType: 0
            Comments: null
            CountryName: "Peru"
            CreatedBy: "00000000-0000-0000-0000-000000000000"
            CreatedOn: "2021-08-21T02:50:17.167"
            DealHeaderID: 15092
            DeliveryMethod: ""
            DeliveryRefNo: null
            EntityID: 1012386
            EntityProperties:
              0:
                Attachments: null
                DateValue: null
                Description: "Copy of invoice and number"
                EntityPropertyID: "0A3894D1-E6DB-4035-8639-4844F7F5B7BE"
                NumValue: null
                TextValue: ""
                UserDefinedPropertyID: 3181
              1:
                Attachments: null
                DateValue: null
                Description: "Purpose of the transaction"
                EntityPropertyID: "5B1CCDBC-475C-437C-9182-2A089B5103CD"
                NumValue: null
                TextValue: ""
                UserDefinedPropertyID: 3167
            Name: "Final Company"
            NostroAccount: null
            OutgoingFundsID: 33559
            PaymentAmount: 100
            PaymentInstrument: "Electronic Funds Transfer"
            PaymentReference: ""
            SourceID: null
            SourceTable: null
            Status: "Pending"
            UpdateToken: "637651110171666667"
            UpdatedBy: "00000000-0000-0000-0000-000000000000"
            UpdatedOn: "2021-08-21T02:50:17.167"
            ValueDate: "2021-08-18T00:00:00"
            VersionNo: 1
            WireItemFee: null
            WireItemFeeCurrencyID: "AUD"
          */
          addARowToList(addProperties as any[], true, fullDeal.OutgoingFunds);
        }
      } else {
        addARowToList(addProperties as any[], true);
      }
      setBalance(fullDeal.SellAmount);
      setValueDate(fullDeal.ValueDate);
      setMomentValueDate(moment(Date.parse(fullDeal.ValueDate)) as any);
      getListOfSettlementMethods();
    }
  }, [addProperties, fullDeal, beneficiaries]);

  useEffect(() => {
    if (fullDeal !== undefined && customerCurrencies !== undefined && (customerCurrencies as ICustomerCurrencies).payment !== undefined) {
      debugger;
      let sellcurrencyItemAux = (customerCurrencies as ICustomerCurrencies).payment.find((currency: any) => currency.value === fullDeal.SellCurrency);
      let buycurrencyItemAux = (customerCurrencies as ICustomerCurrencies).settlement.find((currency: any) => currency.value === fullDeal.BuyCurrency); 
      if (buycurrencyItemAux != null) {
      setBuyCurrencyItem(buycurrencyItemAux);
      setBuyScale(buycurrencyItemAux.NumberOfDecimals);
      }
      if (sellcurrencyItemAux != null) {
      setSellScale(sellcurrencyItemAux.NumberOfDecimals);
      }
    }
  }, [customerCurrencies, fullDeal]);

  const getListOfSettlementMethods = () => {
    var currencyID = (fullDeal != null ? fullDeal.SellCurrency : '');
    const customerID = JSON.parse(localStorage.getItem('UserSession')!)['CustomerID'];

    getListOfSettlementCurrenciesForPaymentOrders(customerID, currencyID).then((jsonResponse: any) => {
      if (jsonResponse.items) {
        const formattedSettlemnts = jsonResponse.items.map((c: any) => {
          const details = c.details.map((d: any) => {
            if (d.label === 'Balance') {
              return { ...d, description: formatToCurrency(d.description, currencyID) }
            }
            return d;
          });
          return ({ ...c, label: c.name, details: details });
        });
        formattedSettlemnts.push({ label: getResource('SelectSettlementMethod'), value: '' });
        setSettlementMethods(formattedSettlemnts);
        formattedSettlemnts.forEach((element: any) => {
          if (element.value.includes(defaultSettlementInstrument)) {
            setSettlementMethod(element);
          }
        });

      }
    });
  };

  const deleteADisbursementRow = (idRow: number) => {
    const auxDeleteDisbursement = [...disburses];
    let tempAmount = 0;
    disburses.forEach((disbursement, index) => {
      if (disbursement.key === idRow) {
        auxDeleteDisbursement.splice(index, 1);
        tempAmount = disbursement.amount;
      }
    });
    setDisburses(auxDeleteDisbursement);
  }

  const validateEntities = (disbursement: Disbursement) => {
    let valid = true;
    disbursement.entityProperties.forEach(entity => {
      /*
        AmountLimit: 200
        Attachments: []
        AttachmentsRequired: false
        DataType: "Text"
        DateValue: null
        Description: "Copy of invoice and number"
        EntityPropertyID: null
        IsValid: true
        LookupTableAttachmentsRequired: false
        Mandatory: true
        NumValue: null
        TextValue: ""
        UserDefinedPropertyID: 3181
      */
      if (entity.Mandatory === true) {
        switch (entity.DataType) {
          case 'Text': {
            if (entity.TextValue === undefined || entity.TextValue === null || entity.TextValue === '') {
              valid = false;
            }
            break;
          }
          case 'Numeric': {
            if (entity.NumValue === undefined || entity.NumValue === null || entity.NumValue === '' || entity.NumValue === 0) {
              valid = false;
            }
            break;
          }
          case 'DateTime': {
            if (entity.DateValue === undefined || entity.DateValue === null || entity.DateValue === '') {
              valid = false;
            }
            break;
          }
        }
      }
      else {
        if (entity.AmountLimit !== undefined && entity.AmountLimit !== null && entity.AmountLimit !== 0) {
          if (disbursement.amount * buyCurrencyItem.BaseCurrencyDirectRate > entity.AmountLimit) {
            if (entity.ExtMandatory === true) {
              switch (entity.DataType) {
                case 'Text': {
                  if (entity.TextValue === undefined || entity.TextValue === null || entity.TextValue === '') {
                    valid = false;
                  }
                  break;
                }
                case 'Numeric': {
                  if (entity.NumValue === undefined || entity.NumValue === null || entity.NumValue === '' || entity.NumValue === 0) {
                    valid = false;
                  }
                  break;
                }
                case 'DateTime': {
                  if (entity.DateValue === undefined || entity.DateValue === null || entity.DateValue === '') {
                    valid = false;
                  }
                  break;
                }
              }
            }
            if (entity.AttachmentsRequired) {
              if (entity.Attachments.length === 0) {
                valid = false;
              }
            }
          }
        }
      }
    });
    return valid;
  }

  const valdiatePrevRows = (equalsToCero: boolean, fromQuote: boolean): [boolean, number[]] => {
    let valid = true;
    let lines: number[] = [];

    disburses.forEach((disbursement, index) => {
      let validEntitites = (fromQuote ? validateEntities(disbursement) : true);
      if (disbursement.beneficiary.value === '' ||
        disbursement.instrument === '' ||
        disbursement.amount === 0 || isNaN(disbursement.amount) ||
        !validEntitites) {
        valid = false;
        lines.push(index + 1);
      }
      if (fromQuote) {
        disbursement.showProperties = !validEntitites;
      }
    });

    let undisbursedBalanceTotalAux: number = 0.00;

    disburses.forEach((dis: Disbursement) => {
      undisbursedBalanceTotalAux = undisbursedBalanceTotalAux + (isNaN(dis.amount) ? 0 : dis.amount);
    });

    if (equalsToCero) {
      if (disburses.length > 0 && balance - undisbursedBalanceTotalAux < 0) {
        valid = false;
        lines.push(-5);
      }
    } else {
      if (disburses.length > 0 && balance - undisbursedBalanceTotalAux < 0) {
        valid = false;
        lines.push(-5);
      } else {
        if (disburses.length > 0 && balance - undisbursedBalanceTotalAux == 0) {
          valid = false;
          lines.push(-6);
        }
      }
    }

    const isOpen = deal !== undefined && deal['Forward Type'] === 'Open';

    if (!valid) {
      setSubmit(true);
      if (lines.find((line) => line === -6)) {
        if (lines.length > 1) {
          let finalLines = lines.filter(function (line) {
            return line !== -6;
          });
          showModal(getResource('Title.Warning'), getResource('OnLines') + ' ' + finalLines.join() + '. ' + getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
        } else {
          showModal(getResource('Js_MessageError_TitleError'), (isOpen ? getResource('Message_DrawdownBalance') : getResource('Message_DisbursementBalance')) + '.');
        }
      } else {
        if (lines.find((line) => line === -5)) {
          showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DrawdownAmountExceedBalance').replace('<amount>', balance));
        } else {
          showModal(getResource('Title.Warning'), getResource('OnLines') + ' ' + lines.join() + '. ' + getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
        }
      }
    }
    return [valid, lines];
  }

  const addARowToList = async (properties: any[], _firstTime: boolean, outgoings?: any[]) => {
    let [isvalid, lines] = valdiatePrevRows(false, false);
    if (isvalid) {
      let uniqueID = disburses.length > 0 ? (disburses[disburses.length - 1].key + 1) : 1;
      //entityProperties --------------------------------------------------------------------
      const listOfPropertiesByDisbursement: any[] = [];
      properties.forEach((propertiesHelper, _index) => {
        let propHelper = {
          Attachments: [],
          AttachmentsRequired: propertiesHelper['ExtAttachmentsRequired$'],
          AmountLimit: propertiesHelper['ExtAmountLimit$'],
          DataType: propertiesHelper['Data Type'],
          DateValue: null,
          Description: propertiesHelper['Description'],
          EntityPropertyID: null,
          LookupTableAttachmentsRequired: false,
          Mandatory: propertiesHelper['Mandatory'],
          ExtMandatory: propertiesHelper['ExtMandatory$'],
          NumValue: null,
          TextValue: '',
          UserDefinedPropertyID: propertiesHelper['ID'],
          IsValid: true,
        }
        listOfPropertiesByDisbursement.push(propHelper);
      });
      // ------------------------------------------------------------------------------------
      if (outgoings !== undefined) {
        if (outgoings.length > 0) {
          const lista = [...disburses];
          outgoings.forEach((outgoing: any, index: number) => {
            let newDisbursment: Disbursement;

            let newAmount: number = 0;
            if (fullDeal.RateTerms === 'I') {
              if (fullDeal.LockedSide === 'Sell') {
                newAmount = outgoing.PaymentAmount / fullDeal.CustomerRate;
              } else {
                newAmount = outgoing.PaymentAmount * fullDeal.CustomerRate;
              }
            } else {
              if (fullDeal.LockedSide === 'Sell') {
                newAmount = outgoing.PaymentAmount * fullDeal.CustomerRate;
              } else {
                newAmount = outgoing.PaymentAmount / fullDeal.CustomerRate;
              }
            }

            let getBene: any;
            ([...beneficiaries] as any[]).forEach(bene => {
              if (bene.BeneficiaryID === outgoing.BeneficiaryID && bene.BankAccountID === outgoing.BeneficiaryBankAccountID) {
                getBene = bene;
              }
            });

            const responseSaveDealHeader: any = calculateWireItemFee(
              JSON.parse(localStorage.getItem('UserSession')!).CustomerID,
              fullDeal.BuyCurrency,
              fullDeal.SellCurrency,
              outgoing.PaymentAmount,
              fullDeal.CustomerRate,
              outgoing.PaymentInstrument,
              ''
            );
            if (getBene != null) {
              newDisbursment = {
                id: outgoing.OutgoingFundsID,
                key: index + 1,
                amount: outgoing.PaymentAmount,
                otherAmount: newAmount,
                beneficiary: getBene,
                instrument: outgoing.PaymentInstrument,
                reference: outgoing.PaymentReference,
                fee: responseSaveDealHeader.FeeAmount,
                entityProperties: listOfPropertiesByDisbursement,
                showProperties: false,
                token: outgoing.UpdateToken
              };
            } else {
              newDisbursment = {
                key: uniqueID,
                amount: NaN,
                otherAmount: NaN,
                beneficiary: { BeneficiaryID: 0, value: '' },
                instrument: '',
                reference: '',
                fee: 0,
                entityProperties: listOfPropertiesByDisbursement,
                showProperties: false
              }
            }
            lista.push(newDisbursment);
          });
          setDisburses(lista);
          setSubmit(false);
          forceUpdate();
        }
      } else {
        let newDisbursment: Disbursement;
        newDisbursment = {
          key: uniqueID,
          amount: NaN,
          otherAmount: NaN,
          beneficiary: { BeneficiaryID: 0, value: '' },
          instrument: '',
          reference: '',
          fee: 0,
          entityProperties: listOfPropertiesByDisbursement,
          showProperties: false
        }

        const auxDisburses2 = [...disburses];
        auxDisburses2.push(newDisbursment);
        setDisburses(auxDisburses2);
        setSubmit(false);
      }
    }
  }

  const onUpdateOrderDisbursement = async (id: number, name: string, value: any, otherValue?: any) => {
    const listauxDis = [...disburses];
    let totalAmount = 0;
    listauxDis.forEach(async (dis, _index) => {
      if (dis.key === id) {
        switch (name) {
          case 'Amount': {
            // -----------------------------------------------------------------------------------------------------------------------
            let paymentAmount = value;
            /*let equivalent = (paymentAmount * fullDeal.SellAmount / fullDeal.BuyAmount);
            dis.amount = paymentAmount;
            dis.otherAmount = equivalent;*/

            let newAmount: number = 0;
            if (fullDeal.RateTerms === 'I') {
              newAmount = paymentAmount / fullDeal.CustomerRate;
            } else {
              newAmount = paymentAmount * fullDeal.CustomerRate;
            }

            dis.amount = paymentAmount;
            dis.otherAmount = newAmount;

            const response = await calculateWireItemFee(
              JSON.parse(localStorage.getItem('UserSession')!).CustomerID,
              fullDeal.BuyCurrency, fullDeal.SellCurrency, paymentAmount,
              fullDeal.CustomerRate, dis.instrument, '');

            dis.fee = response.FeeAmount;

              // -----------------------------------------------------------------------------------------------------------------------
            break;
          }
          case 'OtherAmount': {
            // -----------------------------------------------------------------------------------------------------------------------
            let equivalent = value;
            /*let paymentAmount = (equivalent * fullDeal.BuyAmount / fullDeal.SellAmount);

            dis.amount = paymentAmount;
            dis.otherAmount = equivalent;*/

            let newAmount: number = 0;
            if (fullDeal.RateTerms === 'I') {
              newAmount = equivalent * fullDeal.CustomerRate;
            } else {
              newAmount = equivalent / fullDeal.CustomerRate;
            }

            dis.amount = newAmount;
            dis.otherAmount = equivalent;

            const response = await calculateWireItemFee(
              JSON.parse(localStorage.getItem('UserSession')!).CustomerID,
              fullDeal.BuyCurrency, fullDeal.SellCurrency, newAmount,
              fullDeal.CustomerRate, dis.instrument, '');

            dis.fee = response.FeeAmount;

            // -----------------------------------------------------------------------------------------------------------------------
            break;
          }
          case 'Beneficiary': {
            dis.beneficiary = value;
            if (dis.beneficiary.BankAccountID > 0) {
              let auxInstruments = instruments as any[];
              let values = auxInstruments.filter(i => i.value.includes('Electronic Funds Transfer'));

              if (values.length > 0) {
                dis.instrument = values[0].InstrumentTranslationLabel//Instrument
              }

              //console.log('Instruments Electronic -> ', values);
              /*
                AccountsOnly: "1"
                CustomerInstrumentId: 2360
                DeliveryMethods: "ACH§Priority§Regular§Wire Transfer§Low Value§High Value"
                Instrument: "Electronic Funds Transfer"
                InstrumentType: "Outgoing"
                label: "Electronic Funds Transfer"
                value: "Electronic Funds Transfer"
              */
            }
            forceUpdate();
            break;
          }
          case 'Instrument': {
            dis.instrument = value;
            break;
          }
          case 'Reference': {
            dis.reference = value;
            break;
          }
          case 'EntityProperties': {
            dis.entityProperties = otherValue;
            break;
          }
        }
      }
      totalAmount = totalAmount + dis.amount;
    });
    setDisburses(listauxDis);
  }

  const saveDealClicked = () => {
    const isOpen = deal !== undefined && deal['Forward Type'] === 'Open';
    if (isOpen && !settlementMethod.value) {
      setValidates(false);
    }
    {
      let [isvalid, lines] = valdiatePrevRows(true, true);
      if (isvalid) {
        setSaving(true);
        let undisbursedBalanceTotalAux: number = 0.00;
        debugger;
        disburses.forEach((dis: Disbursement) => {
          undisbursedBalanceTotalAux = undisbursedBalanceTotalAux + (isNaN(dis.amount) ? 0 : dis.amount);
        });

        if ((balance - (deal !== undefined ? deal.OutgoingsAmount : 0) - undisbursedBalanceTotalAux) === 0) {
          saveOutgoing();
        } else {
          setShowNotFullAmount(true);
        }
      }
    }
  }

  const calculateFees = (_actualCustomerRate: number, _rateTerms: string, _customerRate: number) => {
    const newDisburses = [...disburses];
  }

  //cuando se de al onchange de cada property //////////////////////////////////////////////////////////////////////////////////
  const dropdownPropertyChange = (item: any, _name: string, index: number, disId: number) => {
    const auxDisburses = [...disburses];
    auxDisburses.find((dis) => dis.key === disId)!.entityProperties[index].TextValue = item.value;
    onUpdateOrderDisbursement(disId, 'EntityProperties', '', auxDisburses.find((dis) => dis.key === disId)!.entityProperties);;
  };

  const textPropertyChange = (event: any, index: number, disId: number) => {
    const auxDisburses = [...disburses];
    auxDisburses.find((dis) => dis.key === disId)!.entityProperties[index].TextValue = event.target.value;
    onUpdateOrderDisbursement(disId, 'EntityProperties', '', auxDisburses.find((dis) => dis.key === disId)!.entityProperties);;
  }

  const numberPropertyChange = (event: any, index: number, disId: number) => {
    const auxDisburses = [...disburses];
    auxDisburses.find((dis) => dis.key === disId)!.entityProperties[index].NumValue = Number(event.target.value);
    onUpdateOrderDisbursement(disId, 'EntityProperties', '', auxDisburses.find((dis) => dis.key === disId)!.entityProperties);;
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const findPropertieByDisbProperty = (properties: any[], id: number) => {
    let propertyFound: any = null;
    properties.forEach(prop => {
      if (prop['ID'] === id) {
        propertyFound = prop;
      }
    });
    return propertyFound;
  }

  const saveOutgoing = async () => {
    let outgoingFunds: any[] = [];
    let totalAmount = 0;
    disburses.forEach((disbursement: Disbursement, _index: number) => {
      let personIDAux = localStorage.getItem('UserID');
      let nowDateAux = new Date();
      let outgoing = {
        BeneficiaryID: String(disbursement.beneficiary.BeneficiaryID),
        BeneficiaryBankAccountID: String(disbursement.beneficiary.BankAccountID),
        BeneficiaryType: disbursement.beneficiary.BeneficiaryType,
        CreatedBy: personIDAux,
        CreatedOn: nowDateAux,
        UpdatedBy: personIDAux,
        UpdateOn: nowDateAux,
        DealHeaderID: fullDeal.DealHeaderID,
        Equivalent: Number(disbursement.otherAmount.toFixed(scaleByCurrency(fullDeal.BuyCurrency))),
        EntityProperties: disbursement.entityProperties,
        EntityPropertiesJson: JSON.stringify(disbursement.entityProperties),
        IsValid: true,
        Origin: "Online Deal",
        OutgoingFundsID: disbursement.id !== undefined ? disbursement.id : 0,
        UpdateToken: disbursement.token !== undefined ? disbursement.token : '',
        PaymentAmount: disbursement.amount.toFixed(scaleByCurrency(fullDeal.BuyCurrency)),
        PaymentInstrument: disbursement.instrument,
        PaymentReference: disbursement.reference,
        ShowProperties: false,
        Status: 'Pending',
        ValidationMessages: [],
        ValueDate: valueDate,
        VersionNo: 1,
        WireItemFee: disbursement.fee,
        WireItemFeeCurrencyID: fullDeal.BuyCurrency,
      }
      outgoingFunds.push(outgoing);
      totalAmount = totalAmount + disbursement.amount;
    });

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    //Get forward balance
    let forwardSellBalance = Number(fullDeal.SellAmount) - Number(fullDeal.ForwardSellTotalDisbursed);
    let forwardBuyBalance = Number(fullDeal.BuyAmount) - Number(fullDeal.ForwardBuyTotalDisbursed);
    let forwardProfitAmountBalance = Number(fullDeal.ProfitAmount) - Number(fullDeal.ForwardProfitAmountDisbursed);

    //Create drawdown           
    let proportion = totalAmount / Number(fullDeal.SellAmount);
    let sellAmount = totalAmount;

    var buyAmount = Number((proportion * fullDeal.BuyAmount).toFixed(2));
    var profitAmount = Number((proportion * fullDeal.ProfitAmount).toFixed(2));
    if (sellAmount === forwardSellBalance) {
      buyAmount = forwardBuyBalance;
      profitAmount = forwardProfitAmountBalance;
    }

    let objDealHeader = {
      BuyAmount: buyAmount.toFixed(scaleByCurrency(fullDeal.BuyCurrency)),
      BuyCurrency: fullDeal.BuyCurrency,
      CustomerContactID: Number(localStorage.getItem('ContactID')),
      CreatedBy: localStorage.getItem('UserID'),
      CustomerID: userSession.CustomerID,
      CustomerRate: fullDeal.CustomerRate,
      CustomerRefNo: '',
      IncomingFunds: [] as any,
      LockedSide: 'Sell',
      MarketRate: fullDeal.MarketRate,
      MaturityDate: formatDate(fullDeal.MaturityDate),
      OutgoingFunds: outgoingFunds,
      ParentDealHeaderID: fullDeal.DealHeaderID,
      ProfitAmount: profitAmount,
      RateQuoteID: fullDeal.RateQuoteID,
      SellAmount: sellAmount.toFixed(scaleByCurrency(fullDeal.SellCurrency)),
      SellCurrency: fullDeal.SellCurrency,
      Status: userSession.DealApproveRequired ? 'Pending for Approval' : 'Accepted',
      DealOrigin: 'Online Deal',
      DealType: 2,
      UpdateBuyAmountField: true,
      UpdatedBy: localStorage.getItem('UserID'),
      ValueDate: formatDate(new Date),
      ExternalInterface: userSession.OnlineInterface,
      ExternalInterfaceType: userSession.OnlineInterfaceType
    }

    if (deal['Forward Type'] === 'Open') {
      let array = settlementMethod.value.split(';');
      let deliveryMethod = settlementMethod.details.filter((i: any) => i.label.includes('Delivery Method'));

      objDealHeader.IncomingFunds.push({
        Amount: sellAmount,
        BankAccountID: array[1],
        SettlementInstrument: array[2],
        SettlementDeliveryMethod: deliveryMethod && deliveryMethod !== {} ? deliveryMethod[0].translationlabel/*deliveryMethod[0].description*/ : ''
      });
      showLoadingModal();
      await saveDealHeader(objDealHeader).then(
        (saveOutgoingFundsResponse) => {
          /*
            {
              "ApprovalsJson":null,
              "DealCreditStatus":"Automatically Approved",
              "DealHeaderID":15300,
              "ExternalInterfaceTradeID":null,
              "ExternalInterfaceTradeNumber":null,
              "Status":"Accepted",
              "HeldFundBalance":-1.0,
              "MessagePending":""
            }
          */
          let alertMessage = '', alertTitle = '';
          const isOpen = deal !== undefined && deal['Forward Type'] === 'Open';
          if (Number(saveOutgoingFundsResponse.httpStatusCode) === 200) {
            alertMessage = (isOpen ? getResource('Message_DrawdownCreated') : getResource('Message_DisbursementCreated'));
            if (saveOutgoingFundsResponse.ServiceErrorMessage != null && saveOutgoingFundsResponse.ServiceErrorMessage != undefined && saveOutgoingFundsResponse.ServiceErrorMessage != '') {
              alertMessage = alertMessage + " " + saveOutgoingFundsResponse.ServiceErrorMessage;
            }
            else {
              if (saveOutgoingFundsResponse.HeldFundBalance != null && saveOutgoingFundsResponse.HeldFundBalance != undefined && saveOutgoingFundsResponse.HeldFundBalance > -1) {
                alertMessage = alertMessage + " " + getResource('YourCurrentBalance') + ": " + formatToCurrency(saveOutgoingFundsResponse.HeldFundBalance, fullDeal.SellCurrency) + ' ' + fullDeal.SellCurrency;
              }
            }
            if (saveOutgoingFundsResponse.LimitValidationMessage != undefined && saveOutgoingFundsResponse.LimitValidationMessage != undefined) {
              alertMessage = alertMessage + " , " + saveOutgoingFundsResponse.LimitValidationMessage;
            }
            hideLoadingModal();
            showModal(getResource('s_MessageError_TitleSuccess'), alertMessage);
            setSaving(false);
            props.onFinish(fullDeal.DealHeaderID);
          }
          else {
            alertTitle = getResource('Js_MessageError_TitleError');
            /*{"Message":"There are no cashflows for the forward: 15586","ErrorMessage":null}*/
            if (saveOutgoingFundsResponse.ValidationMessage !== undefined) {
              alertMessage = saveOutgoingFundsResponse.ValidationMessage;
              alertTitle = getResource('Js_MessageError_TitleValidation');
            } else {
              if (saveOutgoingFundsResponse.Message !== undefined && saveOutgoingFundsResponse.Message !== '') {
                alertMessage = saveOutgoingFundsResponse.Message;
              } else {
                alertMessage = isOpen ? getResource('Message_DrawdownNotCreated') : getResource('Message_DisbursementNotCreated');
              }
            }
            if (saveOutgoingFundsResponse.Message == 'SentToInterface') {
              alertMessage = getResource('Message_DealErrorSentInterface');
            }
            hideLoadingModal();
            showModal(alertTitle, alertMessage);
            setSaving(false);
          }
        }
      );
      hideLoadingModal();
    } else {
      showLoadingModal();
      saveOutgoingFunds(outgoingFunds).then(
        (saveOutgoingFundsResponse) => {
          console.log("saveOutgoingFundsResponse ->", saveOutgoingFundsResponse);
          switch (Number(saveOutgoingFundsResponse.httpStatusCode)) {
            case 200: {
              let alertMessage = getResource('Message_DisbursementCreated');
              if (saveOutgoingFundsResponse.ServiceErrorMessage != null && saveOutgoingFundsResponse.ServiceErrorMessage != undefined && saveOutgoingFundsResponse.ServiceErrorMessage != '') {
                alertMessage = alertMessage + " " + saveOutgoingFundsResponse.ServiceErrorMessage;
              }
              else {
                if (saveOutgoingFundsResponse.HeldFundBalance != null && saveOutgoingFundsResponse.HeldFundBalance != undefined && saveOutgoingFundsResponse.HeldFundBalance > -1) {
                  alertMessage = alertMessage + " " + getResource('YourCurrentBalance') + ": " + formatToCurrency(saveOutgoingFundsResponse.HeldFundBalance, fullDeal.SellCurrency) + ' ' + fullDeal.SellCurrency;
                }
              }
              hideLoadingModal();
              showModal(getResource('s_MessageError_TitleSuccess'), alertMessage);
              props.onFinish(fullDeal.DealHeaderID);
              break;
            }
            default: {
              hideLoadingModal();
              showModal(getResource('Js_MessageError_TitleValidation'), getResource('Message_DisbursementNotCreated'));
              break;
            }
          }
        }
      );
    }
  }

  const yesConfirmNotFullAmount = () => {
    saveOutgoing();
  }


  const isValidInput = (i: number, value?: any) => {
    let valid = true;
    let finalValue = value !== undefined ? value : (isNaN(disburses[i].amount) ? 0 : disburses[i].amount);
    let undisbursedBalanceTotal: number = 0.00;
    disburses.forEach((dis: Disbursement) => {
      undisbursedBalanceTotal = undisbursedBalanceTotal + (isNaN(dis.amount) ? 0 : dis.amount);
    });

    let aaaaa = (balance - undisbursedBalanceTotal);
    let bbbb = isNaN(finalValue) ? 0 : finalValue;

    if (aaaaa + disburses[i].amount - bbbb < 0) {
      valid = false;
    }

    return valid;
  }

  const validateEntity = (disbursement: Disbursement, entity: any) => {
    let valid = true;
    /*
      AmountLimit: 200
      Attachments: []
      AttachmentsRequired: false
      DataType: "Text"
      DateValue: null
      Description: "Copy of invoice and number"
      EntityPropertyID: null
      IsValid: true
      LookupTableAttachmentsRequired: false
      Mandatory: true
      NumValue: null
      TextValue: ""
      UserDefinedPropertyID: 3181
    */
    if (entity.AmountLimit !== undefined && entity.AmountLimit !== null && entity.AmountLimit !== 0) {
      if (disbursement.amount * buyCurrencyItem.BaseCurrencyDirectRate > entity.AmountLimit) {
        valid = false;
      }
      else if (entity.Mandatory === true) {
        switch (entity.DataType) {
          case 'Text': {
            if (entity.TextValue === undefined || entity.TextValue === null || entity.TextValue === '') valid = false;
            break;
          }
          case 'Numeric': {
            if (entity.NumValue === undefined || entity.NumValue === null || entity.NumValue === '' || entity.NumValue === 0) valid = false;
            break;
          }
          case 'DateTime': {
            if (entity.DateValue === undefined || entity.DateValue === null || entity.DateValue === '') valid = false;
            break;
          }
        }
      }
    }
    return valid;
  }

  const onClickMore = (idRow: number) => {
    if (!saving) {
      const auxDis = [...disburses];
      auxDis.forEach(dis => {
        if (dis.key === idRow) {
          dis.showProperties = !dis.showProperties;
        }
      });
      setDisburses(auxDis);
    }
  }

  const getAttachmentsByEntityId = () => {
    let arrayAttach: any[] = [];

    const auxDisburses = [...disburses];
    auxDisburses.forEach(dis => {
      if (dis.key === currentAttachmentProp.currentDisbursementID) {
        dis.entityProperties.forEach(entpro => {
          if (entpro.UserDefinedPropertyID === currentAttachmentProp.id) {
            arrayAttach = entpro.Attachments;
          }
        });
      }
    });

    return arrayAttach;
  }

  const onBlurAmount = (id: number, keyName: string, event: any) => {
    let value = event.target.value !== null && event.target.value !== '' ? Number(event.target.value.replace(/[,]+/g, '')) : 0;
    onUpdateOrderDisbursement(id, keyName, value);
  }

  const dropdownSettlementsChangeHandler = (item: any, name: string) => {
    setSettlementMethod(item);
    setValidates(true);
  };

  let undisbursedBalanceTotal: number = 0.00;

  disburses.forEach((dis: Disbursement) => {
    undisbursedBalanceTotal = undisbursedBalanceTotal + (isNaN(dis.amount) ? 0 : dis.amount);
  });

  return (
    <>
      <div className="row center-xs">
        <div className="col-md-12">
          <div className="row col-md-12 center-xs around-xs">
            {deal !== undefined && deal['Forward Type'] === 'Open' && <h2 className='bold-text'>{getResource('Message_DrawdownBeneficiaries')} {fullDeal !== undefined ? fullDeal.DealHeaderID : ''}</h2>}
            {deal !== undefined && deal['Forward Type'] === 'Closed' && <h2 className='bold-text'>{getResource('Message_DisbursementBeneficiaries')} {fullDeal !== undefined ? fullDeal.DealHeaderID : ''}</h2>}
          </div>
          <div className="row col-md-12 center-xs around-xs">
            <div className="col-md-3 col-xs-6">
              <div className="col-md-12 col-xs-12 row middel-xs align-center">
                <span className="bold-text">{getResource('Form.Label.SellAmount')}: {fullDeal !== undefined ? (formatToCurrency(fullDeal.BuyAmount, undefined, buyScale) + ' ' + fullDeal.BuyCurrency) : ''}</span>
              </div>
              <div className="col-md-12 col-xs-12 row middel-xs align-center">
                <span className="bold-text">{getResource('Form.Label.BuyAmount')}: {fullDeal !== undefined ? (formatToCurrency(fullDeal.SellAmount, undefined, sellScale) + ' ' + fullDeal.SellCurrency) : ''}</span>
              </div>
            </div>
            <div className="col-md-3 col-xs-6">
              <div className="col-md-12 col-xs-12 row middel-xs align-center">
                <span className="bold-text">{getResource('Table_TH_Rate')}: {fullDeal !== undefined ? (Number(fullDeal.CustomerRate).toFixed(4)) : ''}</span>
              </div>
              <div className="col-md-12 col-xs-12 row middel-xs align-center">
                <span className="bold-text">{getResource('UndisbursedBalance')}: {deal !== undefined ? formatToCurrency(deal.OutstandingBalance, undefined, sellScale) : ''}</span>
              </div>
            </div>
            <div className="col-md-3 col-xs-12">
              <div className="col-md-12 col-xs-6 row middel-xs align-center">
                <span className="bold-text">{getResource('Form.Label.ValueDate')}: {valueDate}</span>
              </div>
              <div className="col-md-12 col-xs-6 row middel-xs align-center">
                <span className="bold-text">{getResource('RemainingBalance')}: {formatToCurrency((balance - undisbursedBalanceTotal).toString(), undefined, sellScale)}</span>
              </div>
            </div>
            {deal !== undefined && deal['Forward Type'] === 'Open' &&
              <div className="col-md-3 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Form.Label.SettlementMethod')}</span>
                </div>
                <div className="row">
                  <Dropdown
                    name="settlementMethod"
                    title={getResource('SelectSettlementMethod')}
                    className={validates ? '' : (formatValidInputClass(validInputClass(settlementMethod.value)))}
                    settlementMode={true}
                    list={settlementMethods}
                    onChange={dropdownSettlementsChangeHandler}
                    select={{ value: settlementMethod.value }}
                    disabled={false}
                  />
                </div>
              </div>}
          </div>
          <div className="row">
            <Divider style={{ marginTop: 0 }} />
            <div className="col-md-3 col-xs-12">
              <span>{getResource('Js_TH_Beneficiary')}</span>
            </div>
            <div className="col-md-3 col-xs-12">
              <span>{getResource('Label.Instrument')}</span>
            </div>
            <div className="col-md-1°5 col-xs-12">
              <span>{getResource('Label.WeSend')} ({fullDeal !== undefined ? fullDeal.SellCurrency : ''})</span>
            </div>
            <div className="col-md-1°5 col-xs-12">
              <span>{getResource('Label.WePay')} ({fullDeal !== undefined ? fullDeal.BuyCurrency : ''})</span>
            </div>
            <div className="col-md-1 col-xs-12">
              <span>{getResource('Label.Fee')} ({fullDeal != null ? fullDeal.BuyCurrency : ''})</span>
            </div>
            <div className="col-md-1 col-xs-12">
              <span>{getResource('Label.Reference')}</span>
            </div>
            <div className="col-md-1 col-xs-12">
              <span>{getResource('Table_TH_Actions')}</span>
            </div>
            {/* ----------- Lista ----------------------------- */}
            <div className="row">
              {disburses.map((r, i) => (
                <>
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <div className="row">
                        <Dropdown
                          className={submit && disburses[i].beneficiary.value === '' ? 'input-error' : ''}
                          id={"beneId" + disburses[i].key}
                          name="beneficiaryDropdown"
                          title={getResource('SelectBeneficiary')}
                          list={beneficiaries}
                          onChange={(item: any) => { onUpdateOrderDisbursement(disburses[i].key, 'Beneficiary', item); }}
                          select={{ value: disburses[i].beneficiary.value }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <div className="row">
                        <Dropdown
                          className={submit && disburses[i].instrument === '' ? 'input-error' : ''}
                          id={"instrumentId" + disburses[i].key}
                          name="instrumentDropdown"
                          title={getResource('SelectInstrument')}
                          list={Number(disburses[i].beneficiary.BankAccountID) > 0 ? instruments : instrumentsForNonAccounts}
                          onChange={(item: any) => { onUpdateOrderDisbursement(disburses[i].key, 'Instrument', item.value); }}
                          select={{ value: disburses[i].instrument }}
                        />
                      </div>
                    </div>
                    <div className="col-md-1°5 col-xs-12">
                      <div className="row">
                        <Input
                          id={"payment-amount-" + i}
                          key={"payment-amount-" + i}
                          className={submit ? ((disburses[i].amount === 0 || isNaN(disburses[i].amount)) && (disburses[i].otherAmount === 0 || isNaN(disburses[i].otherAmount)) || !isValidInput(i) ? 'input-error' : '') : isValidInput(i) ? '' : 'input-error'}
                          //className={lockedSide === 'sell' && submit ? (disburses[i].amount === 0 || isNaN(disburses[i].amount) ? 'input-error' : '') : ''}
                          type='number'
                          //disabled={lockedSide === 'Buy'}
                          value={disburses[i].amount}
                          decimalScale={buyScale} 
                          onValueChange={(value: number) => {
                            if (value !== undefined) {
                              if (!isValidInput(i, value)) {
                                showModal(getResource('Js_MessageError_TitleError'), getResource('Message_DrawdownAmountExceedBalance').replace('<amount>', balance));
                              }
                              onUpdateOrderDisbursement(disburses[i].key, 'Amount', value);
                            }
                          }}
                          /*onBlur={(event: any) => {
                            if (event !== undefined) {
                              onBlurAmount(disburses[i].key, 'Amount', event);
                            }
                          }}  */
                        />
                      </div>
                    </div>
                    <div className="col-md-1°5 col-xs-12">
                      <div className="row">
                        <Input
                          id={"payment-cost-" + i}
                          key={"payment-cost-" + i}
                          className={submit ? ((disburses[i].amount === 0 || isNaN(disburses[i].amount)) && (disburses[i].otherAmount === 0 || isNaN(disburses[i].otherAmount)) ? 'input-error' : '') : ''}
                          //className={lockedSide === 'buy' && submit ? (disburses[i].amount === 0 || isNaN(disburses[i].amount) ? 'input-error' : '') : ''}
                          type="number"
                          decimalScale={sellScale} 
                          //disabled={fullDeal.LockedSide === 'Sell'}
                          value={disburses[i].otherAmount}
                          /*onValueChange={(value: number) => {
                            if (value !== undefined) {
                              onUpdateOrderDisbursement(disburses[i].key, 'OtherAmount', value);
                            }
                          }}   
                          onBlur={(event: any) => {
                            if (event !== undefined) {
                              onBlurAmount(disburses[i].key, 'OtherAmount', event);
                            }
                          }}      */
                          disabled={true}   
                        />
                      </div>
                    </div>
                    <div className="col-md-1 col-xs-12">
                      <div className="row">
                        <Input type="text" disabled={true} value={(disburses[i].fee !== undefined ? disburses[i].fee.toFixed(buyScale) : '0.00')} />
                      </div>
                    </div>
                    <div className="col-md-1 col-xs-12">
                      <div className="row">
                        <input type="text" value={disburses[i].reference} onChange={(event) => { onUpdateOrderDisbursement(disburses[i].key, 'Reference', event.target.value); }} />
                      </div>
                    </div>
                    <div className="col-md-1 col-xs-12 row top-xs center-xs">
                      <DeleteTwoTone onClick={() => { deleteADisbursementRow(disburses[i].key); }} twoToneColor={"#34c521"} />
                      <PlusCircleTwoTone onClick={() => { onClickMore(disburses[i].key); }} twoToneColor={"#34c521"} />
                    </div>
                  </div>
                  <div className="row">
                    {r.showProperties && disburses[i].entityProperties.map(function (entity: any, index: number) {
                      /*
                        AmountLimit: 200
                        Attachments: []
                        AttachmentsRequired: false
                        DataType: "Text"
                        DateValue: null
                        Description: "Copy of invoice and number"
                        EntityPropertyID: null
                        IsValid: true
                        LookupTableAttachmentsRequired: false
                        Mandatory: true
                        NumValue: null
                        TextValue: ""
                        UserDefinedPropertyID: 3181
                      */
                      let propertyElement = findPropertieByDisbProperty(addProperties as any[], entity.UserDefinedPropertyID);
                      /*
                        Data Type: "Text"
                        Description: "Copy of invoice and number"
                        Display Order: 10
                        ExtAmountLimit$: 200
                        ExtAttachmentsRequired$: false
                        ExtMandatory$: true
                        ExtUpdateToken$: "637649787715866667"
                        ExtUserPropertyID$: 3181
                        Group By: null
                        ID: 3181
                        PossibleValues$: null
                        Short Description: "Invoice"
                      */
                      let propertyControl;
                      if (propertyElement['PossibleValues$']) {
                        propertyElement['PossibleValues$'].forEach((element: any) => {
                          element.value = element.Value;
                          element.label = element.Description;
                        });

                        propertyControl = (
                          <Dropdown
                            className={submit && !validateEntity(r, entity) ? 'input-error' : ''}
                            id={propertyElement.Description}
                            title={propertyElement['PossibleValues$'][0].Description}
                            select={{ value: entity.TextValue }}
                            onChange={(item: any, name: string) => {
                              dropdownPropertyChange(item, name, index, r.key);
                            }}
                            name={propertyElement.Description}
                            list={propertyElement['PossibleValues$']}
                            disabled={saving}
                          />
                        );
                      }
                      else {
                        switch (propertyElement['Data Type']) {
                          case 'Text':
                          default:
                            propertyControl = (
                              <input
                                className={submit && !validateEntity(r, entity) ? 'input-error' : ''}
                                disabled={saving}
                                type="text"
                                value={entity.TextValue}
                                onChange={(event) => {
                                  textPropertyChange(event, index, r.key);
                                }}
                              />
                            );
                            break;
                          case 'Numeric':
                            propertyControl = (
                              <input
                                className={submit && !validateEntity(r, entity) ? 'input-error' : ''}
                                type="number"
                                disabled={saving}
                                value={entity.NumValue}
                                onChange={(event) => {
                                  numberPropertyChange(event, index, r.key);
                                }}
                              />
                            );
                            break;
                          case 'DateTime':
                            propertyControl = (
                              <DatePicker />
                            );
                            break;
                        }
                      }

                      return (
                        <div className="row">
                          <div className="col-md-4 col-xs-12 row">
                            <div className="row">
                              <span>{entity.Description}</span>
                            </div>
                          </div>
                          <div className="col-md-5 col-xs-12">
                            <div className="row">
                              {propertyControl}
                            </div>
                          </div>
                          <div className="col-md-1 col-xs-12 row middle-xs">
                            <PaperClipOutlined
                              onClick={() => {
                                let objCurrentAtt = { id: propertyElement['ID'], description: propertyElement.Description, currentDisbursementID: r.key };
                                setCurrentAttachmentProp(objCurrentAtt);
                                setShowAttachmentForm(true);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ))}
            </div>
            <div className="row middle-xs end-xs">
              <div className="col-md-3 col-xs-12 row middle-xs end-xs">
                <Button type={'secondary'} onClick={() => {
                  addARowToList(addProperties as any[], false);
                }}>
                  {getResource('Button.Add')}
                </Button>
              </div>
            </div>
            {/* ----------------------------------------------- */}
            <Divider style={{ marginTop: 0 }} />
          </div>
          <div className="row start-xs around-xs">
            <div className="col-md-4 col-xs-12 row middle-xs">
              <Button
                type={"secondary"}
                onClick={() => { if (props.onExit !== undefined) props.onExit(); }}
              >{getResource('Btn_Exit')}</Button>
            </div>
            <div className="col-md-4 col-xs-12 row middle-xs">
              <Button
                type={(undisbursedBalanceTotal === 0 || (balance - undisbursedBalanceTotal) < 0) ? 'disable' : 'secondary'}
                onClick={() => { saveDealClicked(); }}
              >{getResource('Button.Save')}</Button>
            </div>
          </div>
        </div>
      </div>
      <AttachmentsForm
        key={'orders-disbursement-attachment-form'}
        id={'orders-disbursement-attachment-form'}
        show={{ id: currentAttachmentProp.description, show: showAttachmentForm }}
        onClose={() => { setShowAttachmentForm(false); }}
        useLinkID={false}
        onUploadWithoutLink={(attachmentID: any, fileName: any, description: any) => {
          let objAttach: any = {
            ID: attachmentID,
            FileName: fileName,
            Description: description,
          }
          const auxDisburses = [...disburses];
          auxDisburses.forEach(dis => {
            if (dis.key === currentAttachmentProp.currentDisbursementID) {
              dis.entityProperties.forEach(entpro => {
                if (entpro.UserDefinedPropertyID === currentAttachmentProp.id) {
                  entpro.Attachments.push(objAttach);
                }
              });
            }
          });
          setDisburses(auxDisburses);
        }}
        showList={true}
        attachmentsList={getAttachmentsByEntityId()}
      />
      <Modal
        title={<h3 className="component-title">{getResource('Js_MessageError_TitleConfirm')}</h3>}
        centered
        visible={showNotFullAmount}
        footer={null}
        onCancel={() => { setShowNotFullAmount(false); }}
        maskClosable={false}
        width={'30%'}
        zIndex={1021}
      >
        <div>
          <div className="row col-md-12">
            <h4>{getResource('Message_Confirmation_DrawdownNotFullForwardAmount')}</h4>
          </div>
          <div className="row around-xs">
            <div className="col-md-5 col-xs-6">
              <div className="col">
                <Button id="confirmation-notfullamount-yes" type="primary" onClick={() => { setShowNotFullAmount(false); }}>
                  {getResource('No')}
                </Button>
              </div>
            </div>
            <div className="col-md-5 col-xs-6">
              <div className="col">
                <Button id="confirmation-notfullamount-no" type="secondary" onClick={() => { setShowNotFullAmount(false); yesConfirmNotFullAmount(); }}>
                  {getResource('Yes')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DrawdownMultipleBeneficiaries;