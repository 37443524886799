import { FunctionComponent, useContext, useState } from 'react';
import { generateTokenCodeSecurity, validateTokenCodeSecurity } from '../../api/NetworkingUsers';
import AppContext from '../../App.context';
import { formatValidInputClass } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from "../inputs/Button";
import Input from "../inputs/Input";

interface AdditionalValidationProps {
  userSession: any,
  tokenValidated?: any,
  onClose: any
}
const AdditionalValidation: FunctionComponent<AdditionalValidationProps> = (props) => {
  const { showModal } = useContext(AppContext);
  const [token, setToken] = useState('');
  const [validations, setValidations] = useState({
    token: true
  });
  const [smsSent, setSmsSent] = useState(false);

  const validateToken = async () => {
    let request = {
      numberMobile: smsSent ? props.userSession.MobileNumber : '',
      email: smsSent ? '' : props.userSession.UserName,
      token: token
    }

    validateTokenCodeSecurity(request).then((response) => {
      if (response !== undefined) {
        if (response.validation) {
          props.tokenValidated()
        }
        else {
          showModal(getResource('Js_MessageError_TitleError'), response.msg);
        }
      }
      else {
        showModal(getResource('Js_MessageError_TitleError'), getResource('Js_Message_TryAgainOrContactAdministrator'));
      }

    })
  }

  const onSendEmailToken = () => {
    let request = {
      numberMobile: '',
      email: props.userSession.UserName
    }

    setSmsSent(false)

    generateTokenCodeSecurity(request).then((response) => {
      if (response !== undefined) {
        if (response.ErrorMessage === null && response.ErrorMessage !== '') {
          showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Message_CodeSent'));
        }
        else {
          showModal(getResource('Js_MessageError_TitleError'), response.ErrorMessage);
        }
      }
      else {
        showModal(getResource('Js_MessageError_TitleError'), getResource('Js_Message_TryAgainOrContactAdministrator'));
      }

    })
  }

  const onSendSMSToken = () => {
    let request = {
      numberMobile: props.userSession.MobileNumber,
      email: ''
    }

    setSmsSent(true)

    generateTokenCodeSecurity(request).then((response) => {
      if (response !== undefined) {
        if (response.ErrorMessage === null && response.ErrorMessage !== '') {
          showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Message_CodeSent'));
        }
        else {
          showModal(getResource('Js_MessageError_TitleError'), response.ErrorMessage);
        }
      }
      else {
        showModal(getResource('Js_MessageError_TitleError'), getResource('Js_Message_TryAgainOrContactAdministrator'));
      }

    })
  }

  const onChangeToken = (e: any) => {
    setToken(e.target.value);
    setValidations({ ...validations, token: true });
  }

  return (
    <div>
      <div className="row">
        <div className="row" style={{ borderBottom: '1px solid #f0f0f0', marginTop: '10px', marginBottom: '10px' }}>
          <div className="col-md-5 col-xs-12">
            <p>{getResource('TextVerificationSentTo')}</p>
          </div>
          <div className="col-md-5 col-xs-12" >
            <div style={{ paddingLeft: '10px' }}>
              {getResource('Email') + ":"}
              <a style={{ paddingLeft: '5px' }} onClick={onSendEmailToken}>{props.userSession.UserName}</a>
            </div>
            {props.userSession.MobileNumber !== '' &&
              <div style={{ paddingLeft: '10px' }}>
                {getResource('Label.Mobile') + ":"}
                <a style={{ paddingLeft: '5px' }} onClick={onSendSMSToken}>{props.userSession.MobileNumber}</a>
              </div>
            }
          </div>
        </div>

        <div className="row">
          <div className="col-md-5 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('EnterCode')}</span>
            </div>
            <div className="row">
              <Input type="text" onChange={onChangeToken} value={token} errorMessage={getResource('DataMsgRequired')}
                className={formatValidInputClass(validations.token)}></Input>
            </div>
          </div>
          <div className="col-md-3 col-xs-12" style={{ marginTop: '25px', marginRight: '20px' }}>
            <Button type="primary" onClick={validateToken}>{getResource('Button.Validate')}</Button>
          </div>
          <div className="col-md-3 col-xs-12" style={{ marginTop: '25px' }}>
            <Button type="secondary" onClick={props.onClose}>{getResource('Btn_Exit')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdditionalValidation;