import { Steps } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { saveRegularPayment, SaveRegularPayment } from '../../../api/NetworkingPayments';
import AppContext from '../../../App.context';
import { getResource } from '../../../helpers/ResourcesHelper';
import { RegularPayment } from './RegularPayments';
import CurrencyDetails from './steps/CurrencyDetails';
import TransferFrequencyDetails from './steps/TransferFrequencyDetails';
const { Step } = Steps;

interface AddEdidRegularPaymentProps {
  payment: RegularPayment | null;
  action: string;
  onCancel: () => void;
}

export interface CurrencyDetailsModel {
  accountToDebit: string;
  beneficiary: string;
  sendAmount: number | string;
  sendCurrency: string;
  beneficiaryID: number;
  beneficiaryName: string;
  paymentID?: number;
  paymentCurrencyID?: string;
  settlementNostroAccount?: number;
  settlementCurrencyID?: string;
  settlementDeliveryMethod?: string;
  settlementInstrument?: string;
  updateToken?: string;
  issueDate?: string;
}
export interface TransferFrequencyDetailsModel {
  transferStartingDate: string;
  transferType: string;
  transferEvery: string;
  startingOn: number | string;
  ending: string;
  endingOcurrences: number | string;
  endingOnDate: string;
  externalComments: string; // Save payment as
  status: string;
}

const AddEditRegularPayment: React.FC<AddEdidRegularPaymentProps> = ({ payment, action, onCancel: returnToPayments }) => {
  const { showModal } = useContext(AppContext);
  type StepStatus = 'wait' | 'process' | 'finish' | 'error' | undefined;
  enum StepScreen {
    CurrencyDetails,
    TransferFrequencyDetails
  }
  const [currencyDetails, setCurrencyDetails] = useState<CurrencyDetailsModel | null>(null);
  const [transferFrequencyDetails, setTransferFrequencyDetails] = useState<TransferFrequencyDetailsModel | null>(null);
  const [currentScreen, setCurrentScreen] = useState<StepScreen>(StepScreen.CurrencyDetails);
  const [currencyDetailsStatus, setCurrencyDetailsStatus] = useState<StepStatus>('process');
  const [transferFrequencyDetailsStatus, setTransferFrequencyDetailsStatus] = useState<StepStatus>('wait');
  const [validCurrencyDetails, setValidCurrencyDetails] = useState(false);
  const [validTransferDetails, setValidTransferDetails] = useState(false);

  useEffect(() => {
    if (payment != null) {
      const currencyDetails: CurrencyDetailsModel = {
        paymentID: payment['Regular Payment ID'],
        paymentCurrencyID: payment['Payment Currency ID'],
        accountToDebit: payment['Settlement Bank Account'].toString(),
        beneficiary: payment['Payment Beneficiary BankAccount ID'].toString(),
        sendAmount: payment['Payment Amount'],
        sendCurrency: payment['Payment Currency ID'],
        beneficiaryID: payment['Payment Beneficary ID'],
        beneficiaryName: payment['Beneficiary Name'],
        settlementNostroAccount: payment['Settlement Nostro Account'],
        settlementCurrencyID: payment['Settlement CurrencyID'],
        settlementDeliveryMethod: payment['Settlement Delivery Methodas'],
        settlementInstrument: payment['Settlement Instrument'],
        updateToken: payment.UpdateToken,
        issueDate: payment['Issue Date']
      };
      const transferDetails: TransferFrequencyDetailsModel = {
        ending: returnAfterNullCheckOrEmpty(payment['Transfer Ending Type']),
        endingOcurrences: returnAfterNullCheckOrEmpty(payment['Transfer Ending After Occurrences']),
        endingOnDate: returnAfterNullCheckOrEmpty(payment['Transfer Ending On']),
        startingOn: returnAfterNullCheckOrEmpty(payment['Transfer Monthly On Day']),
        transferEvery: returnAfterNullCheckOrEmpty(payment['Transfer Day']),
        transferStartingDate: returnAfterNullCheckOrEmpty(payment['Transfer Starting Date']),
        transferType: returnAfterNullCheckOrEmpty(payment['Transfer Type']),
        externalComments: returnAfterNullCheckOrEmpty(payment['External Comments']),
        status: returnAfterNullCheckOrEmpty(payment.Status)
      };
      setCurrencyDetails(currencyDetails);
      setTransferFrequencyDetails(transferDetails);
    }
  }, [payment]);

  const returnAfterNullCheckOrEmpty = (value: any) => (value != null ? value : '');

  const returnAfterNullCheckOrZero = (value: any) => (value != null ? value : 0);

  const onCurrencyDetailStep = (currenStatus: StepStatus, _: CurrencyDetailsModel, valid: boolean) => {
    setCurrencyDetailsStatus(currenStatus);
    setTransferFrequencyDetailsStatus('process');
    if (_ != null) {
      setCurrencyDetails({ ...currencyDetails, ..._ });
    }
    setValidCurrencyDetails(valid);
    if (valid) {
      setCurrentScreen(StepScreen.TransferFrequencyDetails);
    }
  };

  const onTransferDetailStep = (currenStatus: StepStatus, _: TransferFrequencyDetailsModel, valid: boolean) => {
    setCurrencyDetailsStatus('process');
    setTransferFrequencyDetailsStatus(currenStatus);
    setCurrentScreen(StepScreen.CurrencyDetails);
    if (_ != null) {
      setTransferFrequencyDetails({ ...transferFrequencyDetails, ..._ });
    }
    setValidTransferDetails(valid);
  };

  const onTrySave = async (currenStatus: StepStatus, _: TransferFrequencyDetailsModel, valid: boolean) => {
    setTransferFrequencyDetailsStatus(currenStatus);
    if (_ != null) {
      setTransferFrequencyDetails(_);
    }
    setValidTransferDetails(valid);
    // Not validCurrencyDetails && validTransferDetails because setValidTransferDetails from previous line is async and doesn't update fast enough
    if (validCurrencyDetails && valid) {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const personID = JSON.parse(localStorage.getItem('UserSession')!).PersonID;
      const model: SaveRegularPayment = {
        objRegularPayment: {
          RegularPaymentID: returnAfterNullCheckOrZero(currencyDetails!.paymentID!),
          CurrencyID: currencyDetails!.sendCurrency,
          CustomerRefNo: '',
          ExternalComments: _!.externalComments,
          InternalComments: '',
          IssueDate: returnAfterNullCheckOrEmpty(payment?.['Issue Date']!),
          LastExecuted: '',
          NextExecute: '',
          NotificationMethod: 'Email',
          PaymentAmount: currencyDetails!.sendAmount as number,
          PaymentBeneficiaryBankAccountID: Number.parseInt(currencyDetails!.beneficiary),
          PaymentBeneficiaryID: currencyDetails!.beneficiaryID,
          PaymentCurrencyID: currencyDetails!.paymentCurrencyID!,
          BeneficiaryName: currencyDetails!.beneficiaryName,
          SettlementBankAccount: Number.parseInt(currencyDetails!.accountToDebit),
          SettlementNostroAccount: returnAfterNullCheckOrZero(currencyDetails!.settlementNostroAccount!),
          SettlementCurrencyID: currencyDetails!.settlementCurrencyID!,
          SettlementDeliveryMethod: 'EFT',
          SettlementInstrument: 'Direct Debit',
          Status: _!.status,
          TransferDay: _!.transferEvery,
          ThirdPartyBeneficiaryPayment: 0,
          TransferEndingAfterOccurrences: returnAfterNullCheckOrEmpty(_!.endingOcurrences) === '' ? null : (_!.endingOcurrences as number),
          TransferEndingOn: _!.endingOnDate === '' ? null : _!.endingOnDate,
          TransferEndingType: _!.ending,
          TransferMonthlyOnDay: _!.startingOn === '' ? null : _!.startingOn,
          TransferStartingDate: _!.transferStartingDate,
          TransferType: _!.transferType,
          UpdateToken: returnAfterNullCheckOrEmpty(currencyDetails!.updateToken!)
        },
        CustomerContactID: localStorage.getItem('ContactID')!,
        CustomerID: customerID,
        UpdatedBy: personID
      };
      const json = await saveRegularPayment(model);
      // console.log(model);
      // const json = { httpStatusCode: 200, httpErrorMessage: '' };
      if (json != null) {
        if (json.httpStatusCode === 200) {
          showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveRegularPaymentSuccessfully'));
        } else {
          showModal(getResource('Js_MessageError_TitleError'), json.httpErrorMessage);
        }
      }
    } else {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
  };

  const onCancel = () => {
    returnToPayments();
  };

  return (
    <React.Fragment>
      <div className="row center-xs">
        <div className="col-lg-2 col-md-1 col-xs-12" />
        <div className="col-lg-8 col-md-10 col-xs-12">
          <div className="screen-container">
            <Steps current={currentScreen}>
              <Step title={getResource('Label.CurrencyDetails')} status={currencyDetailsStatus} />
              <Step title={getResource('Label.TransferFrequencyDetails')} status={transferFrequencyDetailsStatus} />
            </Steps>
          </div>
        </div>
        <div className="col-lg-2 col-md-1 col-xs-12" />
      </div>
      {currentScreen === StepScreen.CurrencyDetails && <CurrencyDetails details={currencyDetails} action={action} onCancel={onCancel} onStep={onCurrencyDetailStep} />}
      {currentScreen === StepScreen.TransferFrequencyDetails && <TransferFrequencyDetails details={transferFrequencyDetails} action={action} onCancel={onCancel} onStep={onTransferDetailStep} onTrySave={onTrySave} />}
    </React.Fragment>
  );
};

export default AddEditRegularPayment;
